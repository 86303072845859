import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Lightbox from 'react-image-lightbox';
import storage from "helper/helper.storage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import throttle from "lodash/throttle";
import StatusFilter from "components/datatables/status_filter";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Input,
  InputLabel,
  Dialog,
  Alert,
  AlertTitle,
  MenuItem,
  InputAdornment,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Button,
  Avatar,
  DialogTitle,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
  Link,
  Box,
  Slide,
} from "@mui/material";
import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
  RefreshOutlined,
  AccountCircle,
  Save,
  Download,
  Close,
  AccessTime,
  AssignmentInd,
  Lock,
  CloudSync,
  EditTwoTone,
  AccountTree,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { def } from "../../helper/helper.const";
import moment from "moment";
import BtnDriverRender from "components/datatables/btn_driver_render";
import { red } from "@mui/material/colors";
const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  option: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: 11,
    letterSpacing: 1,
    "& > span": {
      marginRight: 10,
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
  },
  input_label: {
    color: "#3c4043",
    fontSize: 11,
    textTransform: "uppercase",
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var base64 = require('base-64');

export default function Driver() {
  const MySwal = withReactContent(Swal);
  const classes = useStyles();
  const Api = useAxios();
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [unitListSeveral, setUnitSeveral] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [driverUnitForm, setDriverUnit] = React.useState(false);
  const [clearID, setClearID] = React.useState("");
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const [inputValue, setInputValue] = React.useState("");
  const [driverDetails, setDriverDetails] = React.useState({});
  const [syncLatest, setSyncLatest] = React.useState({});
  const [overlay, setOverlay] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewEdit, setEditView] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [lightboxopen, setlightboxopen] = useState({
    open: false,
    image: null,
  });
  const [photoDevice, setPhotoDevice] = useState(null);
  const [photoDevicePreview, setPreviewPhotoDevice] = useState(null);
  const [form, setForm] = React.useState({
    unit_id: "",
    user_id: "",
    user_identity: "",
    user_old_identify: "",
    user_name: "",
    user_nickname: "",
    user_driver_sim: "",
    user_driver_sim_expire: "",
    user_handphone_1: "",
    user_handphone_2: "",
    photo_device: null,
    unit_vehicle_no: "",
    unit_vehicle_type: "",
    unit_description: "",
    user_status: "",
    fingerprint_option: 'true',
    status: "",
  });
  const perPage = 20;
  const gridREF = useRef();
  const photoDeviceREF = useRef();
  const [viewDelete, askDelete] = React.useState(false);
  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        console.log(request);
        let param = new FormData();
        param.append("search_input", request.input);
        Api.post("/web-app/context/sync/search-unit", param)
          .then((response) => {
            callback(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      if (form?.unit_id !== "") {
        setUnitSeveral(form.unit_id ? [form.unit_id] : []);
      }
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (form.unit_id) {
          newOptions = [form.unit_id];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setUnitSeveral(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [form?.unit_id, inputValue, fetch]);

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          const page = params.request.endRow / perPage;

          await storage.remove('driver');
          let export_filter = await storage.set("driver", params.request);

          Api.post(
            `/web-app/context/data/driver?per_page=${perPage}&page=${Math.ceil(
              page
            )}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.success({
                rowData: data.data.data,
                rowCount: data.data.lastData,
              });
            })
            .catch((error) => {
              console.error(error);
              params.fail();
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    const _init_sync = async () => {
      setOverlay(true);
      try {
        const { data } = await Api.get(
          `/web-app/context/sync/latest-driver?date=${moment().format(
            "YYYY-MM-DD"
          )}`
        );
        if (data.status) {
          setOverlay(false);
          setSyncLatest(data);
        } else {
          setOverlay(false);
        }
      } catch (error) {
        setOverlay(false);
        console.log(error);
      }
    };
    _init_sync();
    return () => {
      setSyncLatest({});
    };
  }, []);

  useEffect(() => {
    setForm({
      user_id: driverDetails.id,
      user_identity: driverDetails.user_identity,
      user_old_identify: driverDetails.user_old_identify,
      user_name: driverDetails.user_name,
      user_nickname: driverDetails.user_nickname,
      user_driver_sim: driverDetails.user_driver_sim,
      user_driver_sim_expire: driverDetails.user_driver_sim_expire,
      user_handphone_1: driverDetails.user_handphone_1,
      user_handphone_2: driverDetails.user_handphone_2,
      unit_vehicle_no: driverDetails.unit_vehicle_no,
      unit_vehicle_type: driverDetails.unit_vehicle_type,
      unit_description: driverDetails.unit_description,
      user_status: driverDetails.user_status,
      photo_device: driverDetails.photo_device,
      fingerprint_option: driverDetails.fingerprint_option,
      last_absent: driverDetails.last_absent,
      days: driverDetails.days,
      status: driverDetails.user_status,
    });
  }, [driverDetails]);

  useEffect(() => {
    console.log(form);
  }, [form]);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 20;

  const statusCellRender = (params) => {
    let status_driver = "";
    if (params.value === 1) {
      status_driver = "Aktif";
    } else {
      status_driver = "Tidak Aktif";
    }
    return status_driver;
  }

  const statusDaysSim = (params) => {
    let daysSim = "";
    console.log(params);
    return params.value;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Aksi",
      field: "action",
      minWidth: 180,
      editable: false,
      enableRowGroup: false,
      filter: false,
      sortable: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        _view_detail: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_view_detail(data);
        },
        _view_edit: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_edit(data);
        },
        _view_delete: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_clear_finger(data);
        },
      },
    },
    {
      headerName: "#",
      field: "#",
      sortable: false,
      minWidth: 120,
      editable: false,
      enableRowGroup: false,
      filter: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Last absent",
      field: "last_absent",
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
      },
    },
    {
      headerName: "Hari",
      field: "days",
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Status",
      field: "user_status",
      sort: "asc",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,

      cellRenderer: statusCellRender,
      filter: StatusFilter,
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "ID Baru",
      field: "user_identity",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "ID lama",
      field: "user_old_identify",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Nama KTP",
      field: "user_name",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Nama panggilan",
      field: "user_nickname",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Jenis SIM",
      field: "user_driver_sim",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Berlaku SIM",
      field: "user_driver_sim_expire",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
      },
    },
    {
      headerName: "Hari SIM",
      field: "days_sim",
      cellStyle: (params) => {
        if (params.value === 14) {
          //mark police cells as red
          return { color: "black", backgroundColor: "orange" };
        } else if (params.value < 0) {
          //mark police cells as red
          return { color: "black", backgroundColor: "red" };
        }
        return null;
      },
      minWidth: 120,
      enableRowGroup: true,
      editable: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "No HP 1",
      field: "user_handphone_1",
      minWidth: 120,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "No HP 2",
      field: "user_handphone_2",
      minWidth: 120,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "No unit polisi",
      field: "unit_number",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Jenis truk",
      field: "unit_type",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Tanggal entry",
      field: "created_date",
      enableRowGroup: true,
      editable: false,
      minWidth: 180,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
      },

    },
    {
      headerName: "Fingerprint",
      field: "fingerprint_option",
      enableRowGroup: true,
      editable: false,
      minWidth: 180,
      filter: false
    },
    {
      headerName: "Keterangan",
      field: "description",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
  ]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const _init_view_detail = async (id) => {
    setOverlay(true);
    try {
      const { data } = await Api.get("/web-app/context/detail/driver", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setViewDetail(true);
        setDriverDetails(data.data);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };

  const _init_edit = async (id) => {
    setOverlay(true);
    setDriverUnit(false);
    setError({});
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    try {
      const { data } = await Api.get("/web-app/context/edit/driver", {
        params: {
          id: id,
        },
      });
      console.log(data)
      if (data.status) {
        setOverlay(false);
        setDriverDetails(data.data);
        setEditView(true);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  
  const _handle_close_edit = () => {
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    setEditView(false);
  };

  const selectphotodevice = () => {
    photoDeviceREF.current?.click();
  }

  useEffect(() => {
    console.log(photoDevice);

    if (photoDevice !== null && photoDevice !== undefined) {
      console.log(photoDeviceREF.current?.files[0]);
      var file = photoDeviceREF.current?.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setPreviewPhotoDevice(e.target.result)
      }.bind(this);
    }
  }, [photoDevice])

  const _handle_submit = async (e) => {
    e.preventDefault();
    setOverlay(true);
    setError({});
    try {

      const postdata = new FormData();

      if (photoDevice !== null) {
        postdata.append(
          "photo_device",
          photoDevice,
          photoDevice.name
        );
      }
      postdata.append('user_id', form.user_id);
      postdata.append('user_identity', form.user_identity);
      postdata.append('user_old_identify', form.user_old_identify);
      postdata.append('user_name', form.user_name);
      postdata.append('user_nickname', form.user_nickname);
      postdata.append('user_driver_sim', form.user_driver_sim);
      postdata.append('user_driver_sim_expire', form.user_driver_sim_expire);
      postdata.append('user_handphone_1', form.user_handphone_1);
      postdata.append('user_handphone_2', form.user_handphone_2);
      postdata.append('fingerprint_option', form.fingerprint_option);
      postdata.append('unit_vehicle_no', form.unit_vehicle_no);
      postdata.append('unit_vehicle_type', form.unit_vehicle_type);
      postdata.append('unit_description', form.unit_description);
      postdata.append('user_status', form.user_status);

      const { data } = await Api.post("/web-app/context/submit/edit/driver", postdata);
      if (data.status) {
        MySwal.fire({
          title: "Berhasil!",
          text: "Informasi driver disimpan.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setpopup({
          ...alertpoup,
          status: true,
          error: false,
          message: data.message,
        });
        setEditView(false);
        setOverlay(false);
        setPhotoDevice(null);
        setForm({
          user_id: "",
          user_identity: "",
          user_old_identify: "",
          user_name: "",
          user_nickname: "",
          user_driver_sim: "",
          user_driver_sim_expire: "",
          user_handphone_1: "",
          user_handphone_2: "",
          unit_vehicle_no: "",
          unit_vehicle_type: "",
          unit_description: "",
          fingerprint_option: 'true',
          user_status: "",
          status: "",
        });
        setError({});
        _refresh_cache();
      } else {
        setpopup({
          ...alertpoup,
          status: false,
          error: true,
          message: data.message,
        });
        setOverlay(false);
        setError(data.error);
      }
    } catch (error) {
      setpopup({
        ...alertpoup,
        status: false,
        error: true,
        message: error.message,
      });
      setOverlay(false);
      console.log(error);
    }
  };
  const _handle_create_driver = () => {
    setEditView(true);
    setForm({});
    setError({});
  };
  const _change_driver_unit = () => {
    setDriverUnit(!driverUnitForm);
  };
  const _handle_form = (property, event) => {
    const collection = { ...form };
    collection[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setForm(collection);
  };
  const _exportExcel = async () => {
    var filter = base64.encode(JSON.stringify(storage.get('driver')));
    window.open(def.API_URL + '/web-app/context/driver/export?filter=' + filter.replace("==", ""));
  }
  const _driver_list = async () => {
    setOverlay(true);
    try {
      const { data } = await Api.get(
        `/web-app/context/sync/driver/request?date=${moment().format(
          "YYYY-MM-DD"
        )}`
      );
      if (data.status) {
        setOverlay(false);
        _refresh_cache();
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _call_type_vehicle = async (unit_id) => {
    console.log(unit_id);
    if (unit_id !== null) {
      try {
        let payload = new FormData();
        payload.append("unit_id", unit_id.unit_vehicle_no);
        const { data } = await Api.post(
          `/web-app/context/sync/unit-type`,
          payload
        );
        if (data.status) {
          const collection = { ...form };
          collection["unit_vehicle_no"] = unit_id.unit_vehicle_no;
          collection["unit_vehicle_type"] = data.data.unit_vehicle_type;
          collection["unit_description"] = data.data.unit_description;
          setForm(collection);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _handle_close_detail = () => {
    setViewDetail(false);
  };

  const _init_clear_finger = (id) => {
    console.log(id);
    setClearID(id);
    askDelete(true);
  }

  const _handle_close_remove_confirm = async () => {
    try {
      let payload = new FormData();
      payload.append("driver_id", clearID);
      const { data } = await Api.post(
        `/web-app/context/driver/clear-finger`,
        payload
      );
      if (data.status) {
        askDelete(false);
        _refresh_cache();
        MySwal.fire({
          title: "Berhasil!",
          text: "Sistem berhasil reset driver device.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        MySwal.fire({
          title: "Sistem gagal!",
          text: "Gagal reset driver device mohon ulangi lagi nanti.",
          icon: "error",
          confirmButtonText: "OK",
        });
        askDelete(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _preview_photo = (photo) => {
    setlightboxopen({
      ...lightboxopen,
      open: true,
      image: photo
    });
  }

  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: BtnDriverRender,
  });

  const devicephotochange = (e) => {
    setPhotoDevice(e.target.files[0]);
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MKA - driver</title>
        <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={overlay}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          open={viewDelete}
          onClose={() => askDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Anda yakin ingin mereset device driver ini ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Device driver ini akan direset secara permanen dan tidak ada
              pemulihan data setelah direset.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => askDelete(false)}>Batal</Button>
            <Button onClick={_handle_close_remove_confirm} autoFocus>
              Ya, reset
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          sx={{
            zIndex: 1000
          }}
          fullWidth={true}
          maxWidth={"sm"}
          open={viewDetail}
          onClose={_handle_close_detail}
          TransitionComponent={Transition}
        >
          <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={_handle_close_detail}
                aria-label="close"
              >
                <Close sx={{ color: "#000000" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                Detail driver
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, padding: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-last-absent"
                    onChange={(event) => _handle_form("last_absent", event)}
                    value={form?.last_absent}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="last absent"
                    variant="standard"
                    error={error && form.last_absent === "" ? true : false}
                    helperText={
                      error && form.last_absent === "" ? error.last_absent : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-days"
                    onChange={(event) => _handle_form("days", event)}
                    value={form?.days}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="hari"
                    variant="standard"
                    error={error && form.days === "" ? true : false}
                    helperText={error && form.days === "" ? error.days : ""}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormLabel
                  className={classes.input_label}
                  id="demo-row-radio-buttons-group-label"
                >
                  Status
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={form?.user_status}
                    onChange={(event) => _handle_form("user_status", event)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="Aktif"
                    />
                    <FormControlLabel
                      value={2}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="Tidak aktif"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-id"
                    onChange={(event) => _handle_form("user_identity", event)}
                    value={form?.user_identity}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Driver ID baru"
                    variant="standard"
                    error={error && form.user_identity === "" ? true : false}
                    helperText={
                      error && form.user_identity === ""
                        ? error.user_identity
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-old-id"
                    onChange={(event) => _handle_form("user_old_identify", event)}
                    value={form?.user_old_identify}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Driver ID lama"
                    variant="standard"
                    error={error && form.user_old_identify === "" ? true : false}
                    helperText={
                      error && form.user_old_identify === ""
                        ? error.user_old_identify
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-username"
                    onChange={(event) => _handle_form("user_name", event)}
                    value={form?.user_name}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Nama KTP"
                    variant="standard"
                    error={error && form.user_name === "" ? true : false}
                    helperText={
                      error && form.user_name === "" ? error.user_name : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-usernickname"
                    onChange={(event) => _handle_form("user_nickname", event)}
                    value={form?.user_nickname}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Nama panggilan"
                    variant="standard"
                    error={error && form.user_nickname === "" ? true : false}
                    helperText={
                      error && form.user_nickname === ""
                        ? error.user_nickname
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-user-sim"
                    onChange={(event) => _handle_form("user_driver_sim", event)}
                    value={form?.user_driver_sim}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Jenis SIM"
                    variant="standard"
                    error={error && form.user_driver_sim === "" ? true : false}
                    helperText={
                      error && form.user_driver_sim === ""
                        ? error.user_driver_sim
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-user-sim-expire"
                    onChange={(event) =>
                      _handle_form("user_driver_sim_expire", event)
                    }
                    value={form?.user_driver_sim_expire}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Berlaku s/d"
                    variant="standard"
                    error={
                      error && form.user_driver_sim_expire === "" ? true : false
                    }
                    helperText={
                      error && form.user_driver_sim_expire === ""
                        ? error.user_driver_sim_expire
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-user-handphone-1"
                    onChange={(event) => _handle_form("user_handphone_1", event)}
                    value={form?.user_handphone_1}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. handphone 1"
                    variant="standard"
                    error={error && form.user_handphone_1 === "" ? true : false}
                    helperText={
                      error && form.user_handphone_1 === ""
                        ? error.user_handphone_1
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-user-handphone-2"
                    onChange={(event) => _handle_form("user_handphone_2", event)}
                    value={form?.user_handphone_2}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. handphone 2"
                    variant="standard"
                    error={error && form.user_handphone_2 === "" ? true : false}
                    helperText={
                      error && form.user_handphone_2 === ""
                        ? error.user_handphone_2
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  {driverUnitForm ? (
                    <React.Fragment>
                      <Autocomplete
                        id="unit-select-demo"
                        sx={{ width: "100%" }}
                        classes={{
                          option: classes.option,
                        }}
                        getOptionLabel={(option) =>
                          typeof option.unit_vehicle_no === "string"
                            ? option.unit_vehicle_no
                            : option.unit_vehicle_no
                        }
                        filterOptions={(x) => x}
                        options={unitListSeveral}
                        autoComplete
                        value={form.unit_id}
                        includeInputInList
                        filterSelectedOptions
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {option.unit_vehicle_no} -{" "}
                                    {option.unit_truck_no}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                        onChange={(event, newValue) => {
                          setUnitSeveral(
                            newValue
                              ? [newValue, ...unitListSeveral]
                              : unitListSeveral
                          );
                          const collection = { ...form };
                          collection["unit_id"] = newValue;
                          setForm(collection);
                          _call_type_vehicle(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            id="input-driver-user-select-unit"
                            className={classes.input_label}
                            margin="normal"
                            label="Cari No.pol/UnitID"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <StyledButton
                                  variant="text"
                                  onClick={() => _change_driver_unit()}
                                >
                                  batal
                                </StyledButton>
                              ),
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </React.Fragment>
                  ) : (
                    <TextField
                      disabled
                      id="input-driver-type-vehicle-no"
                      onChange={(event) => _handle_form("unit_vehicle_no", event)}
                      value={form?.unit_vehicle_no}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="No. Polisi"
                      variant="standard"
                      error={error && form.unit_vehicle_no === "" ? true : false}
                      helperText={
                        error && form.unit_vehicle_no === ""
                          ? error.unit_vehicle_no
                          : ""
                      }
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-driver-type-vehicle"
                    onChange={(event) => _handle_form("unit_vehicle_type", event)}
                    value={form?.unit_vehicle_type}
                    className={classes.input_label}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="Jenis"
                    variant="standard"
                    error={error && form.unit_vehicle_type === "" ? true : false}
                    helperText={
                      error && form.unit_vehicle_type === ""
                        ? error.unit_vehicle_type
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormLabel
                  className={classes.input_label}
                  id="demo-row-radio-buttons-group-label"
                >
                  Sistem Absen
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={form?.fingerprint_option}
                    onChange={(event) => _handle_form("fingerprint_option", event)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="FINGERPRINT"
                    />
                    <FormControlLabel
                      value={false}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="PIN"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {
                console.log(form?.fingerprint_option)
              }
              {
                form?.fingerprint_option === 'false' || form?.fingerprint_option === false ? (<Grid item xs={12} md={12}>
                  {
                    form?.photo_device !== null ? (<React.Fragment>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <img
                          style={{
                            width: 120,
                            height: 120,
                            objectFit: "cover",
                          }}
                          onClick={() => _preview_photo(`${def.API_URL}${form?.photo_device}`)}
                          src={`${def.API_URL}${form?.photo_device}`}
                          srcSet={`${def.API_URL}${form?.photo_device}`}
                          alt={form?.photo_device}
                          loading="lazy"
                        />
                      </Box>
                    </React.Fragment>) : null
                  }

                </Grid>) : null
              }

              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    InputLabelProps={{ shrink: true }}
                    id="input-driver-description"
                    onChange={(event) => _handle_form("unit_description", event)}
                    value={form?.unit_description}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Keterangan"
                    variant="standard"
                    error={error && form.unit_description === "" ? true : false}
                    helperText={
                      error && form.unit_description === ""
                        ? error.unit_description
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <Dialog
          sx={{
            zIndex: 1000
          }}
          fullWidth={true}
          maxWidth={"sm"}
          open={viewEdit}
          onClose={() => _handle_close_edit()}
          TransitionComponent={Transition}
        >
          <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => _handle_close_edit()}
                aria-label="close"
              >
                <Close sx={{ color: "#000000" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                Driver
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, padding: 5 }}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              {alertpoup.status ? (
                <React.Fragment>
                  {alertpoup.error === false ? (
                    <Alert severity="success">{alertpoup.message}</Alert>
                  ) : (
                    <Alert
                      onClose={() => {
                        setpopup({
                          ...alertpoup,
                          status: false,
                          error: false,
                          message: "",
                        });
                      }}
                      severity="error"
                    >
                      <AlertTitle>Terjadi kesalahan</AlertTitle>
                      {alertpoup.message} — <strong>sistem error</strong>
                    </Alert>
                  )}
                </React.Fragment>
              ) : null}
            </Stack>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-last-absent"
                      onChange={(event) => _handle_form("last_absent", event)}
                      value={form?.last_absent}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="last absent"
                      variant="standard"
                      error={error && form.last_absent === "" ? true : false}
                      helperText={
                        error && form.last_absent === "" ? error.last_absent : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-days"
                      onChange={(event) => _handle_form("days", event)}
                      value={form?.days}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="hari"
                      variant="standard"
                      error={error && form.days === "" ? true : false}
                      helperText={error && form.days === "" ? error.days : ""}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormLabel
                    className={classes.input_label}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Status
                  </FormLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={form?.user_status}
                      onChange={(event) => _handle_form("user_status", event)}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={1}
                        className={classes.input_label}
                        control={<Radio />}
                        label="Aktif"
                      />
                      <FormControlLabel
                        value={2}
                        className={classes.input_label}
                        control={<Radio />}
                        label="Tidak aktif"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-id"
                      onChange={(event) => _handle_form("user_identity", event)}
                      value={form?.user_identity}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Driver ID baru"
                      variant="standard"
                      error={error && form.user_identity === "" ? true : false}
                      helperText={
                        error && form.user_identity === ""
                          ? error.user_identity
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-old-id"
                      onChange={(event) =>
                        _handle_form("user_old_identify", event)
                      }
                      value={form?.user_old_identify}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Driver ID lama"
                      variant="standard"
                      error={
                        error && form.user_old_identify === "" ? true : false
                      }
                      helperText={
                        error && form.user_old_identify === ""
                          ? error.user_old_identify
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-username"
                      onChange={(event) => _handle_form("user_name", event)}
                      value={form?.user_name}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Nama KTP"
                      variant="standard"
                      error={error && form.user_name === "" ? true : false}
                      helperText={
                        error && form.user_name === "" ? error.user_name : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-usernickname"
                      onChange={(event) => _handle_form("user_nickname", event)}
                      value={form?.user_nickname}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Nama panggilan"
                      variant="standard"
                      error={error && form.user_nickname === "" ? true : false}
                      helperText={
                        error && form.user_nickname === ""
                          ? error.user_nickname
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-user-sim"
                      onChange={(event) => _handle_form("user_driver_sim", event)}
                      value={form?.user_driver_sim}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Jenis SIM"
                      variant="standard"
                      error={error && form.user_driver_sim === "" ? true : false}
                      helperText={
                        error && form.user_driver_sim === ""
                          ? error.user_driver_sim
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-user-sim-expire"
                      onChange={(event) =>
                        _handle_form("user_driver_sim_expire", event)
                      }
                      value={form?.user_driver_sim_expire}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Berlaku s/d"
                      variant="standard"
                      error={
                        error && form.user_driver_sim_expire === "" ? true : false
                      }
                      helperText={
                        error && form.user_driver_sim_expire === ""
                          ? error.user_driver_sim_expire
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-user-handphone-1"
                      onChange={(event) =>
                        _handle_form("user_handphone_1", event)
                      }
                      value={form?.user_handphone_1}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="No. handphone 1"
                      variant="standard"
                      error={error && form.user_handphone_1 === "" ? true : false}
                      helperText={
                        error && form.user_handphone_1 === ""
                          ? error.user_handphone_1
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-user-handphone-2"
                      onChange={(event) =>
                        _handle_form("user_handphone_2", event)
                      }
                      value={form?.user_handphone_2}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="No. handphone 2"
                      variant="standard"
                      error={error && form.user_handphone_2 === "" ? true : false}
                      helperText={
                        error && form.user_handphone_2 === ""
                          ? error.user_handphone_2
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    {driverUnitForm ? (
                      <React.Fragment>
                        <Autocomplete
                          id="unit-select-demo"
                          sx={{ width: "100%" }}
                          classes={{
                            option: classes.option,
                          }}
                          getOptionLabel={(option) =>
                            typeof option.unit_vehicle_no === "string"
                              ? option.unit_vehicle_no
                              : option.unit_vehicle_no
                          }
                          filterOptions={(x) => x}
                          options={unitListSeveral}
                          autoComplete
                          value={form.unit_id}
                          includeInputInList
                          filterSelectedOptions
                          renderOption={(props, option) => {
                            return (
                              <li {...props}>
                                <Grid container alignItems="center">
                                  <Grid item xs>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {option.unit_vehicle_no} -{" "}
                                      {option.unit_truck_no}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </li>
                            );
                          }}
                          onChange={(event, newValue) => {
                            setUnitSeveral(
                              newValue
                                ? [newValue, ...unitListSeveral]
                                : unitListSeveral
                            );
                            const collection = { ...form };
                            collection["unit_id"] = newValue;
                            setForm(collection);
                            _call_type_vehicle(newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              id="input-driver-user-select-unit"
                              className={classes.input_label}
                              margin="normal"
                              label="Cari No.pol/UnitID"
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <StyledButton
                                    variant="text"
                                    onClick={() => _change_driver_unit()}
                                  >
                                    batal
                                  </StyledButton>
                                ),
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <TextField
                        disabled
                        id="input-driver-type-vehicle-no"
                        onChange={(event) =>
                          _handle_form("unit_vehicle_no", event)
                        }
                        value={form?.unit_vehicle_no}
                        className={classes.input_label}
                        margin="normal"
                        fullWidth
                        label="No. Polisi"
                        variant="standard"
                        error={
                          error && form.unit_vehicle_no === "" ? true : false
                        }
                        helperText={
                          error && form.unit_vehicle_no === ""
                            ? error.unit_vehicle_no
                            : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <StyledButton
                                variant="text"
                                onClick={() => _change_driver_unit()}
                              >
                                <EditTwoTone fontSize="small" sx={{ mr: 1 }} />
                                ganti
                              </StyledButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      disabled
                      id="input-driver-type-vehicle"
                      onChange={(event) =>
                        _handle_form("unit_vehicle_type", event)
                      }
                      value={form?.unit_vehicle_type}
                      className={classes.input_label}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Jenis"
                      variant="standard"
                      error={
                        error && form.unit_vehicle_type === "" ? true : false
                      }
                      helperText={
                        error && form.unit_vehicle_type === ""
                          ? error.unit_vehicle_type
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormLabel
                    className={classes.input_label}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Sistem Absen
                  </FormLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={form?.fingerprint_option}
                      onChange={(event) => _handle_form("fingerprint_option", event)}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={true}
                        // disabled
                        className={classes.input_label}
                        control={<Radio />}
                        label="FINGERPRINT"
                      />
                      <FormControlLabel
                        value={false}
                        // disabled
                        className={classes.input_label}
                        control={<Radio />}
                        label="PIN"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {
                  form?.fingerprint_option === "false" || form.fingerprint_option === false ? (
                    <React.Fragment>
                      <Grid item xs={12} md={12}>
                        <div className="box-photo-device-upload" onClick={() => selectphotodevice()}>
                          {photoDevicePreview !== null ? <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "center", marginTop: 5 }}>
                            <img
                              style={{
                                width: 220,
                                height: 120,
                                objectFit: "cover",
                              }}
                              onClick={() => _preview_photo(photoDevicePreview)}
                              src={`${photoDevicePreview}`}
                              alt={photoDevicePreview}
                              loading="lazy"
                            />
                          </Box> : <FileUploadIcon sx={{ fontSize: 40 }} />}
                  
                          <p>
                            unggah foto device driver
                          </p>
                          <p>
                            ext: JPEG, JPG, PNG dan WEBP
                          </p>
                          {
                            error ? (
                              <React.Fragment>
                                <strong style={{ fontSize: 12, color: 'red' }}>{error.photo_device}</strong>
                              </React.Fragment>
                            ) : null
                          }
                          {
                            photoDevice ? (
                              <React.Fragment>
                                <p>
                                  {photoDevice.name}
                                </p>
                              </React.Fragment>
                            ) : null
                          }
                          <input ref={photoDeviceREF} type="file" onChange={devicephotochange} />
                        </div>

                        {
                          form?.photo_device !== null ? (
                            <React.Fragment>
                              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 5, marginBottom: 5 }}>
                                <p style={{fontWeight: "bold", fontSize: 11, textTransform: 'uppercase', letterSpacing: 1}}>
                                  Foto device sebelum nya
                                </p>
                                <Box sx={{border: "2px solid grey", padding:2}}>
                                  <img
                                    style={{
                                      width: 120,
                                      height: 120,
                                      objectFit: "cover",
                                    }}
                                    onClick={() => _preview_photo(`${def.API_URL}${form?.photo_device}`)}
                                    src={`${def.API_URL}${form?.photo_device}`}
                                    srcSet={`${def.API_URL}${form?.photo_device}`}
                                    alt={form?.photo_device}
                                    loading="lazy"
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ) : null
                        }
                      </Grid>
                    </React.Fragment>
                  ) : null
                }
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="input-driver-description"
                      onChange={(event) =>
                        _handle_form("unit_description", event)
                      }
                      value={form?.unit_description}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Keterangan"
                      variant="standard"
                      error={error && form.unit_description === "" ? true : false}
                      helperText={
                        error && form.unit_description === ""
                          ? error.unit_description
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                  <StyledLoadingButton
                    loading={loading}
                    loadingPosition="start"
                    disabled={loading}
                    onClick={_handle_submit}
                    type="button"
                    startIcon={<Save />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Simpan
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        <Box
          sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
        >
          <Breadcrumbs
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Resources
            </Link>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "bold",
                letterSpacing: 1,
                textTransform: "uppercase",
                padding: 2,
              }}
              color="text.primary"
            >
              driver
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          className="ag-theme-alpine"
          sx={{ height: 800, marginBottom: 20, width: "100%" }}
        >
          <Stack sx={{ margin: 2 }} spacing={2} direction="row">
            <StyledButton variant="text" onClick={() => _refresh_cache()}>
              <RefreshOutlined sx={{ mr: 1 }} />
              Reload
            </StyledButton>
            <StyledButton variant="text" onClick={() => _driver_list()}>
              <CloudSync sx={{ mr: 1 }} />
              Check new driver
            </StyledButton>
            <StyledButton variant="text" onClick={() => _exportExcel()}>
              <Download sx={{ mr: 1 }} />
              Export Excel
            </StyledButton>
            {syncLatest.latest_sync === undefined ? (
              <StyledButton variant="text" disabled={true}>
                <AccessTime sx={{ mr: 1 }} />
                latest async -
              </StyledButton>
            ) : (
              <StyledButton variant="text" disabled={true}>
                <AccessTime sx={{ mr: 1 }} />
                latest async [{syncLatest.latest_sync}]
              </StyledButton>
            )}
          </Stack>
          {lightboxopen.open && (
            <Lightbox
              mainSrc={`${lightboxopen.image}`}
              onCloseRequest={() => setlightboxopen({
                ...lightboxopen,
                open: false,
                image: null,
              })}
            />
          )}
          <AgGridReact
            ref={gridREF}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            onGridReady={onGridReady}
            frameworkComponents={frameworkComponents}
            chartThemes={["ag-theme-alpine"]}
            defaultColDef={defaultColDef}
            rowGroupPanelShow={"always"}
            rowModelType={"serverSide"}
            serverSideStoreType={"partial"}
            purgeClosedRowNodes={true}
            cacheBlockSize={20}
            animateRows={true}
            pivotMode={false}
            sideBar={sideBar}
            columnDefs={columnDefs}
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
}
