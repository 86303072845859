import * as React from "react";
import ReactDOM from "react-dom";
import 'react-image-lightbox/style.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { createBrowserHistory } from "history";

// Define theme settings
const light = {
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiButton: {
      root: {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 13,
        letterSpacing: 1,
        textTransform: "uppercase",
      },
    },
  },
};

const dark = {
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiButton: {
      root: {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 13,
        letterSpacing: 1,
        textTransform: "uppercase",
      },
    },
  },
};
export default function App() {
  const history = createBrowserHistory();
  // The light theme is used by default
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };
  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
      <BrowserRouter history={history}>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}
