import React, { Component } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";



class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.data.usertype_id);
  }
  render() {
    return (
      <ButtonGroup disableElevation variant="text">
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.btnClickedHandler}
          color="inherit"
        >
          <InfoIcon
            sx={{
              fontSize: '1.2rem',
              color: "#000000",
            }}
          />
        </IconButton>
      </ButtonGroup>
    );
  }
}


export default BtnCellRenderer;