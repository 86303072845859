const initialState = {
  token: null,
  profile: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        ...action.payload,
      };

    case "USER_LOGOUT":
      return initialState;

    case "USER_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };

    case "RESTORE:USER":
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
