import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    CssBaseline,
    Input,
    InputLabel,
    Dialog,
    Alert,
    AlertTitle,
    MenuItem,
    InputAdornment,
    Divider,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    Avatar,
    DialogTitle,
    Stack,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    Link,
    Box,
    Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
    RefreshOutlined,
    AccountCircle,
    Save,
    Close,
    AssignmentInd,
    Lock,
    Download,
    ExitToAppOutlined,
    CloudSync,
    AccountTree,
    VisibilityOff,
    AccessTime,
    Visibility,
} from "@mui/icons-material";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import moment from "moment";
import storage from "helper/helper.storage";
import { currency } from "helper/helper.idr";
import { def } from "../../helper/helper.const";
import StatusFilter from "components/datatables/status_filter";
import BtnActionRender from "components/datatables/btn_roadmoney_render";
const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input_text: {
        textTransform: "uppercase",
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RoadMoneyDetails() {
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [orderDetails, setOrderDetails] = React.useState(null);
    const [syncLatest, setSyncLatest] = React.useState({});
    const [overlay, setOverlay] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [viewEdit, setEditView] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [clearID, setClearID] = React.useState("");
    const gridREF = useRef();
    const perPage = 20;
    const [viewDelete, askDelete] = React.useState(false);
    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );
    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    console.log(params);
                    let HEADER_CONFIG = {
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                    };
                    const page = params.request.endRow / perPage;
                    Api.post(
                        `/web-app/context/data/roadmoneydetails?page=${Math.ceil(
                            page
                        )}&per_page=${Math.ceil(perPage)}`,
                        JSON.stringify(params.request),
                        HEADER_CONFIG
                    )
                        .then((data) => {
                            console.log(data.data);
                            params.successCallback(data.data.data, data.data.lastData);
                        })
                        .catch((error) => {
                            console.error(error);
                            params.successCallback([], 0);
                        });
                },
            };

            gridApi.setServerSideDatasource(dataSource);
        }
    }, [gridApi]);

    useEffect(() => {

    }, []);

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        };
    }, []);

    const statusCellRender = (params) => {
        let statusUnit = "";
        if (params.value === 1) {
            statusUnit = "Aktif";
        } else {
            statusUnit = "Tidak Aktif";
        }
        return statusUnit;
    };

    // enables pagination in the grid
    const pagination = true;

    // sets 10 rows per page (default is 100)
    const paginationPageSize = 20;

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Aksi",
            field: "action",
            minWidth: 180,
            enableRowGroup: false,
            editable: false,
            filter: false,
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
                _view_detail: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_view_detail(data);
                },
            },
        },
        {
            headerName: "No",
            field: "#",
            editable: false,
            enableRowGroup: true,
            filter: false,
        },
        {
            headerName: "No SO",
            field: "SONumber",
            minWidth: 140,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Tanggal Muat/Jalan",
            field: "TanggalMuat",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = cellValue;
                    if (dateAsString == null) return -1;
                    var dateParts = dateAsString.split("/");
                    var cellDate = new Date(
                        Number(dateParts[2]),
                        Number(dateParts[1]) - 1,
                        Number(dateParts[0])
                    );

                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    }

                    if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                },
                browserDatePicker: true,
            },
        },
        {
            headerName: "Customer",
            minWidth: 220,
            field: "CustomerNama",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Rute",
            field: "Rute",
            minWidth: 280,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "No Polisi",
            minWidth: 100,
            field: "VehicleNo",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jenis Truck",
            minWidth: 140,
            field: "StrJenisTruck",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Nama Driver",
            field: "NamaDriver",
            editable: false,
            minWidth: 180,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Harga Borongan",
            field: "HargaBorongan",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Kawalan",
            field: "Kawalan",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Timbangan",
            field: "Timbangan",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Karantina",
            field: "Karantina",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "SPSI",
            field: "SPSI",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Multidrop",
            field: "Multidrop",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Tambahan Rute Muat",
            field: "TotalTambahanRuteMuat",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Tambahan Rute Lain",
            field: "TotalTambahanLain",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Total Borongan",
            field: "TotalBorongan",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Potongan Driver",
            field: "TotalPotonganDriver",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Total Alokasi",
            field: "TotalAlokasi",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Keterangan Admin",
            field: "KeteranganAdmin",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
    ]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const [form, setForm] = React.useState({});

    useEffect(() => {
        setForm(orderDetails);
    }, [orderDetails]);


    const validate = () => {
        const schema = {
            Keterangan: {
                require: () =>
                    !!form?.Keterangan ? "" : `Keterangan tidak boleh kosong`,
            },
        };
        let error = validate_input(schema);
        setError(error);
        return error;
    };

    const _handle_close_edit = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setEditView(false);
    };

    const _init_view_detail = async (id) => {
        setOverlay(true);
        try {
            const { data } = await Api.get("/web-app/context/roadmoney/detail?id=" + id);
            if (data.status) {
                setOverlay(false);
                setViewDetail(true);
                setOrderDetails(data.data);
            } else {
                setOverlay(false);
            }
        } catch (error) {
            setOverlay(false);
            console.log(error);
        }
    };

    const _handle_close_detail = () => {
        setViewDetail(false);
    };

    const _exportExcel = useCallback(() => {
        gridREF.current.api.exportDataAsExcel();
    }, []);

    const _refresh_cache = useCallback(() => {
        gridREF.current.api.refreshServerSideStore({ purge: true });
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const [frameworkComponents, setFrameworkComponents] = useState({
        btnCellRenderer: BtnActionRender,
    });

    const _handle_form = (property, event) => {
        const collection = { ...form };
        collection[property] = event.target.value;

        setError({
            ...error,
            [property]: "",
        });

        setForm(collection);
    };

    const excelStyles = [
        {
            id: "header",
            alignment: {
                vertical: "Center",
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
                bold: true,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Center',
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
            },
        },
    ];

    return (
        <Grid container component="main" className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - Rincian Uang Jalan</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/document/rincian-uang-jalan" />
            </Helmet>
            <CssBaseline />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={overlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box
                sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
            >
                <Breadcrumbs
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 1,
                        textTransform: "uppercase",
                        padding: 2,
                    }}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Dokumen
                    </Link>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        color="text.primary"
                    >
                        Rincian Uang Jalan
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={viewDetail}
                onClose={_handle_close_detail}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_handle_close_detail}
                            aria-label="close"
                        >
                            <Close sx={{ color: "#000000" }} />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Detail Rincian Uang Jalan
                        </Typography>
                    </Toolbar>
                </AppBar>
                {alertpoup.status ? (
                    <React.Fragment>
                        <Box sx={{ flexGrow: 1, padding: 5 }}>
                            <Stack sx={{ width: "100%" }} spacing={2}>
                                {alertpoup.error === false ? (
                                    <Alert severity="success">{alertpoup.message}</Alert>
                                ) : (
                                    <Alert
                                        onClose={() => {
                                            setpopup({
                                                ...alertpoup,
                                                status: false,
                                                error: false,
                                                message: "",
                                            });
                                        }}
                                        severity="error"
                                    >
                                        <AlertTitle>Terjadi kesalahan</AlertTitle>
                                        {alertpoup.message} — <strong>sistem error</strong>
                                    </Alert>
                                )}
                            </Stack>

                        </Box>

                    </React.Fragment>
                ) : null}

                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Typography
                                className="text-detail-invoice-info"
                                variant="p"
                                component="div"
                            >
                                Basic info
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            no so
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.SONumber}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            customer
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.CustomerNama}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            no polisi
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.VehicleNo}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            jenis truck
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.StrJenisTruck}
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            tanggal muat/jalan
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.TanggalMuat}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            rute
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.Rute}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            nama driver
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.NamaDriver}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            keterangan admin
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            {orderDetails?.vw_RincianUJ?.KeteranganAdmin}
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                variant="p"
                                component="div"
                            >
                                Borongan
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>

                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            harga borongan
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.HargaBorongan)},-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            kawalan
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.Kawalan)},-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            timbangan
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.Timbangan)},-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            karantina
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.Karantina)},-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            SPSI
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.SPSI)},-
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-title-table-invoice">
                                        <Typography
                                            className="text-labeled-invoice"
                                            variant="p"
                                            component="div"
                                        >
                                            Multidrop
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            :
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className="value-invoiced"
                                            variant="p"
                                            component="div"
                                        >
                                            Rp {currency(orderDetails?.vw_RincianUJ?.Multidrop)},-
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                            <br />
                            <Divider />
                            {
                                orderDetails !== null && orderDetails?.vw_RincianUJ_TambahanRuteMuat.length !== 0 ? (
                                    <React.Fragment>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            Tambahan Rute Muat
                                        </Typography>
                                        <Grid item xs={12} md={12}>
                                            <table style={{ width: '100%' }}>
                                                {
                                                    orderDetails?.vw_RincianUJ_TambahanRuteMuat.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-title-table-invoice">
                                                                    <Typography
                                                                        className="text-labeled-invoice"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        {value.Keterangan}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        :
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        className="value-invoiced"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        Rp {currency(value.values)},-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </table>


                                        </Grid>
                                        <Divider />
                                    </React.Fragment>
                                ) : null
                            }
                            
                            <br />
                            {
                                orderDetails !== null && orderDetails?.AdminUangJalanTambahanLain.length !== 0 ? (
                                    <React.Fragment>
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            Tambahan Rute Lain
                                        </Typography>
                                        <Grid item xs={12} md={12}>
                                            <table style={{ width: '100%' }}>
                                                {
                                                    orderDetails?.AdminUangJalanTambahanLain.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-title-table-invoice">
                                                                    <Typography
                                                                        className="text-labeled-invoice"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        {value.Keterangan}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        :
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        className="value-invoiced"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        Rp {currency(value.Values)},-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </table>


                                        </Grid>
                                        <Divider />
                                    </React.Fragment>
                                ) : null
                            }
                            
                            <br />
                            <Grid item xs={12} md={12}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className="text-title-table-invoice">
                                            <Typography
                                                className="text-labeled-invoice"
                                                variant="p"
                                                component="div"
                                            >
                                                Total borongan
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                variant="p"
                                                component="div"
                                            >
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                className="value-invoiced"
                                                variant="p"
                                                component="div"
                                            >
                                                Rp {currency(orderDetails?.vw_RincianUJ?.TotalBorongan)},-
                                            </Typography>
                                        </td>
                                    </tr>

                                </table>


                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <Grid item xs={12} md={12}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className="text-title-table-invoice">
                                            <Typography
                                                className="text-labeled-invoice"
                                                variant="p"
                                                component="div"
                                            >
                                                potongan driver
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                variant="p"
                                                component="div"
                                            >
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                className="value-invoiced"
                                                variant="p"
                                                component="div"
                                            >
                                                Rp {currency(orderDetails?.vw_RincianUJ?.TotalPotonganDriver)},-
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <br />
                            <Divider />
                            {
                                orderDetails !== null && orderDetails?.AdminUangJalanVoucherSpbu.length !== 0 ? (
                                    <React.Fragment>
                                       
                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            Voucher Solar
                                        </Typography>
                                        <Grid item xs={12} md={12}>
                                            <table style={{ width: '100%' }}>
                                                {
                                                    orderDetails?.AdminUangJalanVoucherSpbu && orderDetails?.AdminUangJalanVoucherSpbu.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-title-table-invoice">
                                                                    <Typography
                                                                        className="text-labeled-invoice"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        {value.Keterangan}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        :
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        className="value-invoiced"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        Rp {currency(value.Value)},-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </table>


                                        </Grid>
                                        <br />
                                        <Divider />
                                    </React.Fragment>
                                ) : null
                            }
                            {
                                orderDetails !== null && orderDetails?.AdminUangJalanVoucherSpbu.length !== 0 ? (
                                    <React.Fragment>

                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            Voucher Kapal
                                        </Typography>
                                        <Grid item xs={12} md={12}>
                                            <table style={{ width: '100%' }}>
                                                {
                                                    orderDetails?.AdminUangJalanVoucherKapal && orderDetails?.AdminUangJalanVoucherKapal.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-title-table-invoice">
                                                                    <Typography
                                                                        className="text-labeled-invoice"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        {value.Keterangan}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        :
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        className="value-invoiced"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        Rp {currency(value.Value)},-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </table>


                                        </Grid>
                                        <br />
                                        <Divider />
                                    </React.Fragment>
                                ) : null
                            }
                            {
                                orderDetails !== null && orderDetails?.AdminUangJalanUangTf.length !== 0 ? (
                                    <React.Fragment>

                                        <Typography
                                            sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                            variant="p"
                                            component="div"
                                        >
                                            Uang
                                        </Typography>
                                        <Grid item xs={12} md={12}>
                                            <table style={{ width: '100%' }}>
                                                {
                                                    orderDetails?.AdminUangJalanUangTf && orderDetails?.AdminUangJalanUangTf.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-title-table-invoice">
                                                                    <Typography
                                                                        className="text-labeled-invoice"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        {value.Keterangan}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        :
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography
                                                                        className="value-invoiced"
                                                                        variant="p"
                                                                        component="div"
                                                                    >
                                                                        Rp {currency(value.Value)},-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </table>


                                        </Grid>
                                        <Divider />
                                    </React.Fragment>
                                ) : null
                            }
                            <br />
                            <Grid item xs={12} md={12}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className="text-title-table-invoice">
                                            <Typography
                                                className="text-labeled-invoice"
                                                variant="p"
                                                component="div"
                                            >
                                                Total alokasi
                                            </Typography>
                                        </td>
                                        <td className="text-title-table-invoice">
                                            <Typography
                                                sx={{ color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                                variant="p"
                                                component="div"
                                            >
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                className="value-invoiced"
                                                variant="p"
                                                component="div"
                                            >
                                                Rp {currency(orderDetails?.vw_RincianUJ?.TotalAlokasi)},-
                                            </Typography>
                                        </td>
                                    </tr>

                                </table>


                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

            </Dialog>
            <Box
                className="ag-theme-alpine"
                sx={{ height: 800, marginBottom: 20, width: "100%" }}
            >
                <Stack sx={{ margin: 2 }} spacing={2} direction="row">
                    <StyledButton variant="text" onClick={() => _refresh_cache()}>
                        <RefreshOutlined sx={{ mr: 1 }} />
                        Reload
                    </StyledButton>
                    <StyledButton variant="text" onClick={() => _exportExcel()}>
                        <Download sx={{ mr: 1 }} />
                        Export Excel
                    </StyledButton>
                </Stack>
                <AgGridReact
                    ref={gridREF}
                    excelStyles={excelStyles}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onGridReady={onGridReady}
                    frameworkComponents={frameworkComponents}
                    chartThemes={["ag-theme-alpine"]}
                    defaultColDef={defaultColDef}
                    rowGroupPanelShow={"always"}
                    rowModelType={"serverSide"}
                    serverSideStoreType={"partial"}
                    purgeClosedRowNodes={true}
                    cacheBlockSize={20}
                    animateRows={true}
                    pivotMode={false}
                    sideBar={sideBar}
                    columnDefs={columnDefs}
                />
            </Box>
        </Grid>
    );
}
