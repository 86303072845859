import storage from "helper/helper.storage";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
const _user_action = {
  login: (dispatch, http, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await http.post("web-app/login", payload);
        console.log(JSON.stringify(data.status));
        if (data.status) {
          await storage.set("user", data);
          const _navmenu = await new Promise((resolve, reject) =>
            http
              .get("web-app/context/permissions", {
                headers: {
                  Authorization: `Bearer ${data.token}`, //the token is a variable which holds the token
                },
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => {
                reject(error.response);
              })
          );
          await storage.set("nav_menu", _navmenu.data.data);
          await dispatch({ type: "SET_MENU", payload: _navmenu.data.data });
          await dispatch({ type: "USER_LOGIN", payload: data });
          resolve(data);
        } else {
          resolve(data);
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  logout: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const { data } = await http.post("web-app/login", payload);
        // console.log(JSON.stringify(data.status));
        // if (data.status) {
        //   await storage.set("user", data);
        //   dispatch({ type: "USER_LOGIN", payload: data });
        //   resolve(data);
        // } else {
        //   resolve(data);
        // }
        await storage.remove("user");
        await storage.remove("nav_menu");

        dispatch({
          type: "RESTORE:USER",
          payload: {
            profile: {},
            token: null,
          },
        });

        dispatch({
          type: "RESTORE:SETTING",
          payload: {
            theme: "light",
            nav_menu: [],
          },
        });
        window.location.href = "/login";
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  permission: (dispatch, http) => {
    return new Promise(async (resolve, reject) => {
      try {
        let auth = storage.get("user");
        const _navmenu = await new Promise((resolve, reject) =>
          http
            .get("web-app/context/permissions", {
              headers: {
                Authorization: `Bearer ${auth.token}`, //the token is a variable which holds the token
              },
            })
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error.response);
            })
        );
        await storage.remove("nav_menu");
        await storage.set("nav_menu", _navmenu.data.data);
        await dispatch({ type: "SET_MENU", payload: _navmenu.data.data });
        resolve(_navmenu.data.data)
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }
};

export default _user_action;
