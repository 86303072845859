import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Button,
  CssBaseline,
  Avatar,
  Grid,
  Checkbox,
  Box,
  Table,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, alpha } from "@mui/material/styles";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import BtnCellRenderer from "components/datatables/btn_cell_render";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SaveIcon from "@mui/icons-material/Save";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { InfoOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list_access: {
    fontSize: 12,
    fontWeight: "bold",
    letterSpacing: 1,
    textTransform: "uppercase",
    padding: 2,
  },
  list_access_menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  label_checkbox: {
    fontWeight: 700,
    marginLeft: 5,
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 1,
  },
  label_checkbox_child: {
    marginLeft: 20,
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 1,
    '&::before': {
      content: '',
    }
  },
  input_label: {
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 12,
      letterSpacing: 1,
    },
  },
  button: {
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: 13,
    letterSpacing: 1,
    "& .MuiButton-text": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 13,
      letterSpacing: 1,
    },
  },
  child_context_table: {
    paddingLeft: 10,
  },
  custom_table_row: {
    "& .MuiTableRow-root": {
      borderBottomWidth: 1,
      borderBottomStyle: "dashed",
      borderBottomColor: "#EFF2F5",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Roles() {
  const classes = useStyles();
  const Api = useAxios();
  const MySwal = withReactContent(Swal);
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const [overlay, setOverlay] = React.useState(false);
  const [complete_access, setCompleteAccess] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewPermissions, setPermissionDetails] = React.useState(false);
  const [read, setRead] = React.useState([]);
  const [write, setWrite] = React.useState([]);
  const [create, setCreate] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuPermission, setMenuPermission] = React.useState([]);
  const [rolesDetail, setRolesDetail] = React.useState({});
  const [rolesAssigned, setRolesUserAssigned] = React.useState([]);
  const [rolesAccess, setRolesAccess] = React.useState([]);
  const [gridApi, setGridApi] = useState(null);
  const gridREF = useRef();
  const perPage = 20;
  useEffect(() => {
    let child_menu = [];

    let menu_parent = menuList.filter(function (menu_list) {
      return menu_list.nav_parent_id === 0;
    });

    menu_parent.forEach((row) => {
      if (menu_parent.indexOf(row.nav_id) == -1) {
        child_menu.push({
          nav_id: row.nav_id,
          nav_name: row.nav_name,
          nav_parent_id: row.nav_parent_id,
          access: row.access,
          child_menu: menuList.filter((obj) => {
            return row.nav_id === obj.nav_parent_id;
          }),
        });
      }
    });

    console.log(child_menu);

    setMenuPermission(child_menu);
  }, [menuList]);

  useEffect(() => {
    console.log(menuPermission);
  }, [menuPermission]);

  const _menu_list = async (role_id) => {
    try {
      const { data } = await Api.get(
        `/web-app/context/list/menu?role_id=${role_id}`
      );
      setMenuList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );

  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 20;

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: BtnCellRenderer,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "usertype_id",
      minWidth: 20,
      enableRowGroup: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Role",
      field: "usertype_name",
      minWidth: 220,
      enableRowGroup: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Tanggal buat",
      field: "usertype_created_on",
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Dibuat oleh",
      field: "usertype_created_by",
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tanggal pembaruan",
      field: "usertype_updated_on",
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Diperbarui oleh",
      field: "usertype_updated_by",
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Aksi",
      field: "action",
      enableRowGroup: false,
      filter: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_view_detail(data);
        },
      },
    },
  ]);

  const _user_assigned_column = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "username",
      headerName: "pengguna",
      width: 150,
      editable: true,
    },
    {
      field: "join_date",
      headerName: "tanggal buat",
      width: 150,
      editable: true,
    },
  ];

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          const page = params.request.endRow / perPage;
          Api.post(
            `/web-app/context/data/roles?per_page=${perPage}&page=${page}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.success({
                rowData: data.data.data,
                rowCount: data.data.lastData,
              });
              // params.successCallback(data.data.data, data.data.total);
            })
            .catch((error) => {
              console.error(error);
              params.fail();
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);

  const _handle_close_detail = () => {
    setViewDetail(false);
  };
  const _handle_close_permissions = () => {
    setPermissionDetails(false);
  };

  const _handle_submit_users = async () => { 
    
  };


  const _handle_submit_permission = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    try {
      const { data } = await Api.post("/web-app/context/submit/roles/edit", {
        roles_id: rolesDetail.usertype_id,
        permission: menuPermission
      });
      if (data.status) {
        MySwal.fire({
          title: "Berhasil!",
          text: "Update data akses baru.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false);
        setPermissionDetails(false);
      } else { 
        MySwal.fire({
          title: "Sistem gagal!",
          text: "Gagal update data akses mohon ulangi lagi nanti.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
        setPermissionDetails(false);
      }
    } catch (error) {
      setpopup({
        ...alertpoup,
        status: false,
        error: true,
        message: error.message,
      });
      MySwal.fire({
        title: "Sistem gagal!",
        text: "Gagal update data akses mohon ulangi lagi nanti.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setPermissionDetails(false);
      setLoading(false);
      console.log(error);
    }
  };

  const _handle_permission = (event, key, property) => {
    // console.log(event.target.value);

    const menu_copy = [...menuPermission];
    menu_copy[key]["access"][property] =
      menu_copy[key]["access"][property] === 1 ? 0 : 1;

    setMenuPermission(menu_copy);
  };

  const _handle_complete_access = (event, value) => {
    let compile_menu = [];

    menuList.forEach((row) => {
      compile_menu.push({
        nav_id: row.nav_id,
        nav_name: row.nav_name,
        nav_parent_id: row.nav_parent_id,
        access: {
          create: value ? 0 : 1,
          read: value ? 0 : 1,
          write: value ? 0 : 1,
        },
      });
    });
    setMenuList(compile_menu);
    setCompleteAccess(value ? false : true);
  }

  const _handle_child_permission = (event, key, master_key, property) => {
    // console.log(event.target.value);

    const menu_copy = [...menuPermission];
    menu_copy[master_key]["child_menu"][key]["access"][property] =
      menu_copy[master_key]["child_menu"][key]["access"][property] === 1
        ? 0
        : 1;

    setMenuPermission(menu_copy);
  };

  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const _generate_access = async () => {
    setLoading(true);
    try {
      // let params = new FormData();
      // params.append("id", data);

      const { data } = await Api.get("/web-app/context/generate-permissions", {
        params: {
          roles: rolesDetail.usertype_id,
        },
      });

      if (data.status) {
        _init_view_detail(rolesDetail.usertype_id);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const _init_view_detail = async (id) => {
    setOverlay(true);

    try {
      // let params = new FormData();
      // params.append("id", data);

      const { data } = await Api.get("/web-app/context/detail/roles", {
        params: {
          id: id,
        },
      });

      if (data.status) {
        _menu_list(id);
        setOverlay(false);
        setViewDetail(true);
        setRolesDetail(data.data.detail);
        setRolesUserAssigned(data.data.users_assigned);
        setRolesAccess(data.data.access);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MKA - akses pengguna</title>
        <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={overlay}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          fullScreen
          open={viewDetail}
          onClose={_handle_close_detail}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ bgcolor: "white", position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={_handle_close_detail}
                aria-label="close"
              >
                <CloseIcon sx={{ color: "#000000" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                Role detail
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, padding: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold", mb: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Role
                </Typography>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {rolesDetail.usertype_name}
                    </Typography>
                    <Typography variant="h6" component="div">
                      Tanggal buat: {rolesDetail.usertype_created_on}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Dibuat oleh: {rolesDetail.created_by}
                    </Typography>
                    <Typography variant="body2">
                      Tanggal pembaruan: {rolesDetail.usertype_updated_on}
                    </Typography>
                    <Typography variant="body2">
                      Dibarui oleh: {rolesDetail.updated_by}
                    </Typography>
                    <Divider sx={{ padding: 1 }} />
                    <Box
                      sx={{ display: "flex", justifyContent: "end", margin: 2 }}
                    >
                      <StyledButton
                        edge="start"
                        color="inherit"
                        onClick={() => setPermissionDetails(true)}
                        aria-label="close"
                      >
                        <EditIcon sx={{ color: "#000000" }} /> &nbsp; edit role
                      </StyledButton>
                    </Box>

                    <ul>
                      {rolesAccess.length !== 0 ? (
                        <React.Fragment>
                          {rolesAccess.map((item, index) => {
                            return (
                              <li className={classes.list_access} key={index}>
                                <p className={classes.list_access}>
                                  {item.permissions_name}
                                </p>
                                <div className={classes.list_access_menu}>
                                  - write:{" "}
                                  {item.permission.write === 1 ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                  read:{" "}
                                  {item.permission.read === 1 ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                  create:{" "}
                                  {item.permission.create === 1 ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <li>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                letterSpacing: 1,
                                textTransform: "uppercase",
                                padding: 2,
                              }}
                            >
                              Tidak ada akses
                            </Typography>
                            <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                              <StyledLoadingButton
                                loading={loading}
                                loadingPosition="start"
                                disabled={loading}
                                onClick={_generate_access}
                                type="button"
                                startIcon={<EditIcon />}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                              >
                                Buat akses
                              </StyledLoadingButton>
                            </Grid>
                          </li>
                        </React.Fragment>
                      )}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <div style={{ height: 400, width: "100%" }}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "bold", mb: 1 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Pengguna yang ditugaskan
                  </Typography>
                  <DataGrid
                    rows={rolesAssigned}
                    columns={_user_assigned_column}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={viewPermissions}
          onClose={_handle_close_permissions}
          TransitionComponent={Transition}
        >
          <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={_handle_close_permissions}
                aria-label="close"
              >
                <CloseIcon sx={{ color: "#000000" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                Pengaturan hak akses
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, padding: 5 }}>
            <form>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography>
                    <b>Role : </b>
                  </Typography>
                  <TextField
                    disabled
                    variant="standard"
                    defaultValue={rolesDetail.usertype_name}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <table className="table table-row-dashed">
                    <tbody>
                      <tr>
                        <td>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <span className={classes.label_checkbox}>
                              Akses administrator
                            </span>
                            <IconButton sx={{ ml: 1 }}>
                              <InfoOutlined sx={{ color: "#000000" }} />
                            </IconButton>
                          </Box>
                        </td>
                        <td>
                          <Checkbox
                            checked={complete_access ? true : false}
                            onChange={(event) =>
                              _handle_complete_access(event, complete_access)
                            }
                            inputProps={{
                              "aria-label": "akses administrator semua",
                            }}
                          />
                        </td>
                      </tr>
                      {menuPermission.map((item, index) => {
                        return (
                          <React.Fragment>
                            <tr key={index}>
                              <td>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <span className={classes.label_checkbox}>
                                    {item.nav_name}
                                  </span>
                                </Box>
                              </td>
                              <td>
                                <Box
                                  sx={{
                                    display: "flex",

                                    flexDirection: "row",
                                    ml: 3,
                                  }}
                                >
                                  {menuPermission[index].nav_parent_id !== 0 ? (
                                    <React.Fragment>
                                      <FormControlLabel
                                        className={classes.input_label}
                                        label="Read"
                                        control={
                                          <Checkbox
                                            value={
                                              menuPermission[index]?.access
                                                .read === 1
                                                ? 1
                                                : 0
                                            }
                                            checked={
                                              menuPermission[index]?.access
                                                .read === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              _handle_permission(
                                                event,
                                                index,
                                                "read"
                                              )
                                            }
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        className={classes.input_label}
                                        label="Write"
                                        control={
                                          <Checkbox
                                            value={
                                              menuPermission[index]?.access
                                                .write === 1
                                                ? 1
                                                : 0
                                            }
                                            checked={
                                              menuPermission[index]?.access
                                                .write === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              _handle_permission(
                                                event,
                                                index,
                                                "write"
                                              )
                                            }
                                          />
                                        }
                                      />
                                      <FormControlLabel
                                        className={classes.input_label}
                                        label="Create"
                                        control={
                                          <Checkbox
                                            value={
                                              menuPermission[index]?.access
                                                .create === 1
                                                ? 1
                                                : 0
                                            }
                                            checked={
                                              menuPermission[index]?.access
                                                .create === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              _handle_permission(
                                                event,
                                                index,
                                                "create"
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <FormControlLabel
                                        className={classes.input_label}
                                        label="Read"
                                        control={
                                          <Checkbox
                                            value={
                                              menuPermission[index]?.access
                                                .read === 1
                                                ? 1
                                                : 0
                                            }
                                            checked={
                                              menuPermission[index]?.access
                                                .read === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              _handle_permission(
                                                event,
                                                index,
                                                "read"
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </React.Fragment>
                                  )}
                                </Box>
                              </td>
                            </tr>
                            {item.child_menu.length > 0 ? (
                              <React.Fragment>
                                {item.child_menu.map((child, child_index) => {
                                  return (
                                    <React.Fragment>
                                      <tr
                                        className={classes.child_context_table}
                                        key={child_index}
                                      >
                                        <td>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span className="label-checbox-child">
                                              {child.nav_name}
                                            </span>
                                          </Box>
                                        </td>
                                        <td>
                                          <Box
                                            sx={{
                                              display: "flex",

                                              flexDirection: "row",
                                              ml: 3,
                                            }}
                                          >
                                            <React.Fragment>
                                              {console.log(child?.access)}
                                              <FormControlLabel
                                                className={classes.input_label}
                                                label="Read"
                                                control={
                                                  <Checkbox
                                                    value={
                                                      child?.access.read === 1
                                                        ? 1
                                                        : 0
                                                    }
                                                    checked={
                                                      child?.access.read === 1
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) =>
                                                      _handle_child_permission(
                                                        event,
                                                        child_index,
                                                        index,
                                                        "read"
                                                      )
                                                    }
                                                  />
                                                }
                                              />
                                              <FormControlLabel
                                                className={classes.input_label}
                                                label="Write"
                                                control={
                                                  <Checkbox
                                                    value={
                                                      child?.access.write === 1
                                                        ? 1
                                                        : 0
                                                    }
                                                    checked={
                                                      child?.access.write === 1
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) =>
                                                      _handle_child_permission(
                                                        event,
                                                        child_index,
                                                        index,
                                                        "write"
                                                      )
                                                    }
                                                  />
                                                }
                                              />
                                              <FormControlLabel
                                                className={classes.input_label}
                                                label="Create"
                                                control={
                                                  <Checkbox
                                                    value={
                                                      child?.access.create === 1
                                                        ? 1
                                                        : 0
                                                    }
                                                    checked={
                                                      child?.access.create === 1
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) =>
                                                      _handle_child_permission(
                                                        event,
                                                        child_index,
                                                        index,
                                                        "create"
                                                      )
                                                    }
                                                  />
                                                }
                                              />
                                            </React.Fragment>
                                          </Box>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Grid>
                <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                  <StyledLoadingButton
                    loading={loading}
                    loadingPosition="start"
                    disabled={loading}
                    onClick={_handle_submit_permission}
                    type="button"
                    startIcon={<SaveIcon />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Simpan
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        <Box
          sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
        >
          <Breadcrumbs
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Settings
            </Link>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "bold",
                letterSpacing: 1,
                textTransform: "uppercase",
                padding: 2,
              }}
              color="text.primary"
            >
              roles
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          className="ag-theme-alpine"
          style={{ height: 600, marginBottom: 20, width: "100%" }}
        >
          <AgGridReact
            ref={gridREF}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            onGridReady={onGridReady}
            frameworkComponents={frameworkComponents}
            chartThemes={["ag-theme-alpine"]}
            defaultColDef={defaultColDef}
            // rowGroupPanelShow={"always"}
            pivotMode={false}
            rowModelType={"serverSide"}
            serverSideStoreType={"partial"}
            purgeClosedRowNodes={true}
            cacheBlockSize={20}
            animateRows={true}
            sideBar={sideBar}
            columnDefs={columnDefs}
          ></AgGridReact>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
