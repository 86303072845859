import { useContext } from "react";
import { def } from "./helper.const";
import { Context } from "../state/store";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import storage from "helper/helper.storage";
import _user_action from "state/actions/user.actions";

const useAxios = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [state, dispatch] = useContext(Context);
  let user = storage.get("user");

  const instance = Axios.create({
    baseURL: def.API_URL,
    responseType: "json",
    // timeout: 10000,
    transformResponse: [
      function (data) {
        const response = JSON.parse(data);
        console.log('XHR response :' + JSON.stringify(response));
        if (response.status === "unauthorized") {
          _user_action.logout(dispatch);
        } else {
          return response;
        }
        return response;
      },
    ],
  });
  instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  if (user) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  return instance;
};

export default useAxios;
