import React, { Component } from "react";
import ImageList from "@mui/material/ImageList";
import Lightbox from 'react-image-lightbox';
import ImageListItem from "@mui/material/ImageListItem";
import { def } from "helper/helper.const";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
class SelfiePhotoCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            photoURL: "",
            isOpen: false,
        };
    }

    render() {
        const { photoIndex, isOpen, photoURL } = this.state;

        console.log(this.props.data.attendance_selfiePhoto);

        return (
            <React.Fragment>
                {this.props.data.attendance_selfiePhoto != undefined && this.props.data.attendance_selfiePhoto.length != 0 ? (
                    <img
                        style={{
                            width: 30,
                            height: 30,
                            objectFit: "cover",
                        }}
                        onClick={() => this.setState({ isOpen: true, photoURL: def.API_URL + this.props.data.attendance_selfiePhoto })}
                        src={`${def.API_URL}${this.props.data.attendance_selfiePhoto}?w=60&h=60&fit=crop&auto=format`}
                        srcSet={`${def.API_URL}${this.props.data.attendance_selfiePhoto}?w=60&h=60&fit=crop&auto=format&dpr=2 2x`}
                        alt={this.props.data.attendance_selfiePhoto}
                        loading="lazy"
                    />
                ) : (
                    <React.Fragment>-</React.Fragment>
                )}
                {isOpen && (
                    <Lightbox
                        mainSrc={photoURL}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default SelfiePhotoCellRenderer;
