import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Input,
  InputLabel,
  Dialog,
  Alert,
  AlertTitle,
  MenuItem,
  InputAdornment,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Button,
  Avatar,
  DialogTitle,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
  Link,
  Box,
  Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
  RefreshOutlined,
  AccountCircle,
  Save,
  Close,
  AssignmentInd,
  Lock,
  AccountTree,
  VisibilityOff,
  Download,
  Visibility,
} from "@mui/icons-material";
import StatusFilter from "components/datatables/status_filter";
import BtnAnnouncementRender from "components/datatables/btn_announcement_render";
const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  input_label: {
    color: "#3c4043",
    fontSize: 11,
    textTransform: "uppercase",
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Announcement() {
  const classes = useStyles();
  const Api = useAxios();
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const [announcementDetails, setAnnouncementDetails] = React.useState({});
  const [overlay, setOverlay] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewEdit, setEditView] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const perPage = 20;
  const gridREF = useRef();

  const excelStyles = [
    {
      id: "header",
      alignment: {
        vertical: "Center",
        horizontal: 'Center'
      },
      font: {
        fontName: 'Arial',
        bold: true,
      },
    },
    {
      id: 'cell',
      alignment: {
        vertical: 'Center',
        horizontal: 'Center'
      },
      font: {
        fontName: 'Arial',
      },
    },
  ];

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          const page = params.request.endRow / perPage;
          Api.post(
            `/web-app/context/data/announcement?per_page=${perPage}&page=${page}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.success({
                rowData: data.data.data,
                rowCount: data.data.lastRow,
              });
              // params.successCallback(data.data.data, data.data.total);
            })
            .catch((error) => {
              console.error(error);
              params.fail();
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);
  
  useEffect(() => {
    setForm({
      id: announcementDetails.announcement_id,
      title: announcementDetails.announcement_title,
      detail: announcementDetails.announcement_content,
      description: announcementDetails.announcement_description,
      rotation: announcementDetails.announcement_rotation,
      status: announcementDetails.announcement_status,
    });
  }, [announcementDetails]);

  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const [form, setForm] = React.useState({
    id: "",
    title: "",
    detail: "",
    description: "",
    rotation: 0,
    status: 1,
  });
  const _handle_form = (property, event) => {
    const collection = { ...form };
    collection[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setForm(collection);
  };
  const validate = () => {
    const schema = {
      title: {
        require: () =>
          !!form?.title ? "" : `Judul pengumuman tidak boleh kosong`,
      },
      description: {
        require: () =>
          !!form?.description ? "" : `Keterangan tidak boleh kosong`,
      },
      detail: {
        require: () =>
          !!form?.detail ? "" : `Isi pengumuman tidak boleh kosong`,
      },
      rotation: {
        require: () => (form?.rotation !== "" ? "" : `Rotasi tidak boleh kosong`),
      },
      status: {
        require: () => (!!form?.status ? "" : `Status harus dipilih`),
      },
    };
    let error = validate_input(schema);
    setError(error);
    return error;
  };
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "#",
      minWidth: 40,
      editable: false,
      enableRowGroup: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Aksi",
      field: "action",
      minWidth: 240,
      enableRowGroup: false,
      editable: false,
      filter: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        _view_detail: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_view_detail(data);
        },
        _view_edit: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_edit(data);
        },
      },
    },
    {
      headerName: "Status",
      sort: "asc",
      field: "announcement_status",
      minWidth: 120,
      editable: false,
      enableRowGroup: true,
      filter: StatusFilter,
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Judul pengumuman",
      field: "announcement_title",
      minWidth: 220,
      enableRowGroup: false,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Isi",
      field: "announcement_content",
      minWidth: 220,
      enableRowGroup: false,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Keterangan",
      field: "announcement_description",
      minWidth: 220,
      editable: false,
      enableRowGroup: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Rotasi",
      field: "announcement_rotation",
      minWidth: 140,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Tanggal dibuat",
      field: "announcement_created_on",
      enableRowGroup: false,
      editable: false,
      sort: "desc",
      minWidth: 240,
      filter: "agNumberColumnFilter",
    },
  ]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const _init_view_detail = async (id) => {
    setOverlay(true);
    try {
      const { data } = await Api.get("/web-app/context/detail/announcement", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setViewDetail(true);
        setAnnouncementDetails(data.data)
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _init_edit = async (id) => {
    setOverlay(true);
    setForm({
      id: "",
      title: "",
      detail: "",
      description: "",
      rotation: 0,
      status: 1,
    });
    setError({});
    try {
      const { data } = await Api.get("/web-app/context/edit/announcement", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setEditView(true);
        setAnnouncementDetails(data.data);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _handle_close_edit = () => {
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    setEditView(false);
  };
  const _handle_submit = async (e) => {
    if (!!validate()) return;
    setLoading(true);
    let params = new FormData();
    params.append("id", form?.id);
    params.append("title", form?.title);
    params.append("detail", form?.detail);
    params.append("description", form?.description);
    params.append("rotation", form?.rotation);
    params.append("status", form?.status);
    
    try {
      const { data } = await Api.post("/web-app/context/save/announcement", params);
      if (data.status) {
        setLoading(false);
        setEditView(false);
        _refresh_cache();
        setpopup({
          ...alertpoup,
          status: true,
          error: false,
          message: "Pengumuman berhasil disimpan.",
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const _handle_close_detail = () => {
    setViewDetail(false);
  };

  const _handle_create_announcement = () => {
    setEditView(true);
    setForm({
      id: "",
      title: "",
      detail: "",
      description: "",
      rotation: 0,
      status: 1,
    });
    setError({});
  };

  const _exportExcel = useCallback(() => {
    gridREF.current.api.exportDataAsExcel();
  }, []);

  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: BtnAnnouncementRender,
  });

  return (
    <Grid container component="main" className={classes.root}>
       <Helmet>
          <meta charSet="utf-8" />
          <title>MKA - pengumuman</title>
          <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={overlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewDetail}
        onClose={_handle_close_detail}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_detail}
              aria-label="close"
            >
              <Close sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Detail pengumuman
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Grid container spacing={2}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <FormLabel
                    className={classes.input_label}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Status
                  </FormLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      isReadonly
                      value={form?.status}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={1}
                        className={classes.input_label}
                        control={<Radio />}
                        label="Aktif"
                      />
                      <FormControlLabel
                        value={0}
                        className={classes.input_label}
                        control={<Radio />}
                        label="Tidak aktif"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      id="input-title"
                      isReadonly
                      value={form?.title}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="judul pengumuman"
                      variant="standard"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      id="input-isi"
                      isReadonly
                      value={form?.detail}
                      multiline
                      maxRows={4}
                      rows={4}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Isi pengumuman"
                      variant="standard"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      id="input-description"
                      isReadonly
                      value={form?.description}
                      className={classes.input_label}
                      margin="normal"
                      fullWidth
                      label="Keterangan"
                      variant="standard"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TextField
                      id="input-rotasi"
                      isReadonly
                      value={form?.rotation}
                      className={classes.input_label}
                      margin="normal"
                      type="number"
                      fullWidth
                      label="Rotasi"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewEdit}
        onClose={_handle_close_edit}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_edit}
              aria-label="close"
            >
              <Close sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Pengumuman
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertpoup.status ? (
              <React.Fragment>
                {alertpoup.error === false ? (
                  <Alert severity="success">{alertpoup.message}</Alert>
                ) : (
                  <Alert
                    onClose={() => {
                      setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                      });
                    }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {alertpoup.message} — <strong>sistem error</strong>
                  </Alert>
                )}
              </React.Fragment>
            ) : null}
          </Stack>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormLabel
                  className={classes.input_label}
                  id="demo-row-radio-buttons-group-label"
                >
                  Status
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={form?.status}
                    onChange={(event) => _handle_form("status", event)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      className={classes.input_label}
                      control={<Radio />}
                      label="Aktif"
                    />
                    <FormControlLabel
                      value={0}
                      className={classes.input_label}
                      control={<Radio />}
                      label="Tidak aktif"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-title"
                    onChange={(event) => _handle_form("title", event)}
                    value={form?.title}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="judul pengumuman"
                    variant="standard"
                    error={error && form.title === "" ? true : false}
                    helperText={error && form.title === "" ? error.title : ""}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-isi"
                    onChange={(event) => _handle_form("detail", event)}
                    value={form?.detail}
                    multiline
                    maxRows={4}
                    rows={4}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Isi pengumuman"
                    variant="standard"
                    error={error && form.detail === "" ? true : false}
                    helperText={error && form.detail === "" ? error.detail : ""}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-description"
                    onChange={(event) => _handle_form("description", event)}
                    value={form?.description}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Keterangan"
                    variant="standard"
                    error={error && form.description === "" ? true : false}
                    helperText={
                      error && form.description === "" ? error.description : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-rotasi"
                    onChange={(event) => _handle_form("rotation", event)}
                    value={form?.rotation}
                    className={classes.input_label}
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Rotasi"
                    variant="standard"
                    error={error && form.rotation === "" ? true : false}
                    helperText={
                      error && form.rotation === "" ? error.rotation : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                <StyledLoadingButton
                  loading={loading}
                  loadingPosition="start"
                  disabled={loading}
                  onClick={_handle_submit}
                  type="button"
                  startIcon={<Save />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Simpan
                </StyledLoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Box
        sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
      >
        <Breadcrumbs
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 1,
            textTransform: "uppercase",
            padding: 2,
          }}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/dashboard">
            Settings
          </Link>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            color="text.primary"
          >
            announcement
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        className="ag-theme-alpine"
        sx={{ height: 600, marginBottom: 20, width: "100%" }}
      >
        <Stack sx={{ margin: 2 }} spacing={2} direction="row">
          <StyledButton
            variant="text"
            onClick={() => _handle_create_announcement()}
          >
            <PersonAddIcon sx={{ mr: 1 }} />
            Tambah data
          </StyledButton>
          <StyledButton variant="text" onClick={() => _refresh_cache()}>
            <RefreshOutlined sx={{ mr: 1 }} />
            Reload
          </StyledButton>
          <StyledButton variant="text" onClick={() => _exportExcel()}>
            <Download sx={{ mr: 1 }} />
            Export Excel
          </StyledButton>
        </Stack>
        <AgGridReact
          ref={gridREF}
          excelStyles={excelStyles}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          chartThemes={["ag-theme-alpine"]}
          defaultColDef={defaultColDef}
          rowGroupPanelShow={"always"}
          rowModelType={"serverSide"}
          pivotMode={false}
          serverSideStoreType={"partial"}
          purgeClosedRowNodes={true}
          cacheBlockSize={20}
          animateRows={true}
          sideBar={sideBar}
          columnDefs={columnDefs}
        />
      </Box>
    </Grid>
  );
}
