import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { Outlet, Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import useAxios from "../helper/helper.http";
import { Context } from "../state/store";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Settings from "@mui/icons-material/Settings";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { white } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import logo_path from "../assets/logo.png";
import _user_action from "../state/actions/user.actions";
import _setting_action from "../state/actions/setting.actions";
import Drawer from '@mui/material/Drawer';
const useStyles = makeStyles((theme) => ({
  avatar_photo: {
    width: 40,
    height: "auto",
    objectFit: "cover",
  },
  avatar: {
    width: 120,
    height: 120,
    margin: theme.spacing(0),
    backgroundColor: "#ffffff",
  },
}));

const Layout = () => {
  const Api = useAxios();
  let navigate = useNavigate();
  const classes = useStyles();
  const [state, dispatch] = React.useContext(Context);
  const [nav_open, _set_open_menu] = React.useState(false);
  const [anchor_elnav, _set_anchor_nav] = React.useState(null);
  const [anchor_eluser, _set_anchor_user] = React.useState(null);
  const [menu_nav, _set_navmenu] = React.useState([]);
  const [drawerState, setStateDrawer] = React.useState({
    left: false,
  });

  const _handle_drawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    console.log(anchor);
    console.log(open);


    setStateDrawer({ ...drawerState, [anchor]: open });
  };

  const _handle_user_menu = (event) => {
    _set_anchor_user(event.currentTarget);
  };
  const _handle_navmenu = (event, index) => {
    console.log(event.currentTarget);
    const menu_copy = [...menu_nav];
    menu_copy[index]["parent_dropdown"] = menu_copy[index]["parent_dropdown"]
      ? false
      : true;
    _set_navmenu(menu_copy);
    _set_anchor_nav(event.currentTarget);
  };

  const _handle_drawer_nav = (event, index) => {
    console.log(event.currentTarget);
    const menu_copy = [...menu_nav];
    menu_copy[index]["open_collapse"] = menu_copy[index]["open_collapse"]
      ? false
      : true;
    _set_navmenu(menu_copy);
    // _set_anchor_nav(event.currentTarget);
  };

  const _handle_close_usermenu = () => {
    _set_anchor_user(null);
  };
  const _handle_close_navmenu = (event, index) => {
    _set_anchor_nav(null);
    const menu_copy = [...menu_nav];
    menu_copy[index]["parent_dropdown"] = false;
    _set_navmenu(menu_copy);
  };

  const _handle_clear_session = () => {
    _set_anchor_user(null);
    _user_action
      .logout(dispatch, Api)
      .then(() => {
        navigate("login");
      })
      .catch((error) => { });
  };

  const _navigate_url = (uri, index) => {
    const menu_copy = [...menu_nav];
    menu_copy[index]["parent_dropdown"] = false;
    _set_navmenu(menu_copy);
    navigate(uri);
  };

  const _navigate_url_collapse = (uri, index, anchor) => {
    setStateDrawer({ ...drawerState, [anchor]: false });
    const menu_copy = [...menu_nav];
    menu_copy[index]["open_collapse"] = false;
    _set_navmenu(menu_copy);
    navigate(uri);
  }

  const { token, profile } = state.userReducer;
  const { nav_menu } = state.settingReducer;

  React.useEffect(() => {
    let child_menu = [];

    let menu_parent = nav_menu.filter(function (menu_list) {
      return menu_list.permissions_nav_parent === 0;
    });

    menu_parent.forEach((row) => {
      if (menu_parent.indexOf(row.permission_id) == -1) {
        if (row.permission.read === 1) {
          child_menu.push({
            permission_id: row.permission_id,
            permissions_name: row.permissions_name,
            permissions_nav_parent: row.permissions_nav_parent,
            permissions_nav_url: row.permissions_nav_url,
            parent_dropdown: false,
            open_collapse: false,
            child_menu: nav_menu.filter((obj) => {
              return row.permission_id === obj.permissions_nav_parent;
            }),
          });
        }
      }
    });

    console.log(child_menu);

    _set_navmenu(child_menu);
  }, [nav_menu]);

  React.useEffect(() => {

  }, [anchor_elnav]);
  
  React.useEffect(() => {
    _user_action
      .permission(dispatch, Api)
      .then(() => {
        
      })
      .catch((error) => { 
        console.log('nav menu error: ' + JSON.stringify(error));
      });
  }, []);

  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1),
      minWidth: 220,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const open_context = Boolean(anchor_elnav);
  const open_drawer_context = Boolean(anchor_elnav);

  const ProfileMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1),
      minWidth: 280,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={_handle_drawer(anchor, false)}
      // onKeyDown={_handle_drawer(anchor, false)}
    >
      {menu_nav &&
        menu_nav.map((page, index) => {
          if (page.child_menu.length !== 0) {
            return (
              <React.Fragment key={index}>
                <ListItem  dense id={`menu-drawerbar-${index}`} button onClick={(event) => _handle_drawer_nav(event, index)}>
                  <ListItemText dense sx={{
                    color: "grey",
                    display: "block",
                    padding: 1,
                    fontSize: 12,
                    textTransform: "uppercase",
                    letterSpacing: 1,
                    fontWeight: "bold",
                    color: "black",
                  }} primary={page.permissions_name} />
                  {menu_nav.find(item => item.permission_id === page.permission_id).open_collapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse id={`menu-drawerappbar-${index}`} in={menu_nav.find(item => item.permission_id === page.permission_id).open_collapse} timeout="auto" unmountOnExit>
                  {page.child_menu &&
                    page.child_menu.map(
                      (child_menu_ex, child_menu_index) => (
                        <List key={child_menu_index} component="div" disablePadding>
                          <ListItem
                            dense
                            onClick={() =>
                              _navigate_url_collapse(
                                child_menu_ex.permissions_nav_url, index, anchor
                              )
                            } button className={classes.nested}>
                            <ListItemText sx={{
                              color: "grey",
                              display: "block",
                              padding: 1,
                              fontSize: 12,
                              textTransform: "uppercase",
                              letterSpacing: 1,
                              fontWeight: "bold",
                              color: "black",
                            }} inset secondary={child_menu_ex.permissions_name} />
                          </ListItem>
                        </List>

                      )
                    )}
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <ListItem id={`menu-drawerbar-${index}`} button onClick={() =>
                  _navigate_url_collapse(page.permissions_nav_url, index, anchor)
                }>
                  <ListItemText sx={{
                    color: "grey",
                    display: "block",
                    padding: 1,
                    fontSize: 12,
                    textTransform: "uppercase",
                    letterSpacing: 1,
                    fontWeight: "bold",
                    color: "black",
                  }} primary={page.permissions_name} />
                </ListItem>

              </React.Fragment>
            );
          }
        })}
    </Box>
  );


  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        {token ? (
          <AppBar sx={{ bgcolor: "white" }} position="static" elevation={0}>
            <Container maxWidth={false}>
              <Toolbar disableGutters>
                <Avatar
                  component="div"
                  sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: "#ffffff",
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <img className={classes.avatar_photo} src={logo_path}></img>
                </Avatar>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={_handle_drawer('left', true)}
                    color="inherit"
                  >
                    <MenuIcon
                      sx={{
                        fontSize: "2rem",
                        color: "#31A3DB",
                      }}
                    />
                  </IconButton>
                </Box>
                <Avatar
                  component="div"
                  sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: "#ffffff",
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <img className={classes.avatar_photo} src={logo_path}></img>
                </Avatar>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  {menu_nav &&
                    menu_nav.map((page, index) => {
                      if (page.child_menu.length !== 0) {
                        return (
                          <React.Fragment key={index}>
                            <Button
                              id={`menu-btnbar-${index}`}
                              aria-owns={
                                open_context ? `menu-appbar-${index}` : null
                              }
                              aria-haspopup="true"
                              onClick={(event) => _handle_navmenu(event, index)}
                              sx={{
                                my: 2,
                                color: "grey",
                                display: "block",
                                padding: 1,
                                fontSize: 12,
                                textTransform: "uppercase",
                                letterSpacing: 1,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {page.permissions_name}
                            </Button>
                            <StyledMenu
                              id={`menu-appbar-${index}`}
                              getcontentanchorel={null}
                              MenuListProps={{
                                "aria-labelledby": `menu-appbar-${index}`,
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              sx={{ mt: "10px" }}
                              anchorEl={anchor_elnav}
                              open={page.parent_dropdown}
                              onClick={(event) =>
                                _handle_close_navmenu(event, index)
                              }
                            >
                              {page.child_menu &&
                                page.child_menu.map(
                                  (child_menu_ex, child_menu_index) => (
                                    <MenuItem
                                      key={child_menu_index}
                                      onClick={() =>
                                        _navigate_url(
                                          child_menu_ex.permissions_nav_url, index
                                        )
                                      }
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          textTransform: "uppercase",
                                          letterSpacing: 1,
                                          fontWeight: "bold",
                                          color: "grey",
                                        }}
                                        textAlign="center"
                                      >
                                        {child_menu_ex.permissions_name}
                                      </Typography>
                                    </MenuItem>
                                  )
                                )}
                            </StyledMenu>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment key={index}>
                            <Button
                              onClick={() =>
                                _navigate_url(page.permissions_nav_url, index)
                              }
                              sx={{
                                my: 2,
                                color: "grey",
                                display: "block",
                                padding: 1,
                                fontSize: 12,
                                textTransform: "uppercase",
                                letterSpacing: 1,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {page.permissions_name}
                            </Button>
                          </React.Fragment>
                        );
                      }
                    })}
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={_handle_user_menu} sx={{ p: 0 }}>
                      <Avatar
                        alt={profile.user_nickname}
                        src="/static/images/avatar/2.jpg"
                      />
                    </IconButton>
                  </Tooltip>
                  <ProfileMenu
                    id="menu-appbar"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    sx={{ mt: "10px" }}
                    anchorEl={anchor_eluser}
                    open={Boolean(anchor_eluser)}
                    onClose={_handle_close_usermenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                  >
                    <MenuItem sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Avatar
                          alt={profile.user_nickname}
                          src="/static/images/avatar/2.jpg"
                        />
                        <Typography
                          sx={{
                            padding: 1,
                            fontSize: 12,
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            fontWeight: "bold",
                            color: "black",
                          }}
                          textAlign="center"
                        >
                          {profile.user_nickname}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <MenuIcon
                          sx={{
                            fontSize: "2rem",
                            color: "#31A3DB",
                          }}
                        />
                        <Typography
                          sx={{
                            padding: 1,
                            fontSize: 12,
                            textTransform: "uppercase",
                            letterSpacing: 1,
                            fontWeight: "bold",
                            color: "black",
                          }}
                          textAlign="center"
                        >
                          pengaturan
                        </Typography>
                      </Box>
                    </MenuItem>
                    <Box
                      sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Chip
                        onClick={_handle_close_usermenu}
                        avatar={
                          <Avatar
                            alt={profile.user_name}
                            src="/static/images/avatar/1.jpg"
                          />
                        }
                        label={profile.user_name}
                        sx={{
                          fontSize: 12,
                          // textTransform: "uppercase",
                          letterSpacing: 1,
                          fontWeight: "bold",
                          color: "black",
                        }}
                        variant="outlined"
                      />
                      <Button
                        onClick={_handle_clear_session}
                        sx={{
                          my: 2,
                          display: "block",
                          fontSize: 12,
                          textTransform: "uppercase",
                          letterSpacing: 1,
                          fontWeight: "bold",
                          color: "blue",
                        }}
                      >
                        keluar
                      </Button>
                    </Box>
                  </ProfileMenu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        ) : null}
        <Container maxWidth={false}>
          <Outlet />
        </Container>
      </Box>
      <Drawer
        anchor={'left'}
        open={drawerState['left']}
        onClose={_handle_drawer('left', false)}
      >
        {list('left')}
      </Drawer>
    </React.Fragment>
  );
};

export default Layout;
