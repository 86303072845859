import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    CssBaseline,
    Input,
    InputLabel,
    Dialog,
    Alert,
    AlertTitle,
    Divider,
    MenuItem,
    InputAdornment,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    Avatar,
    DialogTitle,
    Stack,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    Link,
    Box,
    Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import { useNavigate, useLocation } from "react-router-dom";
import {
    RefreshOutlined,
    AccountCircle,
    Save,
    Close,
    ExitToAppOutlined,
    AssignmentInd,
    Lock,
    Download,
    CloudSync,
    AccountTree,
    VisibilityOff,
    AccessTime,
    Visibility,
} from "@mui/icons-material";
import moment from "moment";
import storage from "helper/helper.storage";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { def } from "../../helper/helper.const";
import StatusFilter from "components/datatables/status_filter";
import BtnActionRender from "components/datatables/btn_new_order";
const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input_text: {
        textTransform: "uppercase",
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActiveOrderUnload() {
    let navigate = useNavigate();
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [orderDetails, setOrderDetails] = React.useState({});
    const [syncLatest, setSyncLatest] = React.useState({});
    const [overlay, setOverlay] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [viewEdit, setEditView] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [clearID, setClearID] = React.useState("");
    const gridREF = useRef();
    const perPage = 20;
    const [viewDelete, askDelete] = React.useState(false);
    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );
    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );


    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    console.log(params);
                    let HEADER_CONFIG = {
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                    };
                    const page = params.request.endRow / perPage;
                    params.request.page = Math.ceil(page);
                    params.request.per_page = Math.ceil(perPage);
                    Api.post(
                        `/web-app/context/data/active-order-unload`,
                        JSON.stringify(params.request),
                        HEADER_CONFIG
                    )
                        .then((data) => {
                            console.log(data.data);
                            // params.successCallback(data.data.data, data.data.lastData);
                            params.success({
                                rowData: data.data.data,
                                rowCount: data.data.lastRow,
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            params.successCallback([], 0);
                        });
                },
            };

            gridApi.setServerSideDatasource(dataSource);
        }
    }, [gridApi]);

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        };
    }, []);

    // enables pagination in the grid
    const pagination = true;

    // sets 10 rows per page (default is 100)
    const paginationPageSize = 20;

    const _init_view_detail = async (id) => {
        window.open("/order/aktif/jalan-bongkar/" + id, "_blank")
    };

    const _init_edit = async (id) => {
        window.open("/order/aktif/jalan-bongkar/" + id, "_blank")
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Aksi",
            field: "action",
            minWidth: 180,
            enableRowGroup: false,
            editable: false,
            filter: false,
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
                _view_detail: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_view_detail(data);
                },
                _view_edit: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_edit(data);
                }
            },
        },
        {
            headerName: "No",
            field: "#",
            editable: false,
            enableRowGroup: true,
            filter: false,
        },
        {
            headerName: "No SO",
            field: "order_so_number",
            minWidth: 140,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "No Polisi",
            field: "order_vehicle_no",
            editable: false,
            minWidth: 180,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jenis Truck",
            field: "order_type_truck",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Nama Driver",
            field: "order_driver_name",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Perjalanan Foto",
            minWidth: 220,
            headerClass: 'yellow-header',
            field: "order_delivery_progress",
            editable: false,
            enableRowGroup: true,
            cellStyle: (params) => {
                if (params.value == "T") {
                    //mark police cells as red
                    return { color: "red" };
                } else if (params.value == "K") {
                    //mark police cells as red
                    return { color: "orange" };
                } else if (params.value == "Y") {
                    //mark police cells as red
                    return { color: "green" };
                } else {
                    return { color: "black" }
                }
            },
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Bongkar Absen",
            field: "order_unload_attend_time",
            headerClass: 'green-header',
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = cellValue;
                    if (dateAsString == null) return -1;
                    var dateParts = dateAsString.split("/");
                    var cellDate = new Date(
                        Number(dateParts[2]),
                        Number(dateParts[1]) - 1,
                        Number(dateParts[0])
                    );

                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    }

                    if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                },
                browserDatePicker: true,
            },
        },
        {
            headerClass: 'green-header',
            headerName: "Bongkar Foto 100%",
            field: "order_unload_100_percent",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red" };
                } else {
                    //mark police cells as red
                    return { color: "green" };
                }
                return null;
            },
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerClass: 'green-header',
            headerName: "Bungkar Foto 75%",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red" };
                } else {
                    //mark police cells as red
                    return { color: "green" };
                }
                return null;
            },
            field: "order_unload_75_percent",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerClass: 'green-header',
            headerName: "Bongkar Foto 50%",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red" };
                } else {
                    //mark police cells as red
                    return { color: "green" };
                }
                return null;
            },
            field: "order_unload_50_percent",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerClass: 'green-header',
            headerName: "Bongkar Foto 25%",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red" };
                } else {
                    //mark police cells as red
                    return { color: "green" };
                }
                return null;
            },
            field: "order_unload_25_percent",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerClass: 'green-header',
            headerName: "Bongkar Foto SJ",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red" };
                } else {
                    //mark police cells as red
                    return { color: "green" };
                }
                return null;
            },
            field: "order_unload_document_percent",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "No HP",
            field: "order_handphone_1",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Keterangan",
            field: "order_unload_description",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            // headerClass: 'orange-header',
            headerName: "Comment",
            field: "order_unload_comment",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
    ]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
            defaultToolPanel: "",
        };
    }, []);


    const [form, setForm] = React.useState({});

    useEffect(() => {
        setForm(orderDetails);
    }, [orderDetails]);

    const _handle_close_detail = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setViewDetail(false);
    };

    const _exportExcel = useCallback(() => {
        gridREF.current.api.exportDataAsExcel();
    }, []);

    const _refresh_cache = useCallback(() => {
        gridREF.current.api.refreshServerSideStore({ purge: true });
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const [frameworkComponents, setFrameworkComponents] = useState({
        btnCellRenderer: BtnActionRender,
    });

    const excelStyles = [
        {
            id: "header",
            alignment: {
                vertical: "Center",
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
                bold: true,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Center',
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
            },
        },
    ];



    const _handle_close_edit = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setEditView(false);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - order aktif - pergi muat</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/order/aktif/pergi-muat" />
            </Helmet>
            <CssBaseline />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={overlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box
                sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
            >
                <Breadcrumbs
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 1,
                        textTransform: "uppercase",
                        padding: 2,
                    }}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Order Aktif
                    </Link>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        color="text.primary"
                    >
                        Jalan Bongkar
                    </Typography>
                </Breadcrumbs>
            </Box>

            <Box
                className="ag-theme-alpine"
                sx={{ height: 800, marginBottom: 20, width: "100%" }}
            >
                <Stack sx={{ margin: 2 }} spacing={2} direction="row">
                    <StyledButton variant="text" onClick={() => _refresh_cache()}>
                        <RefreshOutlined sx={{ mr: 1 }} />
                        Reload
                    </StyledButton>
                    <StyledButton variant="text" onClick={() => _exportExcel()}>
                        <Download sx={{ mr: 1 }} />
                        Export Excel
                    </StyledButton>
                </Stack>
                <AgGridReact
                    ref={gridREF}
                    excelStyles={excelStyles}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onGridReady={onGridReady}
                    frameworkComponents={frameworkComponents}
                    chartThemes={["ag-theme-alpine"]}
                    defaultColDef={defaultColDef}
                    rowGroupPanelShow={"always"}
                    rowModelType={"serverSide"}
                    serverSideStoreType={"partial"}
                    purgeClosedRowNodes={true}
                    cacheBlockSize={20}
                    animateRows={true}
                    pivotMode={false}
                    sideBar={sideBar}
                    columnDefs={columnDefs}
                />
            </Box>
        </Grid>
    );
}