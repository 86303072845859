import React, {
    useState,
    useMemo,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { makeStyles } from "@mui/styles";
import InputBase from '@mui/material/InputBase';
import PlaceIcon from '@mui/icons-material/Place';
import {
    Typography,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    CssBaseline,
    Stack,
    Avatar,
    Grid,
    InputAdornment,
    Checkbox,
    Box,
    Table,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, alpha } from "@mui/material/styles";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Dialog from "@mui/material/Dialog";


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import BtnCellRenderer from "components/datatables/btn_edit_location";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SaveIcon from "@mui/icons-material/Save";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { InfoOutlined, RefreshOutlined, Download, Save, ExitToAppOutlined, SpaceBar } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import validate_input from "helper/helper.error_form";
import { def } from "../../helper/helper.const";
import Geocode from "react-geocode";
// import { Circle } from '@react-google-maps/api';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

// import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Autocomplete from '@mui/material/Autocomplete';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import { compose, withProps, lifecycle, withStateHandlers } from "recompose";
import { InfoWindow, withGoogleMap, withScriptjs, GoogleMap, Marker, Circle } from 'react-google-maps';

import Map from '../../components/map/Map';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    list_access: {
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: 1,
        textTransform: "uppercase",
        padding: 2,
    },
    list_access_menu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    label_checkbox: {
        fontWeight: 700,
        marginLeft: 5,
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: 1,
    },
    label_checkbox_child: {
        marginLeft: 20,
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: 1,
        '&::before': {
            content: '',
        }
    },
    input_label: {
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 12,
            letterSpacing: 1,
        },
    },
    button: {
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 13,
        letterSpacing: 1,
        "& .MuiButton-text": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 13,
            letterSpacing: 1,
        },
    },
    child_context_table: {
        paddingLeft: 10,
    },
    custom_table_row: {
        "& .MuiTableRow-root": {
            borderBottomWidth: 1,
            borderBottomStyle: "dashed",
            borderBottomColor: "#EFF2F5",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    "& .MuiInputBase-input::placeholder": {
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: 1,
    }
}));


const Location = () => {
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [placeDetails, savePlaceDetailsToState] = React.useState(null);
    const [placeMapList, setPlaceList] = React.useState(null);
    const [loadingMap, setLoadingMap] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [form, setForm] = React.useState({
        location_id: "",
        location_name: "",
        location_code: "",
        location_address: "",
        location_ward: "",
        location_districts: "",
        location_city: "",
        location_province: "",
        location_longitude: "",
        location_latitude: "",
        location_radius: 200,
        location_phone: "",
        location_pic: "",
        location_status: 1
    });
    const [overlay, setOverlay] = React.useState(false);
    const [viewForm, setViewForm] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [viewAdd, setViewAdd] = React.useState(false);
    const [viewMap, setViewMap] = React.useState(false);
    const [locationData, setLocationData] = React.useState({});
    const [gridApi, setGridApi] = useState(null);
    const [maplistaddress, setMapListAddress] = useState([]);

    const gridREF = useRef();
    const perPage = 20;


    const _handle_form = (property, event) => {
        const collection = { ...form };
        collection[property] = event.target.value;
        setError({
            ...error,
            [property]: "",
        });

        setForm(collection);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("latitude is :", position.coords.latitude);
            console.log("longitude is :", position.coords.longitude);
            setCenter({
                lng: position.coords.longitude,
                lat: position.coords.latitude,
            });
        });
        setZoom(16);
        setForm({
            location_id: "",
            location_name: "",
            location_code: "",
            location_address: "",
            location_ward: "",
            location_districts: "",
            location_city: "",
            location_province: "",
            location_longitude: null,
            location_latitude: null,
            location_radius: 200,
            location_phone: "",
            location_pic: "",
            location_status: 0
        });
        savePlaceDetailsToState(null)
    }, [viewAdd]); 
    useEffect(() => {
        savePlaceDetailsToState(null)
    }, [viewDetail]);

    useEffect(() => {
        setCenter({
            lng: parseFloat(locationData.location_longitude),
            lat: parseFloat(locationData.location_latitude),
        });
        setZoom(16);
        setForm({
            location_id: locationData.location_id,
            location_name: locationData.location_name,
            location_code: locationData.location_code,
            location_address: locationData.location_address,
            location_ward: locationData.location_ward,
            location_districts: locationData.location_districts,
            location_city: locationData.location_city,
            location_province: locationData.location_province,
            location_longitude: locationData.location_longitude,
            location_latitude: locationData.location_latitude,
            location_radius: locationData.location_radius,
            location_phone: locationData.location_phone,
            location_pic: locationData.location_pic,
            location_status: locationData.location_status,
        });
    }, [locationData]);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: def.API_KEY,
    });

    useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length !== 0) {
            savePlaceDetailsToState(placePredictions);
        }

    }, [placePredictions]);


    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            width: 100,
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 12,
            borderRadius: 0,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        };
    }, []);

    // enables pagination in the grid
    const pagination = true;

    // sets 10 rows per page (default is 100)
    const paginationPageSize = 20;

    const [frameworkComponents, setFrameworkComponents] = useState({
        btnCellRenderer: BtnCellRenderer,
    });

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Aksi",
            field: "action",
            enableRowGroup: false,
            editable: false,
            filter: false,
            minWidth: 120,
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
                _view_detail: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_view_detail(data);
                },
                _view_edit: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_edit(data);
                },
            },
        },
        {
            headerName: "No",
            editable: false,
            field: "number",
            minWidth: 80,
            enableRowGroup: false,
            filter: false,
        },
        {
            headerName: "Active",
            editable: false,
            field: "location_status",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Kode Lokasi",
            editable: false,
            field: "location_code",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Nama Lokasi",
            editable: false,
            field: "location_name",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Alamat Lokasi",
            editable: false,
            field: "location_address",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Kelurahan",
            editable: false,
            field: "location_ward",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Kecamatan",
            editable: false,
            field: "location_districts",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Kab/Kota",
            editable: false,
            field: "location_city",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Provinsi",
            editable: false,
            field: "location_province",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Longitude",
            editable: false,
            field: "location_longitude",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Latitude",
            editable: false,
            field: "location_latitude",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Radius",
            editable: false,
            field: "location_radius",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Telp",
            editable: false,
            field: "location_phone",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "PIC",
            editable: false,
            field: "location_pic",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
    ]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    console.log(params);
                    let HEADER_CONFIG = {
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                    };
                    const page = params.request.endRow / perPage;
                    Api.post(
                        `/web-app/context/settings/location?per_page=${perPage}&page=${page}`,
                        JSON.stringify(params.request),
                        HEADER_CONFIG
                    )
                        .then((data) => {
                            console.log(data.data);
                            params.success({
                                rowData: data.data.data,
                                rowCount: data.data.lastData,
                            });
                            // params.successCallback(data.data.data, data.data.total);
                        })
                        .catch((error) => {
                            console.error(error);
                            params.fail();
                        });
                },
            };

            gridApi.setServerSideDatasource(dataSource);
        }
    }, [gridApi]);

    const _handle_close_detail = () => {
        setViewDetail(false);
    };
    const _handle_open_map = () => {
        // setLoadingMap(true);
        setViewMap(true);
    };

    const _handle_close_add = () => {
        setViewAdd(false);
    };

    const _refresh_cache = useCallback(() => {
        gridREF.current.api.refreshServerSideStore({ purge: true });
    }, []);

    const _exportExcel = useCallback(() => {
        gridREF.current.api.exportDataAsExcel();
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const _init_view_detail = async (id) => {
        setLoading(true);
        setViewForm(true);
        try {
            const { data } = await Api.get("/web-app/context/settings/location/detail", {
                params: {
                    id: id,
                },
            });

            if (data.status) {
                setLocationData(data.data);
                setViewDetail(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error.response);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const _init_edit = async (id) => {
        setLoading(true);
        setViewForm(false);
        try {
            const { data } = await Api.get("/web-app/context/settings/location/edit", {
                params: {
                    id: id,
                },
            });

            if (data.status) {
                setViewDetail(true);
                setLocationData(data.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const validate = () => {
        const schema = {
            location_name: {
                require: () =>
                    !!form?.location_name ? "" : `nama lokasi diperlukan`,
            },
            location_status: {
                require: () =>
                    !!form?.location_status ? "" : `status lokasi diperlukan`,
            },
            // location_phone: {
            //     require: () =>
            //         !!form?.location_phone ? "" : `nomor telepon lokasi diperlukan`,
            // },
        };
        let error = validate_input(schema);
        setError(error);
        return error;
    };

    const _handle_submit = async (e) => {
        e.preventDefault();
        if (!!validate()) return;
        setLoading(true);
        setError({});
        try {
            const postdata = new FormData();
            postdata.append('location_id', form.location_id);
            postdata.append('location_name', form.location_name);
            postdata.append('location_code', form.location_code);
            postdata.append('location_address', form.location_address);
            postdata.append('location_city', form.location_city);
            postdata.append('location_districts', form.location_districts);
            postdata.append('location_longitude', form.location_longitude);
            postdata.append('location_latitude', form.location_latitude);
            postdata.append('location_name', form.location_name);
            postdata.append('location_phone', form.location_phone);
            postdata.append('location_pic', form.location_pic);
            postdata.append('location_province', form.location_province);
            postdata.append('location_radius', form.location_radius);
            postdata.append('location_status', form.location_status);
            postdata.append('location_ward', form.location_ward);

            const { data } = await Api.post("/web-app/context/settings/location/save", postdata);

            if (data.status) {
                setViewDetail(false);
                setViewAdd(false);
                setLoading(false);
                _refresh_cache();
                MySwal.fire({
                    title: '',
                    text: "Berhasil simpan pengaturan lokasi.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setForm({
                    location_id: "",
                    location_name: "",
                    location_code: "",
                    location_address: "",
                    location_ward: "",
                    location_districts: "",
                    location_city: "",
                    location_province: "",
                    location_longitude: "",
                    location_latitude: "",
                    location_radius: "",
                    location_phone: "",
                    location_pic: "",
                    location_status: 1
                })
            } else {
                _refresh_cache();
                setLoading(false);
            }
        } catch (error) {
            setViewDetail(false);
            setViewAdd(false);
            setLoading(false);
            _refresh_cache();
            console.log(error);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const _handle_close_map = () => {
        setViewMap(false);
    }

    const [clicks, setClicks] = React.useState([]);
    const [zoom, setZoom] = React.useState(18); // initial zoom
    const [center, setCenter] = React.useState({
        lat: 0,
        lng: 0,
    });

    const mapMarkerMove = (event) => {
        console.log(event);
        let newLat = event.lat(),
            newLng = event.lng();

        Geocode.setApiKey(def.API_KEY);

        setCenter({
            lat: newLat,
            lng: newLng,
        });

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const formated_address = response.results[0].formatted_address,
                    address_components = response.results[0].address_components,
                    city = getCity(address_components),
                    districts = getDistricts(address_components),
                    subdistricts = getSubdiscticts(address_components),
                    state = getState(address_components);

                console.log(city);
                console.log(subdistricts);
                console.log(state);
                console.log(address_components);

                setForm({
                    ...form,
                    location_latitude: newLat,
                    location_longitude: newLng,
                    location_address: formated_address,
                    location_city: city,
                    location_ward: subdistricts,
                    location_districts: districts,
                    location_province: state
                });
            },
            error => {
                console.error(error);
            }
        );
    };

    const getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    const getDistricts = (addressArray) => {
        let districts = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_3' === addressArray[i].types[0]) {
                districts = addressArray[i].long_name;
                return districts;
            }
        }
    };

    const getSubdiscticts = (addressArray) => {
        let subdistricts = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ("administrative_area_level_4" === addressArray[i].types[j]) {
                        subdistricts = addressArray[i].long_name;
                        return subdistricts;
                    }
                }
            }
        }
    };

    const getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    const onLoad = circle => {
        console.log('circle onloaded circle: ', circle)
    }

    const onUnmount = circle => {
        console.log('circle onunmount circle: ', circle)
    }

    const MapWithAMarker = compose(
        withStateHandlers(() => ({
            isMarkerShown: false,
            markerPosition: null
        }), {
            onMapClick: ({ isMarkerShown }) => (e) => ({
                markerPosition: e.latLng,
                isMarkerShown: true
            })
        }),
        withScriptjs,
        withGoogleMap
    )
        (props =>
            <GoogleMap
                defaultZoom={zoom}
                defaultCenter={center}
                onClick={props.onMapClick}
            >
                {/* {props.markers.map(marker => (
                    <Marker
                        {...marker}
                        draggable={true}
                        google={props.google}
                        name={'lokasi pool'}
                        onDragEnd={onMarkerDragEnd}
                        options={{
                            zIndex: 1000
                        }}
                        onRightClick={() => props.onMarkerRightClick(marker)}
                    />
                ))} */}
                {props.isMarkerShown && <Marker 
                    // draggable={true}
                    // google={props.google}
                    position={props.markerPosition}
                    name={'lokasi pool'}
                    // onDragEnd={onMarkerDragEnd}
                    options={{
                        zIndex: 1000
                    }}
                 />}
                <Circle
                    // optional
                    onLoad={onLoad}
                    // optional
                    onUnmount={onUnmount}
                    // required
                    center={center}
                    // required
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: parseInt(form?.location_radius),
                        zIndex: 1
                    }}
                />

            </GoogleMap>
        )

    const placeListItem = (item) => {
        console.log(item);
        return (
            <ListItem onClick={() => getLatLngFromAdress(item.description)} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#000000' }} primary={item.description} />
                </ListItemButton>
            </ListItem>
        );
    }

    const getLatLngFromAdress = (value) => {
        Geocode.setApiKey(def.API_KEY);
        // Get latitude & longitude from address.
        Geocode.fromAddress(value).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                console.log(lat, lng);

                setCenter({
                    lat: lat,
                    lng: lng
                })

                Geocode.fromLatLng(lat, lng).then(
                    response => {
                        const formated_address = response.results[0].formatted_address,
                            address_components = response.results[0].address_components,
                            city = getCity(address_components),
                            districts = getDistricts(address_components),
                            subdistricts = getSubdiscticts(address_components),
                            state = getState(address_components);

                        console.log(city);
                        console.log(subdistricts);
                        console.log(state);
                        console.log(address_components);

                        setForm({
                            ...form,
                            location_latitude: lat,
                            location_longitude: lng,
                            location_address: formated_address,
                            location_city: city,
                            location_ward: subdistricts,
                            location_districts: districts,
                            location_province: state
                        });

                        savePlaceDetailsToState(null);
                    },
                    error => {
                        console.error(error);
                    }
                );
            },
            (error) => {
                console.error(error);
            }
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - Pengaturan Location</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/settings/location" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={overlay}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={viewDetail}
                    onClose={_handle_close_detail}
                    TransitionComponent={Transition}
                >
                    <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={_handle_close_detail}
                                aria-label="close"
                            >
                                <CloseIcon sx={{ color: "#000000" }} />
                            </IconButton>
                            <Typography
                                sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                variant="h6"
                                component="div"
                            >
                                View / Edit Lokasi
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ flexGrow: 1, padding: 5 }}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <FormLabel
                                        className={classes.input_label}
                                        id="demo-row-radio-buttons-group-label"
                                    >
                                        Aktif
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            value={form?.location_status}
                                            onChange={(event) => _handle_form("location_status", event)}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value={1}

                                                className={classes.input_label}
                                                control={<Radio />}
                                                label="YA"
                                            />
                                            <FormControlLabel
                                                value={2}

                                                className={classes.input_label}
                                                control={<Radio />}
                                                label="TIDAK"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_code", event)}
                                            disabled={true}
                                            id="input-location_code"
                                            value={form?.location_code}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kode lokasi"
                                            variant="standard"
                                            error={error.location_code || form.location_code === "" ? true : false}
                                            helperText={
                                                error.location_code || form.location_code === "" ? error.location_code : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_name", event)}
                                            id="input-location_name"
                                            value={form?.location_name}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="nama lokasi"
                                            variant="standard"
                                            error={error.location_name || form.location_name === "" ? true : false}
                                            helperText={
                                                error.location_name || form.location_name === "" ? error.location_name : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_address", event)}
                                            id="input-location_address"
                                            value={form?.location_address}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="alamat"
                                            variant="standard"
                                            error={error.location_address || form.location_address === "" ? true : false}
                                            helperText={
                                                error.location_address || form.location_address === "" ? error.location_address : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_ward", event)}
                                            id="input-location_ward"
                                            value={form?.location_ward}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kelurahan"
                                            variant="standard"
                                            error={error.location_ward || form.location_ward === "" ? true : false}
                                            helperText={
                                                error.location_ward || form.location_ward === "" ? error.location_ward : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_city", event)}
                                            id="input-location_city"
                                            value={form?.location_city}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kab/kota"
                                            variant="standard"
                                            error={error.location_city || form.location_city === "" ? true : false}
                                            helperText={
                                                error.location_city || form.location_city === "" ? error.location_city : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_districts", event)}
                                            id="input-location_districts"
                                            value={form?.location_districts}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kecamatan"
                                            variant="standard"
                                            error={error.location_districts || form.location_districts === "" ? true : false}
                                            helperText={
                                                error.location_districts || form.location_districts === "" ? error.location_districts : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}

                                            onChange={(event) => _handle_form("location_province", event)}
                                            id="input-location_province"
                                            value={form?.location_province}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="provinsi"
                                            variant="standard"
                                            error={error.location_province || form.location_province === "" ? true : false}
                                            helperText={
                                                error.location_province || form.location_province === "" ? error.location_province : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            // InputLabelProps={{ shrink: form.location_longitude === "" ? true : false }}  
                                            id="input-location_longitude"
                                            value={form?.location_longitude}
                                            onChange={(event) => _handle_form("location_longitude", event)}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="longitude"
                                            variant="standard"
                                            error={error.location_longitude || form.location_longitude === "" ? true : false}
                                            helperText={
                                                error.location_longitude || form.location_longitude === "" ? error.location_longitude : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField

                                            id="input-location_latitude"
                                            value={form?.location_latitude}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="latitude"
                                            onChange={(event) => _handle_form("location_latitude", event)}
                                            // InputLabelProps={{ shrink: form.location_latitude === "" ? true : false }}  
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <StyledLoadingButton
                                                    loading={loadingMap}
                                                    loadingPosition="start"
                                                    disabled={loadingMap}
                                                    onClick={_handle_open_map}
                                                    type="button"
                                                    startIcon={<MyLocationIcon />}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.submit}
                                                >
                                                    map
                                                </StyledLoadingButton>,
                                            }}
                                            error={error.location_latitude || form.location_latitude === "" ? true : false}
                                            helperText={
                                                error.location_latitude || form.location_latitude === "" ? error.location_latitude : ""
                                            }
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_radius", event)}
                                            id="input-location_radius"
                                            value={form?.location_radius}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
                                            }}
                                            error={error.location_radius || form.location_radius === "" ? true : false}
                                            helperText={
                                                error.location_radius || form.location_radius === "" ? error.location_radius : ""
                                            }
                                            label="radius"
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_phone", event)}
                                            id="input-location_phone"
                                            value={form?.location_phone}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="Telp"
                                            // error={error.location_phone || form.location_phone === "" ? true : false}
                                            helperText={
                                                error.location_phone || form.location_phone === "" ? error.location_phone : ""
                                            }
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_pic", event)}
                                            id="input-location_pic"
                                            value={form?.location_pic}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            // error={error.location_pic || form.location_pic === "" ? true : false}
                                            helperText={
                                                error.location_pic || form.location_pic === "" ? error.location_pic : ""
                                            }
                                            label="PIC"
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid sx={{ marginTop: 5 }} item md={6} xs={6}>
                                    <StyledLoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        disabled={loading}
                                        onClick={_handle_close_detail}
                                        type="button"
                                        startIcon={<ExitToAppOutlined />}
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        className={classes.submit}
                                    >
                                        Kembali
                                    </StyledLoadingButton>

                                    <StyledLoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        disabled={loading}
                                        onClick={_handle_submit}
                                        type="button"
                                        startIcon={<Save />}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Simpan
                                    </StyledLoadingButton>
                                </Grid>


                            </Grid>

                        </form>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={viewAdd}
                    onClose={_handle_close_add}
                    TransitionComponent={Transition}
                >
                    <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={_handle_close_add}
                                aria-label="close"
                            >
                                <CloseIcon sx={{ color: "#000000" }} />
                            </IconButton>
                            <Typography
                                sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                                variant="h6"
                                component="div"
                            >
                                Tambah lokasi baru
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ flexGrow: 1, padding: 5 }}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <FormLabel
                                        className={classes.input_label}
                                        id="demo-row-radio-buttons-group-label"
                                    >
                                        Aktif
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            value={form?.location_status}
                                            onChange={(event) => _handle_form("location_status", event)}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value={1}

                                                className={classes.input_label}
                                                control={<Radio />}
                                                label="YA"
                                            />
                                            <FormControlLabel
                                                value={2}

                                                className={classes.input_label}
                                                control={<Radio />}
                                                label="TIDAK"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_code", event)}
                                            disabled={false}
                                            id="input-location_code"
                                            value={form?.location_code}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kode lokasi"
                                            variant="standard"
                                            error={error.location_code || form.location_code === "" ? true : false}
                                            helperText={
                                                error.location_code || form.location_code === "" ? error.location_code : ""
                                            }
                                        />
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_name", event)}
                                            id="input-location_name"
                                            value={form?.location_name}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="nama lokasi"
                                            variant="standard"
                                            error={error.location_name || form.location_name === "" ? true : false}
                                            helperText={
                                                error.location_name || form.location_name === "" ? error.location_name : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_address", event)}
                                            id="input-location_address"
                                            value={form?.location_address}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="alamat"
                                            variant="standard"
                                            error={error.location_address || form.location_address === "" ? true : false}
                                            helperText={
                                                error.location_address || form.location_address === "" ? error.location_address : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_ward", event)}
                                            id="input-location_ward"
                                            value={form?.location_ward}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kelurahan"
                                            variant="standard"
                                            error={error.location_ward || form.location_ward === "" ? true : false}
                                            helperText={
                                                error.location_ward || form.location_ward === "" ? error.location_ward : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_city", event)}
                                            id="input-location_city"
                                            value={form?.location_city}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kab/kota"
                                            variant="standard"
                                            error={error.location_city || form.location_city === "" ? true : false}
                                            helperText={
                                                error.location_city || form.location_city === "" ? error.location_city : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_districts", event)}
                                            id="input-location_districts"
                                            value={form?.location_districts}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="kecamatan"
                                            variant="standard"
                                            error={error.location_districts || form.location_districts === "" ? true : false}
                                            helperText={
                                                error.location_districts || form.location_districts === "" ? error.location_districts : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            // disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => _handle_form("location_province", event)}
                                            id="input-location_province"
                                            value={form?.location_province}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="provinsi"
                                            variant="standard"
                                            error={error.location_province || form.location_province === "" ? true : false}
                                            helperText={
                                                error.location_province || form.location_province === "" ? error.location_province : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            id="input-location_longitude"
                                            value={form?.location_longitude}
                                            onChange={(event) => _handle_form("location_longitude", event)}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="longitude"
                                            variant="standard"
                                            error={error.location_longitude || form.location_longitude === "" ? true : false}
                                            helperText={
                                                error.location_longitude || form.location_longitude === "" ? error.location_longitude : ""
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField

                                            id="input-location_latitude"
                                            value={form?.location_latitude}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="latitude"
                                            onChange={(event) => _handle_form("location_latitude", event)}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <StyledLoadingButton
                                                    loading={loadingMap}
                                                    loadingPosition="start"
                                                    disabled={loadingMap}
                                                    onClick={_handle_open_map}
                                                    type="button"
                                                    startIcon={<MyLocationIcon />}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.submit}
                                                >
                                                    map
                                                </StyledLoadingButton>,
                                            }}
                                            error={error.location_latitude || form.location_latitude === "" ? true : false}
                                            helperText={
                                                error.location_latitude || form.location_latitude === "" ? error.location_latitude : ""
                                            }
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_radius", event)}
                                            id="input-location_radius"
                                            value={form?.location_radius}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
                                            }}
                                            error={error.location_radius || form.location_radius === "" ? true : false}
                                            helperText={
                                                error.location_radius || form.location_radius === "" ? error.location_radius : ""
                                            }
                                            label="radius"
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_phone", event)}
                                            id="input-location_phone"
                                            value={form?.location_phone}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            label="Telp"
                                            // error={error.location_phone || form.location_phone === "" ? true : false}
                                            helperText={
                                                error.location_phone || form.location_phone === "" ? error.location_phone : ""
                                            }
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                        <TextField
                                            onChange={(event) => _handle_form("location_pic", event)}
                                            id="input-location_pic"
                                            value={form?.location_pic}
                                            className={classes.input_label}
                                            margin="normal"
                                            fullWidth
                                            // error={error.location_pic || form.location_pic === "" ? true : false}
                                            helperText={
                                                error.location_pic || form.location_pic === "" ? error.location_pic : ""
                                            }
                                            label="PIC"
                                            variant="standard"
                                        />
                                    </Box>
                                </Grid>
                                <Grid sx={{ marginTop: 5 }} item md={6} xs={6}>
                                    <StyledLoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        disabled={loading}
                                        onClick={_handle_close_add}
                                        type="button"
                                        startIcon={<ExitToAppOutlined />}
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        className={classes.submit}
                                    >
                                        Kembali
                                    </StyledLoadingButton>

                                    <StyledLoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        disabled={loading}
                                        onClick={_handle_submit}
                                        type="button"
                                        startIcon={<Save />}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Simpan
                                    </StyledLoadingButton>
                                </Grid>


                            </Grid>

                        </form>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={viewMap}
                    onClose={_handle_close_map}
                    TransitionComponent={Transition}
                >
                    <AppBar elevation={0} sx={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={_handle_close_map}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="cari lokasi..."
                                    onChange={(evt) => {
                                        getPlacePredictions({ input: evt.target.value });
                                    }}
                                    loading={isPlacePredictionsLoading}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                        </Toolbar>
                        {
                            placeDetails !== null ? (<Box sx={{
                                position: 'absolute',
                                top: 60,
                                left: 20,
                                background: 'white',
                                borderRadius: 4,
                                width: '80%'
                            }}>
                                <List>
                                    {placeDetails.map((item) => placeListItem(item))}
                                </List>

                            </Box>) : null
                        }

                    </AppBar>
                    <Map
                        id="map-location"
                        options={{
                            center: center,
                            zoom: zoom,
                        }}
                        onMapLoad={(map) => {
                            var marker;
                            var circle;
                             marker = new window.google.maps.Marker({
                                position: center,
                                map: map,
                            });
                             circle = new window.google.maps.Circle({
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#FF0000",
                                fillOpacity: 0.35,
                                map,
                                center: center,
                                 radius: parseInt(form?.location_radius),
                            });
                            map.addListener('click', function (e) {
                                console.log(e);
                                if (marker && circle) {
                                    circle.setCenter(e.latLng);
                                    marker.setPosition(e.latLng);
                                    mapMarkerMove(e.latLng);
                                } else {
                                    marker = new window.google.maps.Marker({
                                        position: e.latLng,
                                        map: map
                                    }); 
                                    circle = new window.google.maps.Circle({
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: "#FF0000",
                                        fillOpacity: 0.35,
                                        map,
                                        center: e.latLng,
                                        radius: parseInt(form?.location_radius),
                                    });

                                    mapMarkerMove(e.latLng);
                                }
                            });

                           
                        }}
                    />
                </Dialog>

                <Box
                    sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
                >
                    <Breadcrumbs
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        aria-label="breadcrumb"
                    >
                        <Link underline="hover" color="inherit" href="/dashboard">
                            Pengaturan
                        </Link>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                letterSpacing: 1,
                                textTransform: "uppercase",
                                padding: 2,
                            }}
                            color="text.primary"
                        >
                            Location
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box
                    className="ag-theme-alpine"
                    style={{ height: 600, marginBottom: 20, width: "100%" }}
                >
                    <Stack sx={{ margin: 2 }} spacing={2} direction="row">
                        <StyledButton variant="text" onClick={() => _refresh_cache()}>
                            <RefreshOutlined sx={{ mr: 1 }} />
                            Reload
                        </StyledButton>
                        <StyledButton variant="text" onClick={() => setViewAdd(true)}>
                            Tambah lokasi baru
                        </StyledButton>
                        <StyledButton variant="text" onClick={() => _exportExcel()}>
                            <Download sx={{ mr: 1 }} />
                            Export Excel
                        </StyledButton>
                    </Stack>
                    <AgGridReact
                        ref={gridREF}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        onGridReady={onGridReady}
                        frameworkComponents={frameworkComponents}
                        chartThemes={["ag-theme-alpine"]}
                        defaultColDef={defaultColDef}
                        // rowGroupPanelShow={"always"}
                        pivotMode={false}
                        rowModelType={"serverSide"}
                        serverSideStoreType={"partial"}
                        purgeClosedRowNodes={true}
                        cacheBlockSize={20}
                        animateRows={true}
                        sideBar={sideBar}
                        columnDefs={columnDefs}
                    ></AgGridReact>
                </Box>
            </Grid>
        </React.Fragment>
    );
}

export default Location;
