export default function validate_input(schema) {
  let errors = {};

  Object.keys(schema).map((key) => {
    Object.keys(schema[key]).map((rule) => {
      let response = schema[key][rule]();
      if (!!response) errors[key] = response;
    });
  });

  return Object.keys(errors).length === 0 ? false : errors;
}
