import * as React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
  Outlet,
} from "react-router-dom";
import Layout from "page/layout";
// import Nomatch from "../page/nomatch";
import Login from "page/login";
import Dashboard from "page/dashboard";
import Announcement from "page/settings/announcement";
import Users from "page/settings/users";
import Roles from "page/settings/roles";
import Driver from "page/resource/driver";
import Units from "page/resource/unit";
import AbsenceToday from "page/absence/today";
import NewOrder from "page/order/new-order";
import ActiveOrder from "page/order/active-order";
import FinishOrder from "page/order/order-done";
import FinishOrderDetail from "page/order/order-done-detail";
import ActiveOrderLoad from "page/order/active-order-load";
import ActiveOrderUnLoad from "page/order/active-order-unload";
import ActiveOrderLoadDetail from "page/order/active-order-load-detail";
import ActiveOrderUnLoadDetail from "page/order/active-order-unload-detail";
import DispatchNote from "page/document/dispatchNote";
import AbsenceSettings from "page/settings/absence";
import AbsencePastDay from "page/absence/past";
import Location from "page/settings/location";
import RoadMoneyDetails from "page/document/roadMoneyDetails";
import { Context } from "state/store";

const RootRoute = () => {
  const [state, dispatch] = React.useContext(Context);
  const { token } = state.userReducer;

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/announcement"
          element={
            <RequireAuth>
              <Announcement />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/location"
          element={
            <RequireAuth>
              <Location />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/roles"
          element={
            <RequireAuth>
              <Roles />
            </RequireAuth>
          }
        /> 
        <Route
          path="/settings/absent"
          element={
            <RequireAuth>
              <AbsenceSettings />
            </RequireAuth>
          }
        />
        <Route
          path="/resource/driver"
          element={
            <RequireAuth>
              <Driver />
            </RequireAuth>
          }
        />
        <Route
          path="/resource/units"
          element={
            <RequireAuth>
              <Units />
            </RequireAuth>
          }
        />
        <Route
          path="/order/baru"
          element={
            <RequireAuth>
              <NewOrder />
            </RequireAuth>
          }
        />
        <Route
          path="/order/aktif"
          element={
            <RequireAuth>
              <ActiveOrder />
            </RequireAuth>
          }
        />
        <Route
          path="/order/selesai"
          element={
            <RequireAuth>
              <FinishOrder />
            </RequireAuth>
          }
        />
        <Route
          path="/order/aktif/pergi-muat"
          element={
            <RequireAuth>
              <ActiveOrderLoad />
            </RequireAuth>
          }
        />
        <Route
          path="/order/aktif/jalan-bongkar"
          element={
            <RequireAuth>
              <ActiveOrderUnLoad />
            </RequireAuth>
          }
        />
        <Route
          path="/order/selesai/:id"
          element={
            <RequireAuth>
              <FinishOrderDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/order/aktif/jalan-bongkar/:id"
          element={
            <RequireAuth>
              <ActiveOrderUnLoadDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/order/aktif/pergi-muat/:id"
          element={
            <RequireAuth>
              <ActiveOrderLoadDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/document/surat-jalan"
          element={
            <RequireAuth>
              <DispatchNote />
            </RequireAuth>
          }
        />
        <Route
          path="/document/rincian-uang-jalan"
          element={
            <RequireAuth>
              <RoadMoneyDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/absence/today"
          element={
            <RequireAuth>
              <AbsenceToday />
            </RequireAuth>
          }
        />
        <Route
          path="/absence/pastday"
          element={
            <RequireAuth>
              <AbsencePastDay />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <CheckAuth>
              <Login />
            </CheckAuth>
          }
        />
        {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
        {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
      </Route>
    </Routes>
  );
};

const RequireAuth = ({ children }) => {
  let location = useLocation();
  const [state, dispatch] = React.useContext(Context);
  const { token } = state.userReducer;

  if (token === null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

const CheckAuth = ({ children }) => {
  let location = useLocation();
  let from = location.state?.from?.pathname || "/dashboard";

  console.log(from);

  const [state, dispatch] = React.useContext(Context);
  const { token } = state.userReducer;

  console.log(token)

  if (token === null) {
    return children;
  } else {
    return <Navigate to={from} state={{ from: location }} replace />;
  }
};

export default RootRoute;
