import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Input,
  InputLabel,
  Dialog,
  Alert,
  AlertTitle,
  MenuItem,
  InputAdornment,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Button,
  Avatar,
  DialogTitle,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
  Link,
  Box,
  Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
  RefreshOutlined,
  AccountCircle,
  Save,
  Close,
  AssignmentInd,
  Lock,
  Download,
  CloudSync,
  AccountTree,
  VisibilityOff,
  AccessTime,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import storage from "helper/helper.storage";
import { def } from "../../helper/helper.const";
import StatusFilter from "components/datatables/status_filter";
import BtnUnitRender from "components/datatables/btn_unit_render";
const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  input_label: {
    color: "#3c4043",
    fontSize: 11,
    textTransform: "uppercase",
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Unit() {
  const classes = useStyles();
  const Api = useAxios();
  const MySwal = withReactContent(Swal);
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const [unitDetails, setUnitDetails] = React.useState({});
  const [syncLatest, setSyncLatest] = React.useState({});
  const [overlay, setOverlay] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewEdit, setEditView] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [clearID, setClearID] = React.useState("");
  const gridREF = useRef();
  const perPage = 20;
  const [viewDelete, askDelete] = React.useState(false);
  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          const page = params.request.endRow / perPage;
          Api.post(
            `/web-app/context/data/unit?page=${Math.ceil(
              page
            )}&per_page=${Math.ceil(perPage)}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.successCallback(data.data.data, data.data.lastRow);
            })
            .catch((error) => {
              console.error(error);
              params.successCallback([], 0);
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    const _init_sync = async () => {
      setOverlay(true);
      try {
        const { data } = await Api.get(
          `/web-app/context/sync/latest-unit?date=${moment().format(
            "YYYY-MM-DD"
          )}`
        );
        if (data.status) {
          setOverlay(false);
          setSyncLatest(data);
        } else {
          setOverlay(false);
        }
      } catch (error) {
        setOverlay(false);
        console.log(error);
      }
    };
    _init_sync();
    return () => {
      setSyncLatest({});
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  const statusCellRender = (params) => {
    let statusUnit = "";
    if (params.value === 1) {
      statusUnit = "Aktif";
    } else {
      statusUnit = "Tidak Aktif";
    }
    return statusUnit;
  };

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 20;

  const _init_clear_finger = (id) => {
    console.log(id);
    setClearID(id);
    askDelete(true);
  };

  const _handle_close_remove_confirm = async () => {
    try {
      let payload = new FormData();
      payload.append("driver_id", clearID);
      const { data } = await Api.post(
        `/web-app/context/driver/clear-finger`,
        payload
      );
      if (data.status) {
        askDelete(false);
        _refresh_cache();
        MySwal.fire({
          title: "Berhasil!",
          text: "Sistem berhasil reset driver device.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        MySwal.fire({
          title: "Sistem gagal!",
          text: "Gagal reset driver device mohon ulangi lagi nanti.",
          icon: "error",
          confirmButtonText: "OK",
        });
        askDelete(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Aksi",
      field: "action",
      minWidth: 180,
      enableRowGroup: false,
      editable: false,
      filter: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        _view_detail: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_view_detail(data);
        },
        _view_edit: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_edit(data);
        },
        _view_delete: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_clear_finger(data);
        },
      },
    },
    {
      headerName: "No",
      field: "id",
      minWidth: 120,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Status",
      field: "unit_status",
      cellRenderer: statusCellRender,
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: StatusFilter,
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Unit ID",
      field: "unit_truck_no",
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "No polisi",
      field: "unit_vehicle_no",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Alokasi pool",
      field: "unit_pool",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Alokasi unit",
      field: "unit_alokasi",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Berlaku STNK",
      field: "unit_stnk",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
      },
    },
    {
      headerName: "Hari STNK",
      field: "unit_stnk_days",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Keterangan",
      field: "unit_description",
      minWidth: 180,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
  ]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const _init_view_detail = async (id) => {
    setOverlay(true);
    try {
      const { data } = await Api.get("/web-app/context/edit/unit", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setViewDetail(true);
        setUnitDetails(data.data);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const [form, setForm] = React.useState({
    unit_id: "",
    unit_status: 1,
    unit_truck_no: "",
    unit_vehicle_no: "",
    unit_description: "",
    unit_pool: "",
    unit_alokasi: "",
    unit_stnk: "",
    unit_stnk_days: "",
    description: "",
    unit_vehicle_type: "",
    attendance_list: []
  });

  useEffect(() => {
    setForm({
      unit_id: unitDetails.unit_id,
      unit_status: unitDetails.unit_status,
      unit_truck_no: unitDetails.unit_truck_no,
      unit_vehicle_no: unitDetails.unit_vehicle_no,
      unit_description: unitDetails.unit_description,
      unit_pool: unitDetails.unit_pool,
      unit_alokasi: unitDetails.unit_alokasi,
      unit_stnk: unitDetails.unit_stnk,
      unit_stnk_days: unitDetails.unit_stnk_days,
      description: unitDetails.description,
      unit_vehicle_type: unitDetails.unit_vehicle_type,
    });
  }, [unitDetails]);

  const _init_edit = async (id) => {
    setOverlay(true);
    setForm({
      unit_id: "",
      unit_status: 1,
      unit_truck_no: "",
      unit_vehicle_no: "",
      unit_description: "",
      unit_pool: "",
      unit_alokasi: "",
      unit_stnk: "",
      unit_stnk_days: "",
      description: "",
      unit_vehicle_type: "",
    });
    setError({});
    try {
      const { data } = await Api.get("/web-app/context/edit/unit", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setEditView(true);
        setUnitDetails(data.data);
        setpopup({
          ...alertpoup,
          status: false,
          error: false,
          message: "",
        });
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _handle_close_edit = () => {
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    setEditView(false);
  };

  const _handle_submit = async (e) => {
    e.preventDefault();
    setOverlay(true);
    setError({});
    try {
      const { data } = await Api.post("/web-app/context/submit/edit/unit", {
        unit_id: form.unit_id,
        unit_status: form.unit_status,
        description: form.description,
      });
      if (data.status) {
        setpopup({
          ...alertpoup,
          status: true,
          error: false,
          message: data.message,
        });
        setEditView(false);
        setOverlay(false);
        setForm({
          unit_id: "",
          unit_status: 1,
          unit_truck_no: "",
          unit_vehicle_no: "",
          unit_description: "",
          unit_pool: "",
          unit_alokasi: "",
          unit_stnk: "",
          unit_stnk_days: "",
          description: "",
          unit_vehicle_type: "",
        });
        setError({});
        _refresh_cache();
      } else {
        setpopup({
          ...alertpoup,
          status: false,
          error: true,
          message: data.message,
        });
        setOverlay(false);
        setError(data.error);
      }
    } catch (error) {
      setpopup({
        ...alertpoup,
        status: false,
        error: true,
        message: error.message,
      });
      setOverlay(false);
      console.log(error);
    }
  };

  const _handle_create_driver = () => {
    setEditView(true);
    setForm({});
    setError({});
  };

  const _request_unit_list = async () => {
    setOverlay(true);
    try {
      const { data } = await Api.get(
        `/web-app/context/sync/latest-unit?date=${moment().format(
          "YYYY-MM-DD"
        )}`
      );
      if (data.status) {
        setOverlay(false);
        _refresh_cache();
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _handle_close_detail = () => {
    setViewDetail(false);
  };

  const _exportExcel = useCallback(() => {
    gridREF.current.api.exportDataAsExcel();
  }, []);
  
  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: BtnUnitRender,
  });

  const _handle_form = (property, event) => {
    const collection = { ...form };
    collection[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setForm(collection);
  };

  const excelStyles = [
    {
      id: "header",
      alignment: {
        vertical: "Center",
        horizontal: 'Center'
      },
      font: { 
        fontName: 'Arial',
        bold: true,
      },
    },
    {
      id: 'cell',
      alignment: {
        vertical: 'Center',
        horizontal: 'Center'
      },
      font: {
        fontName: 'Arial',
      },
    },
  ];

  return (
    <Grid container component="main" className={classes.root}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MKA - unit</title>
          <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={overlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewDetail}
        onClose={_handle_close_detail}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_detail}
              aria-label="close"
            >
              <Close sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Detail units
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertpoup.status ? (
              <React.Fragment>
                {alertpoup.error === false ? (
                  <Alert severity="success">{alertpoup.message}</Alert>
                ) : (
                  <Alert
                    onClose={() => {
                      setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                      });
                    }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {alertpoup.message} — <strong>sistem error</strong>
                  </Alert>
                )}
              </React.Fragment>
            ) : null}
          </Stack>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormLabel
                  className={classes.input_label}
                  id="demo-row-radio-buttons-group-label"
                >
                  Status
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={form?.unit_status}
                    onChange={(event) => _handle_form("unit_status", event)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="Aktif"
                    />
                    <FormControlLabel
                      value={2}
                      disabled
                      className={classes.input_label}
                      control={<Radio />}
                      label="Tidak aktif"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_truck_no"
                    onChange={(event) => _handle_form("unit_truck_no", event)}
                    value={form?.unit_truck_no}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Unit ID"
                    variant="standard"
                    error={error && form.unit_truck_no === "" ? true : false}
                    helperText={
                      error && form.unit_truck_no === ""
                        ? error.unit_truck_no
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_no"
                    onChange={(event) => _handle_form("unit_vehicle_no", event)}
                    value={form?.unit_vehicle_no}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. Polisi"
                    variant="standard"
                    error={error && form.unit_vehicle_no === "" ? true : false}
                    helperText={
                      error && form.unit_vehicle_no === ""
                        ? error.unit_vehicle_no
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_type"
                    onChange={(event) =>
                      _handle_form("unit_vehicle_type", event)
                    }
                    value={form?.unit_vehicle_type}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Jenis Truck"
                    variant="standard"
                    error={
                      error && form.unit_vehicle_type === "" ? true : false
                    }
                    helperText={
                      error && form.unit_vehicle_type === ""
                        ? error.unit_vehicle_type
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_pool"
                    onChange={(event) => _handle_form("unit_pool", event)}
                    value={form?.unit_pool}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Alokasi Pool"
                    variant="standard"
                    error={error && form.unit_pool === "" ? true : false}
                    helperText={
                      error && form.unit_pool === "" ? error.unit_pool : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_alokasi"
                    onChange={(event) => _handle_form("unit_alokasi", event)}
                    value={form?.unit_alokasi}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Alokasi Unit"
                    variant="standard"
                    error={error && form.unit_alokasi === "" ? true : false}
                    helperText={
                      error && form.unit_alokasi === ""
                        ? error.unit_alokasi
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_stnk"
                    onChange={(event) => _handle_form("unit_stnk", event)}
                    value={form?.unit_stnk}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Berlaku STNK"
                    variant="standard"
                    error={error && form.unit_stnk === "" ? true : false}
                    helperText={
                      error && form.unit_stnk === "" ? error.unit_stnk : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_stnk_days"
                    onChange={(event) => _handle_form("unit_stnk_days", event)}
                    value={form?.unit_stnk_days}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Hari STNK"
                    variant="standard"
                    error={error && form.unit_stnk_days === "" ? true : false}
                    helperText={
                      error && form.unit_stnk_days === ""
                        ? error.unit_stnk_days
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-description"
                    isReadonly
                    value={form?.description}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Keterangan"
                    variant="standard"
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Dialog
        open={viewDelete}
        onClose={() => askDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Anda yakin ingin mereset device driver ini ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Device driver ini akan direset secara permanen dan tidak ada
            pemulihan data setelah direset.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => askDelete(false)}>Batal</Button>
          <Button onClick={_handle_close_remove_confirm} autoFocus>
            Ya, reset
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewEdit}
        onClose={_handle_close_edit}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_edit}
              aria-label="close"
            >
              <Close sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Form Units
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertpoup.status ? (
              <React.Fragment>
                {alertpoup.error === false ? (
                  <Alert severity="success">{alertpoup.message}</Alert>
                ) : (
                  <Alert
                    onClose={() => {
                      setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                      });
                    }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {alertpoup.message} — <strong>sistem error</strong>
                  </Alert>
                )}
              </React.Fragment>
            ) : null}
          </Stack>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormLabel
                  className={classes.input_label}
                  id="demo-row-radio-buttons-group-label"
                >
                  Status
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={form?.unit_status}
                    onChange={(event) => _handle_form("unit_status", event)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      className={classes.input_label}
                      control={<Radio />}
                      label="Aktif"
                    />
                    <FormControlLabel
                      value={2}
                      className={classes.input_label}
                      control={<Radio />}
                      label="Tidak aktif"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_truck_no"
                    onChange={(event) => _handle_form("unit_truck_no", event)}
                    value={form?.unit_truck_no}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Unit ID"
                    variant="standard"
                    error={error && form.unit_truck_no === "" ? true : false}
                    helperText={
                      error && form.unit_truck_no === ""
                        ? error.unit_truck_no
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_no"
                    onChange={(event) => _handle_form("unit_vehicle_no", event)}
                    value={form?.unit_vehicle_no}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. Polisi"
                    variant="standard"
                    error={error && form.unit_vehicle_no === "" ? true : false}
                    helperText={
                      error && form.unit_vehicle_no === ""
                        ? error.unit_vehicle_no
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_type"
                    onChange={(event) =>
                      _handle_form("unit_vehicle_type", event)
                    }
                    value={form?.unit_vehicle_type}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Jenis Truck"
                    variant="standard"
                    error={
                      error && form.unit_vehicle_type === "" ? true : false
                    }
                    helperText={
                      error && form.unit_vehicle_type === ""
                        ? error.unit_vehicle_type
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_pool"
                    onChange={(event) => _handle_form("unit_pool", event)}
                    value={form?.unit_pool}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Alokasi Pool"
                    variant="standard"
                    error={error && form.unit_pool === "" ? true : false}
                    helperText={
                      error && form.unit_pool === "" ? error.unit_pool : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_alokasi"
                    onChange={(event) => _handle_form("unit_alokasi", event)}
                    value={form?.unit_alokasi}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Alokasi Unit"
                    variant="standard"
                    error={error && form.unit_alokasi === "" ? true : false}
                    helperText={
                      error && form.unit_alokasi === ""
                        ? error.unit_alokasi
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_stnk"
                    onChange={(event) => _handle_form("unit_stnk", event)}
                    value={form?.unit_stnk}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Berlaku STNK"
                    variant="standard"
                    error={error && form.unit_stnk === "" ? true : false}
                    helperText={
                      error && form.unit_stnk === "" ? error.unit_stnk : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_stnk_days"
                    onChange={(event) => _handle_form("unit_stnk_days", event)}
                    value={form?.unit_stnk_days}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Hari STNK"
                    variant="standard"
                    error={error && form.unit_stnk_days === "" ? true : false}
                    helperText={
                      error && form.unit_stnk_days === ""
                        ? error.unit_stnk_days
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    id="input-description"
                    isReadonly
                    value={form?.description}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Keterangan"
                    variant="standard"
                  />
                </Box>
              </Grid>
              <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                <StyledLoadingButton
                  loading={loading}
                  loadingPosition="start"
                  disabled={loading}
                  onClick={_handle_submit}
                  type="button"
                  startIcon={<Save />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Simpan
                </StyledLoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Box
        sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
      >
        <Breadcrumbs
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 1,
            textTransform: "uppercase",
            padding: 2,
          }}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/dashboard">
            Resources
          </Link>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            color="text.primary"
          >
            units
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        className="ag-theme-alpine"
        sx={{ height: 800, marginBottom: 20, width: "100%" }}
      >
        <Stack sx={{ margin: 2 }} spacing={2} direction="row">
          <StyledButton variant="text" onClick={() => _refresh_cache()}>
            <RefreshOutlined sx={{ mr: 1 }} />
            Reload
          </StyledButton>
          <StyledButton variant="text" onClick={() => _request_unit_list()}>
            <CloudSync sx={{ mr: 1 }} />
            Check new unit
          </StyledButton>
          <StyledButton variant="text" onClick={() => _exportExcel()}>
            <Download sx={{ mr: 1 }} />
            Export Excel
          </StyledButton>
          {syncLatest.latest_sync === undefined ? (
            <StyledButton variant="text" disabled={true}>
              <AccessTime sx={{ mr: 1 }} />
              latest async [{syncLatest.latest_sync}]
            </StyledButton>
          ) : (
            <StyledButton variant="text" disabled={true}>
              <AccessTime sx={{ mr: 1 }} />
              latest async [{syncLatest.latest_sync}]
            </StyledButton>
          )}
        </Stack>
        <AgGridReact
          ref={gridREF}
          excelStyles={excelStyles}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          chartThemes={["ag-theme-alpine"]}
          defaultColDef={defaultColDef}
          rowGroupPanelShow={"always"}
          rowModelType={"serverSide"}
          serverSideStoreType={"partial"}
          purgeClosedRowNodes={true}
          cacheBlockSize={20}
          animateRows={true}
          pivotMode={false}
          sideBar={sideBar}
          columnDefs={columnDefs}
        />
      </Box>
    </Grid>
  );
}
