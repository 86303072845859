import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    CssBaseline,
    Input,
    InputLabel,
    Dialog,
    Alert,
    AlertTitle,
    Divider,
    MenuItem,
    InputAdornment,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    Avatar,
    DialogTitle,
    Stack,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    Link,
    Box,
    Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
    RefreshOutlined,
    AccountCircle,
    Save,
    Close,
    ExitToAppOutlined,
    AssignmentInd,
    Lock,
    Download,
    CloudSync,
    AccountTree,
    VisibilityOff,
    AccessTime,
    Visibility,
} from "@mui/icons-material";
import moment from "moment";
import storage from "helper/helper.storage";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { def } from "../../helper/helper.const";
import StatusFilter from "components/datatables/status_filter";
import BtnActionRender from "components/datatables/btn_new_order";
const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input_text: {
        textTransform: "uppercase",
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewOrder() {
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [orderDetails, setOrderDetails] = React.useState({});
    const [syncLatest, setSyncLatest] = React.useState({});
    const [overlay, setOverlay] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [viewEdit, setEditView] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [clearID, setClearID] = React.useState("");
    const gridREF = useRef();
    const perPage = 20;
    const [viewDelete, askDelete] = React.useState(false);
    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );
    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    console.log(params);
                    let HEADER_CONFIG = {
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                    };
                    const page = params.request.endRow / perPage;
                    Api.post(
                        `/web-app/context/data/active-order?page=${Math.ceil(
                            page
                        )}&per_page=${Math.ceil(perPage)}`,
                        JSON.stringify(params.request),
                        HEADER_CONFIG
                    )
                        .then((data) => {
                            console.log(data.data);
                            params.successCallback(data.data.data, data.data.lastData);
                        })
                        .catch((error) => {
                            console.error(error);
                            params.successCallback([], 0);
                        });
                },
            };

            gridApi.setServerSideDatasource(dataSource);
        }
    }, [gridApi]);

    useEffect(() => {

    }, []);

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        };
    }, []);

    const statusCellRender = (params) => {
        let statusUnit = "";
        if (params.value === 1) {
            statusUnit = "Aktif";
        } else {
            statusUnit = "Tidak Aktif";
        }
        return statusUnit;
    };

    // enables pagination in the grid
    const pagination = true;

    // sets 10 rows per page (default is 100)
    const paginationPageSize = 20;

    const _init_clear_finger = (id) => {
        console.log(id);
        setClearID(id);
        askDelete(true);
    };

    const _handle_close_remove_confirm = async () => {
        try {
            let payload = new FormData();
            payload.append("driver_id", clearID);
            const { data } = await Api.post(
                `/web-app/context/driver/clear-finger`,
                payload
            );
            if (data.status) {
                askDelete(false);
                _refresh_cache();
                MySwal.fire({
                    title: "Berhasil!",
                    text: "Sistem berhasil reset driver device.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                MySwal.fire({
                    title: "Sistem gagal!",
                    text: "Gagal reset driver device mohon ulangi lagi nanti.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                askDelete(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Aksi",
            field: "action",
            minWidth: 180,
            enableRowGroup: false,
            editable: false,
            filter: false,
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
                _view_detail: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_view_detail(data);
                },
                _view_edit: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_edit(data);
                }
            },
        },
        {
            headerName: "No",
            field: "#",
            editable: false,
            enableRowGroup: true,
            filter: false,
        },
        {
            headerName: "No SO",
            field: "order_so_number",
            minWidth: 140,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },

        {
            headerName: "No Polisi",
            field: "order_vehicle_no",
            editable: false,
            minWidth: 180,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jenis Truck",
            field: "order_type_truck",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Nama Driver",
            field: "order_driver_name",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "No HP",
            field: "order_handphone_1",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jenis Barang",
            field: "order_name_product",
            minWidth: 280,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Range Suhu",
            minWidth: 140,
            field: "order_range_temperature",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Setting Suhu",
            minWidth: 220,
            field: "order_target_temperature",
            editable: false,
            enableRowGroup: true,
            filter: "agNumberColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Verifikasi Suhu",
            minWidth: 220,
            field: "order_verify_temperature",
            editable: false,
            enableRowGroup: true,
            filter: "agNumberColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Selisih Suhu",
            minWidth: 220,
            field: "order_diff_temperature",
            editable: false,
            enableRowGroup: true,
            cellStyle: (params) => {
                if (params.value != 0) {
                    //mark police cells as red
                    return { color: "black", backgroundColor: "red" };
                } else {
                    //mark police cells as red
                    return { color: "black", backgroundColor: "white" };
                }
                return null;
            },
            filter: "agNumberColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Verifikasi OnTemp",
            minWidth: 220,
            field: "order_verify_on_temperature",
            editable: false,
            enableRowGroup: true,
            filter: "agNumberColumnFilter",
            cellStyle: (params) => {
                if (params.value != "Y") {
                    //mark police cells as red
                    return { color: "red"};
                } else {
                    //mark police cells as red
                    return { color: "green"};
                }
                return null;
            },
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Tanggal Jam Muat",
            field: "order_load",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = cellValue;
                    if (dateAsString == null) return -1;
                    var dateParts = dateAsString.split("/");
                    var cellDate = new Date(
                        Number(dateParts[2]),
                        Number(dateParts[1]) - 1,
                        Number(dateParts[0])
                    );

                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    }

                    if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                },
                browserDatePicker: true,
            },
        },
        {
            headerName: "Order Diterima",
            field: "order_date_receive",
            minWidth: 180,
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Customer",
            minWidth: 220,
            field: "order_customer_name",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Rute",
            minWidth: 240,
            field: "order_route",
            editable: false,
            enableRowGroup: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Komentar",
            field: "order_comment",
            minWidth: 180,
            enableRowGroup: true,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
    ]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const _init_view_detail = async (id) => {
        setOverlay(true);
        try {
            const { data } = await Api.get("/web-app/context/order/detail?id=" + id);
            if (data.status) {
                setOverlay(false);
                setViewDetail(true);
                setOrderDetails(data.data);
            } else {
                setOverlay(false);
            }
        } catch (error) {
            setOverlay(false);
            console.log(error);
        }
    };
    const [form, setForm] = React.useState({});

    useEffect(() => {
        setForm(orderDetails);
    }, [orderDetails]);

    const _init_edit = async (id) => {
        setOverlay(true);
        setForm({});
        setError({});
        try {
            const { data } = await Api.get("/web-app/context/order/detail?id=" + id);
            if (data.status) {
                setOverlay(false);
                setEditView(true);
                setOrderDetails(data.data);
                setpopup({
                    ...alertpoup,
                    status: false,
                    error: false,
                    message: "",
                });
            } else {
                setOverlay(false);
            }
        } catch (error) {
            setOverlay(false);
            console.log(error);
        }
    };
    const _handle_close_edit = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setEditView(false);
    };

    const validate = () => {
        const schema = {
            order_comment: {
                require: () =>
                    !!form?.order_comment ? "" : `Komentar tidak boleh kosong`,
            },
        };
        let error = validate_input(schema);
        setError(error);
        return error;
    };

    const _handle_submit = async (e) => {
        e.preventDefault();
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setOverlay(true);
        setLoading(true);
        setError({});
        try {
            const { data } = await Api.post("/web-app/context/order/save", {
                order_id: form.order_id,
                order_comment: form.order_comment
            });
            if (data.status) {
                setpopup({
                    ...alertpoup,
                    status: true,
                    error: false,
                    message: "Berhasil menyimpan data",
                });
                setEditView(false);
                setViewDetail(false);
                setOverlay(false);
                setLoading(false);
                setForm({});
                setError({});
                _refresh_cache();
            } else {
                setpopup({
                    ...alertpoup,
                    status: false,
                    error: true,
                    message: data.message,
                });
                setOverlay(false);
                setEditView(false);
                setViewDetail(false);
                setLoading(false);
                setError(data.error);
            }
        } catch (error) {
            setpopup({
                ...alertpoup,
                status: false,
                error: true,
                message: error.message,
            });
            setOverlay(false);
            setEditView(false);
            setViewDetail(false);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
            setLoading(false);
            console.log(error);
        }
    };

    const _handle_submit_with_confirmation = async (e) => {
        e.preventDefault();
        if (!!validate()) return;
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setOverlay(true);
        setLoading(true);
        setError({});
        try {
            const { data } = await Api.post("/web-app/context/order/save", {
                order_id: form.order_id,
                order_description: form.order_description,
                order_driver_status: "T",
            });
            if (data.status) {
                setpopup({
                    ...alertpoup,
                    status: true,
                    error: false,
                    message: "Berhasil menyimpan data",
                });
                setEditView(false);
                setViewDetail(false);
                setOverlay(false);
                setLoading(false);
                setForm({});
                setError({});
                _refresh_cache();
            } else {
                setpopup({
                    ...alertpoup,
                    status: false,
                    error: true,
                    message: data.message,
                });
                setOverlay(false);
                setViewDetail(false);
                setLoading(false);
                setError(data.error);
            }
        } catch (error) {
            setpopup({
                ...alertpoup,
                status: false,
                error: true,
                message: error.message,
            });
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
            setOverlay(false);
            setEditView(false);
            setViewDetail(false);
            setLoading(false);
            console.log(error);
        }
    };

    const _handle_close_detail = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setViewDetail(false);
    };

    const _exportExcel = useCallback(() => {
        gridREF.current.api.exportDataAsExcel();
    }, []);

    const _refresh_cache = useCallback(() => {
        gridREF.current.api.refreshServerSideStore({ purge: true });
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const [frameworkComponents, setFrameworkComponents] = useState({
        btnCellRenderer: BtnActionRender,
    });

    const _handle_form = (property, event) => {
        const collection = { ...form };
        collection[property] = event.target.value;

        setError({
            ...error,
            [property]: "",
        });

        setForm(collection);
    };

    const excelStyles = [
        {
            id: "header",
            alignment: {
                vertical: "Center",
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
                bold: true,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Center',
                horizontal: 'Center'
            },
            font: {
                fontName: 'Arial',
            },
        },
    ];

    return (
        <Grid container component="main" className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - order aktif</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/order/aktif" />
            </Helmet>
            <CssBaseline />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={overlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={viewDetail}
                onClose={_handle_close_detail}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_handle_close_detail}
                            aria-label="close"
                        >
                            <Close sx={{ color: "#000000" }} />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Detail order
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        {alertpoup.status ? (
                            <React.Fragment>
                                {alertpoup.error === false ? (
                                    <Alert severity="success">{alertpoup.message}</Alert>
                                ) : (
                                    <Alert
                                        onClose={() => {
                                            setpopup({
                                                ...alertpoup,
                                                status: false,
                                                error: false,
                                                message: "",
                                            });
                                        }}
                                        severity="error"
                                    >
                                        <AlertTitle>Terjadi kesalahan</AlertTitle>
                                        {alertpoup.message} — <strong>sistem error</strong>
                                    </Alert>
                                )}
                            </React.Fragment>
                        ) : null}
                    </Stack>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-no_so"
                                        isReadonly
                                        value={form?.order_so_number}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No. SO"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_load"
                                        isReadonly
                                        value={form?.order_load}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Tgl Jam Muat"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_vehicle_no"
                                        isReadonly
                                        value={form?.order_vehicle_no}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No Polisi"
                                        variant="standard"
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="start">
                                    //             <ThermostatIcon />
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_type_truck"
                                        isReadonly
                                        value={form?.order_type_truck}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Jenis Truck"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_driver_name"
                                        isReadonly
                                        value={form?.order_driver_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Nama Driver"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_handphone_1"
                                        isReadonly
                                        value={form?.order_handphone_1}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No HP"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_name_product"
                                        isReadonly
                                        value={form?.order_name_product}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Jenis Barang"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_range_temperature"
                                        isReadonly
                                        value={form?.order_range_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Range Suhu"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_target_temperature"
                                        isReadonly
                                        value={form?.order_target_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Setting Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_verify_temperature"
                                        isReadonly
                                        value={form?.order_verify_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Verifikasi Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_diff_temperature"
                                        isReadonly
                                        value={form?.order_diff_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Selisih Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_verify_on_temperature"
                                        isReadonly
                                        value={form?.order_verify_on_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Verifikasi On Temp"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>  
                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_load"
                                        isReadonly
                                        value={form?.order_load}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Tgl Jam Muat"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_date_receive"
                                        isReadonly
                                        value={form?.order_date_receive}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Order Waktu Diterima"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_customer_name"
                                        isReadonly
                                        value={form?.order_customer_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Customer"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_route"
                                        isReadonly
                                        value={form?.order_route}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Rute"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_comment"
                                        isReadonly
                                        multiline
                                        onChange={(event) => _handle_form("order_comment", event)}
                                        rows={4}
                                        value={form?.order_comment}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Komentar"
                                        variant="standard"
                                        error={error.order_comment || form.order_comment === "" ? true : false}
                                        helperText={
                                            error.order_comment || form.order_comment === "" ? error.order_comment : ""
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={viewDetail}
                                    onClick={_handle_submit_with_confirmation}
                                    type="button"
                                    startIcon={<Close />}
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    className={classes.submit}
                                >
                                    Cancel
                                </StyledLoadingButton>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={loading}
                                    onClick={_handle_close_detail}
                                    type="button"
                                    startIcon={<ExitToAppOutlined />}
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    className={classes.submit}
                                >
                                    Kembali
                                </StyledLoadingButton>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={viewDetail}
                                    onClick={_handle_submit}
                                    type="button"
                                    startIcon={<Save />}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Simpan
                                </StyledLoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={viewEdit}
                onClose={_handle_close_edit}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_handle_close_edit}
                            aria-label="close"
                        >
                            <Close sx={{ color: "#000000" }} />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing: 2, textTransform: 'uppercase', fontWeight: 700 }}
                            variant="h6"
                            component="div"
                        >
                            Order aktif
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        {alertpoup.status ? (
                            <React.Fragment>
                                {alertpoup.error === false ? (
                                    <Alert severity="success">{alertpoup.message}</Alert>
                                ) : (
                                    <Alert
                                        onClose={() => {
                                            setpopup({
                                                ...alertpoup,
                                                status: false,
                                                error: false,
                                                message: "",
                                            });
                                        }}
                                        severity="error"
                                    >
                                        <AlertTitle>Terjadi kesalahan</AlertTitle>
                                        {alertpoup.message} — <strong>sistem error</strong>
                                    </Alert>
                                )}
                            </React.Fragment>
                        ) : null}
                    </Stack>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-no_so"
                                        isReadonly
                                        value={form?.order_so_number}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No. SO"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_load"
                                        isReadonly
                                        value={form?.order_load}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Tgl Jam Muat"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_vehicle_no"
                                        isReadonly
                                        value={form?.order_vehicle_no}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No Polisi"
                                        variant="standard"
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="start">
                                    //             <ThermostatIcon />
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_type_truck"
                                        isReadonly
                                        value={form?.order_type_truck}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Jenis Truck"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_driver_name"
                                        isReadonly
                                        value={form?.order_driver_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Nama Driver"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_handphone_1"
                                        isReadonly
                                        value={form?.order_handphone_1}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="No HP"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_name_product"
                                        isReadonly
                                        value={form?.order_name_product}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Jenis Barang"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_range_temperature"
                                        isReadonly
                                        value={form?.order_range_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Range Suhu"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_target_temperature"
                                        isReadonly
                                        value={form?.order_target_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Setting Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_verify_temperature"
                                        isReadonly
                                        value={form?.order_verify_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Verifikasi Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_diff_temperature"
                                        isReadonly
                                        value={form?.order_diff_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Selisih Suhu"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_verify_on_temperature"
                                        isReadonly
                                        value={form?.order_verify_on_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Verifikasi On Temp"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ThermostatIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_load"
                                        isReadonly
                                        value={form?.order_load}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Tgl Jam Muat"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_date_receive"
                                        isReadonly
                                        value={form?.order_date_receive}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Order Waktu Diterima"
                                        variant="standard"

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_customer_name"
                                        isReadonly
                                        value={form?.order_customer_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Customer"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_route"
                                        isReadonly
                                        value={form?.order_route}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Rute"
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_comment"
                                        isReadonly
                                        multiline
                                        onChange={(event) => _handle_form("order_comment", event)}
                                        rows={4}
                                        value={form?.order_comment}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label="Komentar"
                                        variant="standard"
                                        error={error.order_comment || form.order_comment === "" ? true : false}
                                        helperText={
                                            error.order_comment || form.order_comment === "" ? error.order_comment : ""
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={loading}
                                    onClick={_handle_submit_with_confirmation}
                                    type="button"
                                    startIcon={<Close />}
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    className={classes.submit}
                                >
                                    Cancel
                                </StyledLoadingButton>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={loading}
                                    onClick={_handle_close_edit}
                                    type="button"
                                    startIcon={<ExitToAppOutlined />}
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    className={classes.submit}
                                >
                                    Kembali
                                </StyledLoadingButton>
                            </Grid>
                            <Grid sx={{ marginTop: 5 }} item md={4} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={loading}
                                    onClick={_handle_submit}
                                    type="button"
                                    startIcon={<Save />}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Simpan
                                </StyledLoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Dialog>

            <Box
                sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
            >
                <Breadcrumbs
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 1,
                        textTransform: "uppercase",
                        padding: 2,
                    }}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Order
                    </Link>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        color="text.primary"
                    >
                        Aktif
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                className="ag-theme-alpine"
                sx={{ height: 800, marginBottom: 20, width: "100%" }}
            >
                <Stack sx={{ margin: 2 }} spacing={2} direction="row">
                    <StyledButton variant="text" onClick={() => _refresh_cache()}>
                        <RefreshOutlined sx={{ mr: 1 }} />
                        Reload
                    </StyledButton>
                    <StyledButton variant="text" onClick={() => _exportExcel()}>
                        <Download sx={{ mr: 1 }} />
                        Export Excel
                    </StyledButton>
                </Stack>
                <AgGridReact
                    ref={gridREF}
                    excelStyles={excelStyles}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onGridReady={onGridReady}
                    frameworkComponents={frameworkComponents}
                    chartThemes={["ag-theme-alpine"]}
                    defaultColDef={defaultColDef}
                    rowGroupPanelShow={"always"}
                    rowModelType={"serverSide"}
                    serverSideStoreType={"partial"}
                    purgeClosedRowNodes={true}
                    cacheBlockSize={20}
                    animateRows={true}
                    pivotMode={false}
                    sideBar={sideBar}
                    columnDefs={columnDefs}
                />
            </Box>
        </Grid>
    );
}
