import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Context } from "state/store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Lightbox from 'react-image-lightbox';
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";

import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    CssBaseline,
    Input,
    InputLabel,
    Dialog,
    Alert,
    AlertTitle,
    Divider,
    MenuItem,
    InputAdornment,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    Avatar,
    DialogTitle,
    Stack,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    Link,
    Box,
    Slide,
} from "@mui/material";

import useAxios from "helper/helper.http";
import { def } from "helper/helper.const";
import {
    RefreshOutlined,
    AccountCircle,
    Save,
    Close,
    ExitToAppOutlined,
    AssignmentInd,
    Lock,
    Download,
    CloudSync,
    AccountTree,
    VisibilityOff,
    AccessTime,
    Visibility,
} from "@mui/icons-material";
import moment from "moment";
import storage from "helper/helper.storage";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import CloseIcon from "@mui/icons-material/Close";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";

import { Pagination, Navigation } from "swiper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    input_text: {
        textTransform: "uppercase",
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
    buttonPREV: {
        position: 'absolute',
        top: '45%',
        right: 0,
        zIndex: 1000
    },
    buttonNEXT: {
        position: 'absolute',
        top: '45%',
        left: 0,
        zIndex: 1000
    },
    overlayVideo: {
        position: 'relative'
    },
    overlayVideoBackground: {
        position: 'fixed',
        display: 'block',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 2,
        cursor: 'pointer'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoCollectionUnload({ id, photoType }) {
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [loading, setLoading] = React.useState(false);
    const [videoPopup, openVideo] = React.useState(false);
    const [videoURI, setVideoURI] = React.useState('');
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });

    const swipePREV = React.useRef(null)
    const sliderREF = React.useRef(null)
    const swipeNEXT = React.useRef(null)

    const [photoList, setPhoto] = React.useState([]);

    const [lightboxopen, setlightboxopen] = useState({
        open: false,
        image: null,
    });

    const _preview_photo = (photo) => {
        setlightboxopen({
            ...lightboxopen,
            open: true,
            image: photo
        });
    }

    const _view_video = (videoURI) => {
        setVideoURI(videoURI);
    }

    useEffect(() => {
        if (videoURI !== '') {
            openVideo(true);
        }
    }, [videoURI]);



    useEffect(() => {
        const photoTypeLoad = async () => {
            try {
                let payload = new FormData();
                payload.append("order_unload_id", id);
                payload.append("photo_type", photoType);
                const { data } = await Api.post(
                    `/web-app/context/order/photo-unload`,
                    payload
                );
                if (data.status) {
                    setPhoto(data.data);
                } else {
                    MySwal.fire({
                        title: "Sistem gagal!",
                        text: "Gagal ambil data mohon ulangi lagi nanti.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        photoTypeLoad();
    }, [id, photoType]);

    const PREV = useCallback(() => {
        if (!sliderREF.current) return;
        sliderREF.current.swiper.slidePrev();
    }, []);

    const NEXT = useCallback(() => {
        if (!sliderREF.current) return;
        sliderREF.current.swiper.slideNext();
    }, []);

    const _handle_close_popup = () => {
        openVideo(false);
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Swiper
                initialSlide="0"
                ref={sliderREF}
                pagination={{
                    type: "progressbar",
                }}
                initialSlide="1"
                loop={true}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = swipePREV.current;
                    swiper.params.navigation.nextEl = swipeNEXT.current;
                }}
                modules={[Pagination, Navigation]}
                className="photo-load-swiper"
            >
                {
                    photoList.length > 0 && photoList.map((value, index) => {
                        let mediaType = value.media_type.split("/");

                        console.log(mediaType);

                        return (
                            <SwiperSlide>
                                {
                                    value.media_type !== 'video/mp4' ? (
                                        <>
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: 280,
                                                    objectFit: "cover",
                                                }}
                                                onClick={() => _preview_photo(`${def.API_ASSET_URL}${value.media_uri}`)}
                                                src={`${def.API_ASSET_URL}${value?.media_uri}`}
                                                alt={value?.media_type}
                                                loading="lazy"
                                                onError={(e) => {
                                                    if (e.target.src !== "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png") { e.target.onerror = null; e.target.src = "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png"; }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <div className={classes.overlayVideo} onClick={() => _view_video(`${def.API_ASSET_URL}${value.media_uri}`)}>
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: 280,
                                                    objectFit: "cover",
                                                }}
                                                src={`${def.API_ASSET_URL}${value?.thumbnail}`}
                                                alt={value?.media_type}
                                                loading="lazy"
                                                onError={(e) => {
                                                    if (e.target.src !== "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png") { e.target.onerror = null; e.target.src = "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png"; }
                                                }}
                                            />
                                            <PlayCircleTwoToneIcon
                                                onClick={() => _view_video(`${def.API_ASSET_URL}${value.media_uri}`)}
                                                fontSize="large"
                                                sx={{
                                                    color: "#FFFFFF",
                                                    zIndex: 1100,
                                                    position: 'absolute',
                                                    fontSize: 100,
                                                    top: '35%',
                                                    left: '40%',
                                                    right: '40%',
                                                    bottom: '40%'
                                                }}
                                            />
                                            <div onClick={() => _view_video(`${def.API_ASSET_URL}${value.media_uri}`)} className={classes.overlayVideoBackground}></div>
                                        </div>
                                    )
                                }
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
            <div className={classes.buttonPREV}>
                <ArrowRightIcon fontSize="large" ref={swipePREV} onClick={PREV} />
            </div>
            <div className={classes.buttonNEXT}>
                <ArrowLeftIcon fontSize="large" ref={swipeNEXT} onClick={NEXT} />
            </div>
            {lightboxopen.open && (
                <Lightbox
                    mainSrc={`${lightboxopen.image}`}
                    onCloseRequest={() => setlightboxopen({
                        ...lightboxopen,
                        open: false,
                        image: null,
                    })}
                />
            )}
            <Dialog
                fullWidth={true}
                open={videoPopup}
                onClose={_handle_close_popup}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_handle_close_popup}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <div>
                    <video width="100%" height="500" controls >
                        <source src={videoURI} type="video/mp4" />
                    </video>
                </div>
            </Dialog>
        </Grid>
    );
}