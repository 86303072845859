import * as React from "react";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  InputAdornment,
  IconButton,
  Avatar,
  Alert,
  AlertTitle,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "../helper/helper.error_form";
import logo_path from "../assets/logo.png";
import md5 from "../helper/helper.md5";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import _setting_action from "../state/actions/setting.actions";
import useAxios from "../helper/helper.http";
import { Context } from "../state/store";
import { useNavigate, useLocation } from "react-router-dom";
import _user_action from "../state/actions/user.actions";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(2, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  avatar: {
    width: 120,
    height: 120,
    margin: theme.spacing(0),
  },
  avatar_photo: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  title_text: {
    margin: theme.spacing(2, 0, 1),
    fontFamily: "Google Sans",
    fontWeight: 500,
    color: "grey",
  },
  input_text: {
    "& .MuiInputLabel-root": {
      fontWeight: "bold",
      fontFamily: "inherit",
      fontSize: 12,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& fieldset": {
      borderRadius: 10,
      border: "2px solid #e0e0e0",
    },
  },
  input_label: {
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 12,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const Api = useAxios();
  let navigate = useNavigate();
  let location = useLocation();
  const captchaREF = React.createRef();
  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  let from = location.state?.from?.pathname || "/";
  const [visibility_password, setVisiblePassword] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [account, setAccount] = React.useState({
    username: "",
    password: "",
    token: "",
  });
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    error: false,
    message: "",
  });
  const _handle_show_password = () => {
    setVisiblePassword(!visibility_password);
  };
  const _handle_mousedown_password = (event) => {
    event.preventDefault();
  };
  const _handle_account = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setAccount(accountCopy);
  };

  React.useEffect(() => {
    console.log(error);
    return () => {
      setError({});
    };
  }, []);

  const validate = () => {
    const schema = {
      username: {
        require: () =>
          !!account?.username ? "" : `Pengguna tidak boleh kosong`,
      },
      password: {
        require: () =>
          !!account?.password ? "" : `Kata sandi tidak boleh kosong`,
      },
      token: {
        require: () => (!!account?.token ? "" : `Captcha dibutuhkan`),
      },
    };
    let error = validate_input(schema);
    setError(error);
    return error;
  };

  const _handle_login = () => {
    if (!!validate()) return;
    setLoading(true);

    let params = new FormData();
    params.append("username", account?.username);
    params.append("password", md5(account?.password));

    _user_action
      .login(dispatch, Api, params)
      .then((data) => {
        setLoading(false);
        if (data.status) {
          captchaREF.current?.reset();
          navigate("/dashboard");
          setpopup({
            ...alertpoup,
            status: true,
            error: false,
            message: "Akses diberikan berhasil masuk.",
          });
          setTimeout(
            function () {
              setpopup({
                ...alertpoup,
                status: false,
                error: false,
                message: "",
              });
            }.bind(this),
            8000
          );
        } else {
          captchaREF.current?.reset();
          setpopup({
            ...alertpoup,
            status: true,
            error: true,
            message: "Akun tidak sah untuk masuk.",
          });
          setTimeout(
            function () {
              setpopup({
                ...alertpoup,
                status: false,
                error: false,
                message: "",
              });
            }.bind(this),
            8000
          );
        }
      })
      .catch((error) => {
        captchaREF.current?.reset();
        console.log(error);
        setLoading(false);
        setpopup({
          ...alertpoup,
          status: true,
          error: true,
          message:
            "Akun tidak ada / login gagal mohon coba ulangi lagi, apabila masih terkendala bisa hubungi master web.",
        });
        setTimeout(
          function () {
            setpopup({
              ...alertpoup,
              status: false,
              error: false,
              message: "",
            });
          }.bind(this),
          8000
        );
      });
  };

  const _captcha_change = (token) => {
    console.log("CAPTCHA TOKEN:", token);
    const accountCopy = { ...account };
    accountCopy["token"] = token;

    setError({
      ...error,
      token: "",
    });

    setAccount(accountCopy);
  };

  return (
    <React.Fragment>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MKA - login</title>
          <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <Box
          sx={{
            width: 420,
            height: "auto",
            backgroundColor: "#fafafa",
            borderRadius: 4,
            elevation: 2,
          }}
        >
          <div className={classes.paper}>
            <Avatar
              sx={{
                width: 80,
                height: 80,
                backgroundColor: "white",
                marginTop: 4,
                marginBottom: 4,
              }}
              className={classes.avatar}
            >
              <img className={classes.avatar_photo} src={logo_path}></img>
            </Avatar>
            {/* <Typography
            className={classes.title_text}
            component="h1"
            variant="h5"
          >
            MKA Report
          </Typography> */}
            <Stack sx={{ width: "100%" }} spacing={2}>
              {alertpoup.status ? (
                <React.Fragment>
                  {alertpoup.error === false ? (
                    <Alert severity="success">{alertpoup.message}</Alert>
                  ) : (
                    <Alert
                      onClose={() => {
                        setpopup({
                          ...alertpoup,
                          status: false,
                          error: false,
                          message: "",
                        });
                      }}
                      severity="error"
                    >
                      <AlertTitle>Terjadi kesalahan</AlertTitle>
                      {alertpoup.message} — <strong>sistem error</strong>
                    </Alert>
                  )}
                </React.Fragment>
              ) : null}
            </Stack>
            <form className={classes.form} noValidate>
              <TextField
                className={classes.input_text}
                autoComplete="username"
                onChange={(event) => _handle_account("username", event)}
                variant="outlined"
                margin="normal"
                error={error && error.username ? true : false}
                helperText={error && error.username ? error.username : ""}
                fullWidth
                id="username"
                label="user name"
                name="username"
              />
              <br />
              <TextField
                className={classes.input_text}
                error={error && error.password ? true : false}
                helperText={error && error.password ? error.password : ""}
                onChange={(event) => _handle_account("password", event)}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="kata sandi"
                type={visibility_password ? "text" : "password"}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={_handle_show_password}
                        onMouseDown={_handle_mousedown_password}
                        edge="end"
                      >
                        {visibility_password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />
              <br />
              <br />
              <FormControlLabel
                className={classes.input_label}
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <br />
              <ReCAPTCHA
                ref={captchaREF}
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                onChange={_captcha_change}
              />
              {error && error.token ? (
                <React.Fragment>
                  <Alert
                    onClose={() => {
                      setError({});
                    }}
                    sx={{ marginTop: 2 }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {error.token} — <strong>Login gagal</strong>
                  </Alert>
                </React.Fragment>
              ) : (
                ""
              )}
              <br />
              <StyledLoadingButton
                loading={loading}
                loadingPosition="start"
                disabled={loading}
                onClick={_handle_login}
                type="button"
                startIcon={<LockOpenIcon />}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? "loading..." : "masuk"}
              </StyledLoadingButton>
              <br />
              <br />
              <br />
            </form>
          </div>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
