import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Input,
  InputLabel,
  Alert,
  AlertTitle,
  MenuItem,
  InputAdornment,
  FormControl,
  Button,
  Avatar,
  DialogTitle,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
  Link,
  Box,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from '@mui/icons-material/Save';
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import useAxios from "helper/helper.http";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import md5 from "helper/helper.md5";
import { Context } from "state/store";
import BtnUserRender from "components/datatables/btn_users_render";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { RefreshOutlined, Download } from "@mui/icons-material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  input_label: {
    color: "#3c4043",
    textTransform: "uppercase",
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 14,
      letterSpacing: 1,
      textTransform: "capitalize",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 13,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Users() {
  const MySwal = withReactContent(Swal);
  const classes = useStyles();
  const theme = useTheme();
  const Api = useAxios();
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const gridREF = useRef();
  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const [form, setForm] = React.useState({id: "", username: "", password: "", roles: "", nickname: "" });
  const [userDetails, setUserDetails] = React.useState({});
  const [overlay, setOverlay] = React.useState(false);
  const [roles, setRolesList] = React.useState([]);
  const [visibility_password, setVisiblePassword] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewEdit, setEditView] = React.useState(false);
  const [viewDelete, askDelete] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const perPage = 20;

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          const page = params.request.endRow / perPage;
          Api.post(
            `/web-app/context/data/users?per_page=${Math.ceil(
              perPage
            )}&page=${Math.ceil(page)}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.success({
                rowData: data.data.data,
                rowCount: data.data.lastData,
              });
            })
            .catch((error) => {
              console.error(error);
              params.fail();
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    const _roles_list = async () => {
      try {
        const { data } = await Api.get("/web-app/context/list/roles");
        setRolesList(data);
      } catch(error) {

      }
    }
    _roles_list();

  }, []);

  useEffect(() => {
    console.log(form);
  }, [form]);

  useEffect(() => {
    if(userDetails) {
      setForm({
        id: userDetails.user_id,
        username: userDetails.user_name,
        password: "",
        roles: userDetails.user_usertype,
        nickname: userDetails.user_nickname,
      });
    }
  }, [userDetails]);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 20;

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: BtnUserRender,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "id",
      minWidth: 40,
      enableRowGroup: false,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Pengguna",
      field: "user_name",
      minWidth: 220,
      enableRowGroup: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Role",
      field: "user_usertype",
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "notContains"],
        textFormatter: function (r) {
          if (r == null) return null;
          return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, "a")
            .replace(/æ/g, "ae")
            .replace(/ç/g, "c")
            .replace(/[èéêë]/g, "e")
            .replace(/[ìíîï]/g, "i")
            .replace(/ñ/g, "n")
            .replace(/[òóôõö]/g, "o")
            .replace(/œ/g, "oe")
            .replace(/[ùúûü]/g, "u")
            .replace(/[ýÿ]/g, "y");
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Tanggal buat",
      field: "user_created_on",
      sort: "desc",
      minWidth: 220,
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Dibuat oleh",
      field: "user_created_by",
      minWidth: 220,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tanggal pembaruan",
      field: "user_updated_on",
      minWidth: 220,
      enableRowGroup: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Diperbarui oleh",
      field: "user_updated_by",
      minWidth: 220,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Aksi",
      field: "action",
      minWidth: 240,
      enableRowGroup: false,
      editable: false,
      filter: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        _view_detail: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_view_detail(data);
        },
        _view_edit: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_edit(data);
        },
        _view_delete: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_delete(data);
        },
      },
    },
  ]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const _init_view_detail = async (id) => {
    setOverlay(true);
    try {
      const { data } = await Api.get("/web-app/context/detail/users", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setViewDetail(true);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _init_edit = async (id) => {
    setOverlay(true);
    setForm({ username: "", password: "", roles: "", nickname: "" });
    setError({});
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    try {
      const { data } = await Api.get("/web-app/context/edit/users", {
        params: {
          id: id,
        },
      });
      if (data.status) {
        setOverlay(false);
        setEditView(true);
        setUserDetails(data.data);
      } else {
        setOverlay(false);
      }
    } catch (error) {
      setOverlay(false);
      console.log(error);
    }
  };
  const _init_delete = (id) => {;
    askDelete(true);
  };

  const _handle_close_detail = () => {
    setViewDetail(false);
  };
  const _handle_close_edit = () => {
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    setEditView(false);
  };
  const _handle_close_remove_confirm = () => {
    askDelete(false);
  };
  const _handle_show_password = () => {
    setVisiblePassword(!visibility_password);
  };
  const _exportExcel = useCallback(() => {
    gridREF.current.api.exportDataAsExcel();
  }, []);
  const excelStyles = [
    {
      id: "header",
      alignment: {
        vertical: "Center",
        horizontal: 'Center'
      },
      font: {
        fontName: 'Arial',
        bold: true,
      },
    },
    {
      id: 'cell',
      alignment: {
        vertical: 'Center',
        horizontal: 'Center'
      },
      font: {
        fontName: 'Arial',
      },
    },
  ];
  const _handle_create_users = () => {
    setEditView(true);
    setForm({ id: "", username: "", password: "", roles: "", nickname: "" });
    setError({});
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
  };
  const _handle_mousedown_password = (event) => {
    event.preventDefault();
  };
  const _handle_submit_users = async () => {
    if (!!validate()) return;
    setLoading(true)
    let params = new FormData();
    params.append("id", form?.id);
    params.append("username", form?.username);
    params.append("nickname", form?.nickname);
    params.append("roles", form?.roles);
    params.append("password", md5(form?.password));
    try {
      const { data } = await Api.post("/web-app/context/save/users", params);
      if (data.status) {
        setLoading(false);
        _refresh_cache()
        setpopup({
          ...alertpoup,
          status: true,
          error: false,
          message: "Data pengguna berhasil disimpan.",
        });

        MySwal.fire({
          title: "Berhasil!",
          text: "Data pengguna berhasil disimpan.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setEditView(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error.response.status);
      if(error.response.status === 422) {
        setLoading(false);
        var error_form = {};

        console.log(error.response.data.username);

        if(error.response.data.username !== undefined) {
          error_form.username =  error.response.data.username[0];
        }
        if(error.response.data.nickname !== undefined) {
          error_form.nickname =  error.response.data.nickname[0];
        }
        if(error.response.data.roles !== undefined) {
          error_form.roles =  error.response.data.roles[0];
        }
        if(error.response.data.password !== undefined) {
          error_form.password =  error.response.data.password[0];
        }
        console.log(error_form);
        setError(error_form);
      } else {
        setpopup({
          ...alertpoup,
          status: true,
          error: true,
          message: "Gagal simpan data coba lagi nanti terdapat error. ["+error.response.status+"]",
        });
      }
      setLoading(false);
      console.log(error.response);
    }
  }
  const _handle_form = (property, event) => {
    const collection = { ...form };
    collection[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setForm(collection);
  };
  const validate = () => {
    const schema = {
      username: {
        require: () =>
          !!form?.username ? "" : `Alamat email tidak boleh kosong`,
      },
      password: {
        require: () =>
          !!form?.password ? "" : `Kata sandi tidak boleh kosong`,
      },
      nickname: {
        require: () => (!!form?.nickname ? "" : `Nama tidak boleh kosong`),
      },
      roles: {
        require: () => (!!form?.roles ? "" : `Role belum dipilih`),
      },
    };
    let error = validate_input(schema);

    console.log(error);

    setError(error);
    return error;
  };

  const props_edit_modal = useMediaQuery(theme.breakpoints.down("lg"));

  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={overlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen
        open={viewDetail}
        onClose={_handle_close_detail}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_detail}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Detail pengguna
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Grid container spacing={2}></Grid>
        </Box>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewEdit}
        onClose={_handle_close_edit}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={_handle_close_edit}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Pengguna
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertpoup.status ? (
              <React.Fragment>
                {alertpoup.error === false ? (
                  <Alert severity="success">{alertpoup.message}</Alert>
                ) : (
                  <Alert
                    onClose={() => {
                      setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                      });
                    }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {alertpoup.message} — <strong>sistem error</strong>
                  </Alert>
                )}
              </React.Fragment>
            ) : null}
          </Stack>
          <form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <AccountCircle sx={{ color: "grey", mr: 1, my: 0.5 }} />
                  <TextField
                    className={classes.input_label}
                    id="input-username"
                    margin="normal"
                    fullWidth
                    onChange={(event) => _handle_form("username", event)}
                    value={form?.username}
                    label="username"
                    variant="standard"
                    error={error?.username || form.username === "" ? true : false}
                    helperText={
                      error?.username || form.username === "" ? error.username : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <AssignmentIndIcon sx={{ color: "grey", mr: 1, my: 0.5 }} />
                  <TextField
                    id="input-nickname"
                    onChange={(event) => _handle_form("nickname", event)}
                    value={form?.nickname}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="nama"
                    variant="standard"
                    error={error.nickname || form.nickname === "" ? true : false}
                    helperText={
                      error.nickname || form.nickname === "" ? error.nickname : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <LockIcon sx={{ color: "grey", mr: 1, my: 0.5 }} />
                  <TextField
                    id="input-password"
                    autoComplete="new-password"
                    value={form?.password}
                    className={classes.input_label}
                    onChange={(event) => _handle_form("password", event)}
                    error={error.password && form.password === "" ? true : false}
                    helperText={
                      error.password && form.password === "" ? error.password : ""
                    }
                    margin="normal"
                    type={visibility_password ? "text" : "password"}
                    fullWidth
                    label="kata sandi"
                    variant="standard"
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={_handle_show_password}
                    onMouseDown={_handle_mousedown_password}
                  >
                    {visibility_password ? (
                      <VisibilityOff sx={{ color: "grey" }} />
                    ) : (
                      <Visibility sx={{ color: "grey" }} />
                    )}
                  </IconButton>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <AccountTreeIcon sx={{ color: "grey", mr: 1, my: 0.5 }} />
                  <TextField
                    fullWidth
                    className={classes.input_label}
                    error={error.roles || form.roles === "" ? true : false}
                    id="filled-select-roles"
                    select
                    label="roles"
                    margin="normal"
                    onChange={(event) => _handle_form("roles", event)}
                    value={form?.roles}
                    helperText={error?.roles || form.roles === "" ? error.roles : ""}
                    variant="standard"
                  >
                    {roles.map((option) => (
                      <MenuItem
                        key={option.usertype_id}
                        value={option.usertype_id}
                      >
                        {option.usertype_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid sx={{ marginTop: 5 }} item md={12} xs={12}>
                <StyledLoadingButton
                  loading={loading}
                  loadingPosition="start"
                  disabled={loading}
                  onClick={_handle_submit_users}
                  type="button"
                  startIcon={<SaveIcon />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Simpan
                </StyledLoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Dialog
        open={viewDelete}
        onClose={_handle_close_remove_confirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Anda yakin ingin menghapus data ini ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Data ini akan dihapus secara permanen dan tidak ada pemulihan data
            setelah dihapus.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handle_close_remove_confirm}>Batal</Button>
          <Button onClick={_handle_close_remove_confirm} autoFocus>
            Ya, hapus
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
      >
        <Breadcrumbs
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 1,
            textTransform: "uppercase",
            padding: 2,
          }}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/dashboard">
            Settings
          </Link>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            color="text.primary"
          >
            users
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        className="ag-theme-alpine"
        sx={{ height: 600, marginBottom: 20, width: "100%" }}
      >
        <Stack sx={{ margin: 2 }} spacing={2} direction="row">
          <StyledButton variant="text" onClick={() => _handle_create_users()}>
            <PersonAddIcon sx={{ mr: 1 }} />
            Tambah pengguna
          </StyledButton>
          <StyledButton variant="text" onClick={() => _refresh_cache()}>
            <RefreshOutlined sx={{ mr: 1 }} />
            Reload
          </StyledButton>
          <StyledButton variant="text" onClick={() => _exportExcel()}>
            <Download sx={{ mr: 1 }} />
            Export Excel
          </StyledButton>
        </Stack>
        <AgGridReact
          ref={gridREF}
          excelStyles={excelStyles}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          chartThemes={["ag-theme-alpine"]}
          defaultColDef={defaultColDef}
          rowGroupPanelShow={"always"}
          rowModelType={"serverSide"}
          serverSideStoreType={"partial"}
          purgeClosedRowNodes={true}
          cacheBlockSize={20}
          animateRows={true}
          pivotMode={false}
          sideBar={sideBar}
          columnDefs={columnDefs}
        />
      </Box>
    </Grid>
  );
}
