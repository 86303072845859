import React, { Component } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
class BtnDriverRender extends Component {
  constructor(props) {
    super(props);
    this.btnViewHandler = this.btnViewHandler.bind(this);
    this.btnEditHandler = this.btnEditHandler.bind(this);
    this.btnDeleteHandler = this.btnDeleteHandler.bind(this);
  }
  btnViewHandler() {
    this.props._view_detail(this.props.data.id);
  }
  btnEditHandler() {
    this.props._view_edit(this.props.data.id);
  }
  btnDeleteHandler() {
    this.props._view_delete(this.props.data.id);
  }
  render() {
    return (
      <ButtonGroup disableElevation variant="text">
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.btnViewHandler}
          color="inherit"
        >
          <InfoIcon
            sx={{
              fontSize: "1.2rem",
              color: "grey",
            }}
          />
        </IconButton>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.btnEditHandler}
          color="inherit"
        >
          <ModeEditIcon
            sx={{
              fontSize: "1.2rem",
              color: "grey",
            }}
          />
        </IconButton>
        <IconButton
          size="medium"
          aria-label="reset device ID"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.btnDeleteHandler}
          color="inherit"
        >
          <RefreshOutlined
            sx={{
              fontSize: "1.2rem",
              color: "grey",
            }}
          />
        </IconButton>
      </ButtonGroup>
    );
  }
}

export default BtnDriverRender;
