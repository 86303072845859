import React, { Component } from "react";
import { render } from "react-dom";
import { def } from "../../helper/helper.const";

class Map extends Component {
    constructor() {
        super(); 
        this.onScriptLoad = this.onScriptLoad.bind(this);
    }

    onScriptLoad() {
        const map = new window.google.maps.Map(
            document.getElementById(this.props.id),
            this.props.options
        );
        this.props.onMapLoad(map);
    }

    componentDidMount() {
        if (!window.google) {
            let s = document.createElement("script");
            s.type = "text/javascript";
            s.src = `https://maps.googleapis.com/maps/api/js?key=${def.API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
            let x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
            // Below is important.
            //We cannot access google.maps until it's finished loading
            s.addEventListener("load", (e) => {
                this.onScriptLoad();
            });
        } else {
            this.onScriptLoad();
        }
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', height: '100vh' }} id={this.props.id} />
            </div>
        );
    }
}

export default Map;