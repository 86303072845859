import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import TimeFilter from 'components/datatables/time_filter';
import throttle from "lodash/throttle";
import storage from "helper/helper.storage";
import { def } from "../../helper/helper.const";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Input,
  InputLabel,
  Dialog,
  Alert,
  AlertTitle,
  MenuItem,
  InputAdornment,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Button,
  Avatar,
  DialogTitle,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
  Link,
  Box,
  Slide,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import {
  RefreshOutlined,
  AccountCircle,
  Save,
  Close,
  AccessTime,
  AssignmentInd,
  Lock,
  CloudSync,
  EditTwoTone,
  AccountTree,
  VisibilityOff,
  Visibility,
  Download
} from "@mui/icons-material";

import moment from "moment";
import BtnAbsenceToday from "components/datatables/btn_absence_today";
import ReasonCellImage from "components/datatables/image_reason_cell";
import SelfiePhotoCellRenderer from "components/datatables/photo_absent_cell";
import ReasonCell from "components/datatables/reason_cell";
const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  option: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: 11,
    letterSpacing: 1,
    "& > span": {
      marginRight: 10,
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
  },
  input_label: {
    color: "#3c4043",
    fontSize: 11,
    textTransform: "uppercase",
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var base64 = require('base-64');

export default function Driver() {
  const classes = useStyles();
  const Api = useAxios();
  const [state, dispatch] = React.useContext(Context);
  const [error, setError] = React.useState({});
  const [unitListSeveral, setUnitSeveral] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [driverUnitForm, setDriverUnit] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });
  const [inputValue, setInputValue] = React.useState("");
  const [driverDetails, setDriverDetails] = React.useState({});
  const [syncLatest, setSyncLatest] = React.useState({});
  const [overlay, setOverlay] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [viewEdit, setEditView] = React.useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [form, setForm] = React.useState({
    user_nickname: "",
    user_name: "",
    attendance_time: "",
    unit_vehicle_no: "",
    unit_vehicle_type: "",
    user_driver_sim: "",
    user_driver_sim_expire: "",
    user_handphone_1: "",
    user_handphone_2: "",
    unit_description: "",
    absense_list: [],
  });
  const perPage = 20;
  const gridREF = useRef();

  const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );
  const StyledButton = styled((props) => <Button {...props} />)(
    ({ theme }) => ({
      fontWeight: 700,
      fontFamily: "inherit",
      fontSize: 13,
      letterSpacing: 1,
      textTransform: "uppercase",
    })
  );

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          console.log(params);
          let HEADER_CONFIG = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          };
          await storage.remove('absent_today');
          let export_filter = await storage.set("absent_today", params.request);
          const page = params.request.endRow / perPage;
          Api.post(
            `/web-app/context/data/absence?per_page=${perPage}&page=${page}`,
            JSON.stringify(params.request),
            HEADER_CONFIG
          )
            .then((data) => {
              console.log(data.data);
              params.success({
                rowData: data.data.data,
                rowCount: data.data.lastData,
              });
        
            })
            .catch((error) => {
              console.error(error);
              params.fail();
            });
        },
      };

      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    setForm({
      user_nickname: driverDetails.user_nickname,
      user_name: driverDetails.user_name,
      attendance_time: moment(driverDetails.attendance_time).format(
        "DD, dddd MM YYYY"
      ),
      unit_vehicle_no: driverDetails.unit_vehicle_no,
      unit_vehicle_type: driverDetails.unit_vehicle_type,
      user_driver_sim: driverDetails.user_driver_sim,
      user_driver_sim_expire: driverDetails.user_driver_sim_expire,
      user_handphone_1: driverDetails.user_handphone_1,
      user_handphone_2: driverDetails.user_handphone_2,
      unit_description: driverDetails.unit_description,
    });
  }, [driverDetails]);

  const _handle_form = (property, event) => {
    const collection = { ...form };
    collection[property] = event.target.value;

    setError({
      ...error,
      [property]: "",
    });

    setForm(collection);
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 280,
    };
  }, []);

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 20;

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Aksi",
      field: "action",
      minWidth: 120,
      enableRowGroup: false,
      editable: false,
      filter: false,
      sortable: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        _view_detail: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          // _init_view_detail(data);
           _init_edit(data);
        },
        _view_edit: function (data) {
          // alert(`${JSON.stringify(data)} was clicked`);
          _init_edit(data);
        },
      },
    },
    {
      headerName: "#",
      field: "id",
      minWidth: 120,
      sortable: false,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Ke",
      field: "attendance_order",
      minWidth: 100,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Lokasi Absen",
      field: "attendance_pool_name",
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Alamat Absen",
      field: "attendance_address",
      minWidth: 180,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Foto Absen",
      field: "attendance_selfiePhoto",
      minWidth: 180,
      cellRenderer: "selfiePhotoCellRenderer",
      editable: false,
      enableRowGroup: true,
      filter: false,
    },
    {
      headerName: "Jam",
      field: "attendance_time",
      minWidth: 120,
      editable: false,
      enableRowGroup: true,
      filter: TimeFilter,
    },
    {
      sort: "asc",
      headerName: "Driver Siap",
      field: "attendance_readiness",
      minWidth: 160,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Alasan Driver",
      field: "attendance_reason_driver",
      // cellRenderer: "reasonCell",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Unit Siap",
      field: "attendance_unit_readiness",
      minWidth: 160,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Alasan Units",
      field: "attendance_reason_unit",
      // cellRenderer: "reasonCell",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Foto Alasan",
      field: "attendance_reason_image",
      cellRenderer: "reasonCellImage",
      minWidth: 240,
      filter: false,
      sortable: false,
      editable: false,
      enableRowGroup: false,
    },
    {
      headerName: "Nama driver",
      field: "user_nickname",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "No. polisi",
      field: "unit_vehicle_no",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Jenis truk",
      field: "unit_vehicle_type",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Hari SIM",
      field: "user_driver_sim_expire_day",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Berlaku SIM",
      field: "user_driver_sim_expire",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Keterangan",
      field: "unit_description",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "ID Baru",
      field: "user_identity",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "ID lama",
      field: "user_old_identify",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Nama KTP",
      field: "user_name",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Jenis SIM",
      field: "user_driver_sim",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "No HP",
      field: "user_handphone_1",
      minWidth: 240,
      editable: false,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const _refresh_cache = useCallback(() => {
    gridREF.current.api.refreshServerSideStore({ purge: true });
  }, []);

  const _exportExcel = () => {
    var filter = base64.encode(JSON.stringify(storage.get('absent_today')));
    window.open(def.API_URL + '/web-app/context/absence/export-absent-today?filter=' + filter.replace("==", ""));
  }

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const [frameworkComponents] = useState({
    btnCellRenderer: BtnAbsenceToday,
    reasonCellImage: ReasonCellImage,
    selfiePhotoCellRenderer: SelfiePhotoCellRenderer,
    reasonCell: ReasonCell
  });

  const _handle_close_edit = () => {
    setpopup({
      ...alertpoup,
      status: false,
      error: false,
      message: "",
    });
    setEditView(false);
  };

  const _handle_submit = async (e) => {
    e.preventDefault();
    setOverlay(true);
    setError({});
    try {
      setEditView(false);
      setOverlay(false);
    } catch (error) {
      setpopup({
        ...alertpoup,
        status: false,
        error: true,
        message: error.message,
      });
      setOverlay(false);
      console.log(error);
    }
  };

   const _init_edit = async (id) => {
     setOverlay(true);
     setDriverUnit(false);
     setError({});
     setpopup({
       ...alertpoup,
       status: false,
       error: false,
       message: "",
     });
     try {
       const { data } = await Api.get("/web-app/context/edit/absence", {
         params: {
           id: id,
         },
       });
       console.log(data);
       if (data.status) {
         setOverlay(false);
         setDriverDetails(data.data);
         setEditView(true);
       } else {
         setOverlay(false);
       }
     } catch (error) {
       setOverlay(false);
       console.log(error);
     }
   };

  const getRowHeight = params => params.node.group ? 50 : 50;

  return (
    <Grid container component="main" className={classes.root}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MKA - hari ini</title>
          <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={overlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewEdit}
        onClose={() => _handle_close_edit()}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ bgcolor: "white", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => _handle_close_edit()}
              aria-label="close"
            >
              <Close sx={{ color: "#000000" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              Absen driver: {form?.attendance_time}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, padding: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertpoup.status ? (
              <React.Fragment>
                {alertpoup.error === false ? (
                  <Alert severity="success">{alertpoup.message}</Alert>
                ) : (
                  <Alert
                    onClose={() => {
                      setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                      });
                    }}
                    severity="error"
                  >
                    <AlertTitle>Terjadi kesalahan</AlertTitle>
                    {alertpoup.message} — <strong>sistem error</strong>
                  </Alert>
                )}
              </React.Fragment>
            ) : null}
          </Stack>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-last-user_nickname"
                    onChange={(event) => _handle_form("user_nickname", event)}
                    value={form?.user_nickname}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Nama panggilan"
                    variant="standard"
                    error={error && form.user_nickname === "" ? true : false}
                    helperText={
                      error && form.user_nickname === ""
                        ? error.user_nickname
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-user_name"
                    onChange={(event) => _handle_form("user_name", event)}
                    value={form?.user_name}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Nama KTP"
                    variant="standard"
                    error={error && form.user_name === "" ? true : false}
                    helperText={
                      error && form.user_name === "" ? error.user_name : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_no"
                    onChange={(event) => _handle_form("unit_vehicle_no", event)}
                    value={form?.unit_vehicle_no}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. Polisi"
                    variant="standard"
                    error={error && form.unit_vehicle_no === "" ? true : false}
                    helperText={
                      error && form.unit_vehicle_no === ""
                        ? error.unit_vehicle_no
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-unit_vehicle_type"
                    onChange={(event) =>
                      _handle_form("unit_vehicle_type", event)
                    }
                    value={form?.unit_vehicle_type}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Jenis Truck"
                    variant="standard"
                    error={
                      error && form.unit_vehicle_type === "" ? true : false
                    }
                    helperText={
                      error && form.unit_vehicle_type === ""
                        ? error.unit_vehicle_type
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-user_driver_sim"
                    onChange={(event) => _handle_form("user_driver_sim", event)}
                    value={form?.user_driver_sim}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Jenis SIM"
                    variant="standard"
                    error={error && form.user_driver_sim === "" ? true : false}
                    helperText={
                      error && form.user_driver_sim === ""
                        ? error.user_driver_sim
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-user_driver_sim_expire"
                    onChange={(event) =>
                      _handle_form("user_driver_sim_expire", event)
                    }
                    value={form?.user_driver_sim_expire}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="Berlaku SIM"
                    variant="standard"
                    error={
                      error && form.user_driver_sim_expire === "" ? true : false
                    }
                    helperText={
                      error && form.user_driver_sim_expire === ""
                        ? error.user_driver_sim_expire
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-user_handphone_1"
                    onChange={(event) =>
                      _handle_form("user_handphone_1", event)
                    }
                    value={form?.user_handphone_1}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. Handphone 1"
                    variant="standard"
                    error={error && form.user_handphone_1 === "" ? true : false}
                    helperText={
                      error && form.user_handphone_1 === ""
                        ? error.user_handphone_1
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    disabled
                    id="input-user_handphone_2"
                    onChange={(event) =>
                      _handle_form("user_handphone_2", event)
                    }
                    value={form?.user_handphone_2}
                    className={classes.input_label}
                    margin="normal"
                    fullWidth
                    label="No. Handphone 2"
                    variant="standard"
                    error={error && form.user_handphone_2 === "" ? true : false}
                    helperText={
                      error && form.user_handphone_2 === ""
                        ? error.user_handphone_2
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid sx={{ marginTop: 5 }} item md={6} xs={12}>
                <StyledLoadingButton
                  loading={loading}
                  loadingPosition="start"
                  disabled={loading}
                  onClick={() => _handle_close_edit()}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Back
                </StyledLoadingButton>
              </Grid>
              <Grid sx={{ marginTop: 5 }} item md={6} xs={12}>
                <StyledLoadingButton
                  loading={loading}
                  loadingPosition="start"
                  disabled={loading}
                  onClick={_handle_submit}
                  type="button"
                  startIcon={<Save />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Simpan
                </StyledLoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      <Box
        sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
      >
        <Breadcrumbs
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 1,
            textTransform: "uppercase",
            padding: 2,
          }}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/dashboard">
            Absen
          </Link>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1,
              textTransform: "uppercase",
              padding: 2,
            }}
            color="text.primary"
          >
            hari ini
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        className="ag-theme-alpine"
        sx={{ height: 600, marginBottom: 20, width: "100%" }}
      >
        <Stack sx={{ margin: 2 }} spacing={2} direction="row">
          <StyledButton variant="text" onClick={() => _refresh_cache()}>
            <RefreshOutlined sx={{ mr: 1 }} />
            Reload
          </StyledButton>
          <StyledButton variant="text" onClick={() => _exportExcel()}>
            <Download sx={{ mr: 1 }} />
            Export Excel
          </StyledButton>
        </Stack>
        <AgGridReact
          ref={gridREF}
          getRowHeight={getRowHeight}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          chartThemes={["ag-theme-alpine"]}
          defaultColDef={defaultColDef}
          rowGroupPanelShow={"always"}
          autoGroupColumnDef={autoGroupColumnDef}
          rowModelType={"serverSide"}
          serverSideStoreType={"partial"}
          purgeClosedRowNodes={true}
          cacheBlockSize={20}
          animateRows={true}
          pivotMode={false}
          sideBar={sideBar}
          columnDefs={columnDefs}
        />
      </Box>
    </Grid>
  );
}
