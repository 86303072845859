import React, {
    forwardRef,
    useEffect,
    useRef,
    useImperativeHandle,
    useState,
} from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
// import TextField from '@mui/material/TextField';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    FormControl
} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input_text: {
        textTransform: "uppercase",
    },
    option: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 11,
        letterSpacing: 1,
        "& > span": {
            marginRight: 10,
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
    customSelectFilter: {
        minHeight: 30,
        border: '1px solid',
        borderColor: '#babfc7',
        borderRadius: 5,
    }
}));

export default forwardRef((props, ref) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const [value, setValue] = useState('');
    const [selectType, setSelectType] = useState('equals');

    const isFilterActive = () => {
        return (
            value !== null &&
            value !== undefined &&
            value !== '' && selectType !== ''
        );
    }; 

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            isFilterActive,
            doesFilterPass(params) {
                if (!this.isFilterActive()) {
                    return;
                }

                const { api, colDef, column, columnApi, context, valueGetter } = props;
                const { node } = params;

                const dataValue = valueGetter({
                    api,
                    colDef,
                    column,
                    columnApi,
                    context,
                    data: node.data,
                    getValue: (field) => node.data[field],
                    node,
                });

                if (!dataValue) return false;
                return value;
            },
            getModel() {
                return isFilterActive() ? {
                    filterType: 'text',
                    type: selectType,
                    filter: value
                } : null;
            },
            setModel(model) {
                console.log(model);
                setValue(model);
            },
        };
    });

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChangeType = (event) => {
        setSelectType(event.target.value);
    };

    useEffect(() => {
        props.filterChangedCallback();
    }, [value]);

    useEffect(() => {
        props.filterChangedCallback();
    }, [selectType]);

    return (
        <React.Fragment>
            <div style={{ padding: 10 }}>
                <FormControl fullWidth>
                    <select className={classes.customSelectFilter} onChange={handleChangeType} value={selectType}>
                        <option value="equals">Equals</option>
                        <option value="notEqual">Not Equal</option>
                    </select>
                </FormControl>
           
                <FormControl>
                    <RadioGroup
                        ref={inputRef}
                        row
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value={1}

                            className={classes.input_label}
                            control={<Radio />}
                            label="Aktif"
                        />
                        <FormControlLabel
                            value={2}

                            className={classes.input_label}
                            control={<Radio />}
                            label="Tidak aktif"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        </React.Fragment>
    );
});