import React, { Component } from "react";
import ImageList from "@mui/material/ImageList";
import Lightbox from 'react-image-lightbox';
import ImageListItem from "@mui/material/ImageListItem";
import { def } from "helper/helper.const";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
class ReasonCellImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      photoURL: "",
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen, photoURL } = this.state;

    console.log(this.props.data.attendance_reason);

    return (
      <React.Fragment>
        {this.props.data.attendance_reason != undefined ? (
          <div dangerouslySetInnerHTML={{__html: this.props.data.attendance_reason}} />
        ) : (
          <React.Fragment>-</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ReasonCellImage;
