import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Avatar,
  Grid,
  Box,
} from "@mui/material";
import useAxios from "../helper/helper.http";
import { Context } from "../state/store";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const Api = useAxios();
  const [state, dispatch] = React.useContext(Context);
  const [account, setAccount] = React.useState({ username: "", password: "" });
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [alertpoup, setpopup] = React.useState({
    status: false,
    message: "",
  });

  return (
    <React.Fragment>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MKA - dashboard</title>
          <link rel="canonical" href="https://webdriver.kamanggala.web.id/" />
      </Helmet>
      <Grid container component="main" className={classes.root}>
      
      </Grid>
    </React.Fragment>
  );
}
