import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';


const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_text: {
    textTransform: "uppercase",
  },
  option: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: 11,
    letterSpacing: 1,
    "& > span": {
      marginRight: 10,
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
  },
  input_label: {
    color: "#3c4043",
    fontSize: 11,
    textTransform: "uppercase",
    "& .MuiFormControlLabel-label": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      fontFamily: "google sans",
      fontSize: 11,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    "& .MuiSelect-select": {
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 11,
      letterSpacing: 1,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
}));

export default forwardRef((props, ref) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [time, setTime] = useState('');
  const [selectType, setSelectType] = useState('equals');

  const isFilterActive = () => {
    return (
      time !== null &&
      time !== undefined &&
      time !== ''
    );
  };

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive,
      doesFilterPass(params) {
        if (!this.isFilterActive()) {
          return;
        }

        const { api, colDef, column, columnApi, context, valueGetter } = props;
        const { node } = params;

        const value = valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });

        if (!value) return false;
        return time;
      },
      getModel() {
        return isFilterActive() ? {
          filterType: 'time',
          type: selectType,
          filter: time
        } : null;
      },
      setModel(model) {
        console.log(model);
        setTime(model);
      },
    };
  });

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [time]);

  return (
    <React.Fragment>
      <div style={{padding: 10}}>
        <TextField
          ref={inputRef}
          id="input-last-time-filter"
          onChange={handleChange}
          value={time}
          className={classes.input_label}
          margin="normal"
          fullWidth
          label="Jam"
          variant="standard"
          type="time"
        />
      </div>
    </React.Fragment>
  );
});