import { useContext, useEffect } from "react";
import { Context } from "../state/store";
import storage from "../helper/helper.storage";
const Restore = ({ children }) => {
  const [state, dispatch] = useContext(Context);
  const setting = state.settingReducer;

  useEffect(() => {
    let process = async () => {
      try {
        /* user local storage */
        const user = await storage.get("user");
        const nav_menu = await storage.get("nav_menu");
      
        const setup = {
          ...setting,
          nav_menu: nav_menu ? nav_menu : [],
        };
        /* insert to context */
        if(user) {
          dispatch({
            type: "RESTORE:USER",
            payload: {
              token: user.token,
              profile: user.profile,
            },
          });
        }
        dispatch({ type: "RESTORE:SETTING", payload: setup });

      } catch (error) {
        console.log(error);
      }
    };
    process();
  }, []);

  return children;
};

export default Restore;
