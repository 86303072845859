const initialState = {
  theme: "light",
  nav_menu: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };

    case "SET_MENU":
      return {
        ...state,
        nav_menu: action.payload,
      };

    case "RESTORE:SETTING":
      return {
        ...action.payload,
      };

    case "CLEAR:SETTING":
      return initialState;

    default:
      return state;
  }
};
