import React, { Component } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
class BtnEditLocation extends Component {
    constructor(props) {
        super(props);
        this.btnViewHandler = this.btnViewHandler.bind(this);
        this.btnEditHandler = this.btnEditHandler.bind(this);
    }
    btnViewHandler() {
        this.props._view_detail(this.props.data.location_id);
    }
    btnEditHandler() {
        this.props._view_edit(this.props.data.location_id);
    }
    render() {
        return (
            <ButtonGroup disableElevation variant="text">
                <IconButton
                    size="medium"
                    aria-label="pengaturan view"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.btnViewHandler}
                    color="inherit"
                >
                    <InfoIcon
                        sx={{
                            fontSize: "1.2rem",
                            color: "grey",
                        }}
                    />
                </IconButton>
                <IconButton
                    size="medium"
                    aria-label="pengaturan edit"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.btnEditHandler}
                    color="inherit"
                >
                    <ModeEditIcon
                        sx={{
                            fontSize: "1.2rem",
                            color: "grey",
                        }}
                    />
                </IconButton>
            </ButtonGroup>
        );
    }
}

export default BtnEditLocation;
