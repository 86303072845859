import React, {
    useState,
    useMemo,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { makeStyles } from "@mui/styles";
import {
    Typography,
    TextField,
    FormControlLabel,
    Button,
    CssBaseline,
    Stack,
    Avatar,
    Grid,
    InputAdornment,
    Checkbox,
    Box,
    Table,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, alpha } from "@mui/material/styles";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import BtnCellRenderer from "components/datatables/btn_edit_setting_absence";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SaveIcon from "@mui/icons-material/Save";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { InfoOutlined, RefreshOutlined, Download, Save, ExitToAppOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import validate_input from "helper/helper.error_form";

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    list_access: {
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: 1,
        textTransform: "uppercase",
        padding: 2,
    },
    list_access_menu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    label_checkbox: {
        fontWeight: 700,
        marginLeft: 5,
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: 1,
    },
    label_checkbox_child: {
        marginLeft: 20,
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 12,
        letterSpacing: 1,
        '&::before': {
            content: '',
        }
    },
    input_label: {
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 12,
            letterSpacing: 1,
        },
    },
    button: {
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: 13,
        letterSpacing: 1,
        "& .MuiButton-text": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 13,
            letterSpacing: 1,
        },
    },
    child_context_table: {
        paddingLeft: 10,
    },
    custom_table_row: {
        "& .MuiTableRow-root": {
            borderBottomWidth: 1,
            borderBottomStyle: "dashed",
            borderBottomColor: "#EFF2F5",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Absence() {
    const classes = useStyles();
    const Api = useAxios();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [form, setForm] = React.useState({
        time_id: "",
        time_days: "",
        time_section_one: "",
        time_section_two: "",
        time_section_three: "",
        time_after: 0,
        time_before: 0,
        time_description: "",
    });
    const [overlay, setOverlay] = React.useState(false);
    const [viewForm, setViewForm] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [absenceTime, setAbsenceTime] = React.useState({});
    const [gridApi, setGridApi] = useState(null);
    const gridREF = useRef();
    const perPage = 20;


    const _handle_form = (property, event) => {

        console.log(property, event.target.value);

        const collection = { ...form };
    
        if(property === "time_before" || property === "time_after"){
            var min = 0;
            var max = 59;
            var value = parseInt(event.target.value, 10);

            if (value > max) value = max;
            if (value < min) value = min;

            collection[property] = value;
        } else {
            collection[property] = event.target.value;
        }

        setError({
            ...error,
            [property]: "",
        });

        setForm(collection);
    };

    useEffect(() => {
        setForm({
            time_id: absenceTime.time_id,
            time_days: absenceTime.time_days,
            time_section_one: absenceTime.time_section_one,
            time_section_two: absenceTime.time_section_two,
            time_section_three: absenceTime.time_section_three,
            time_after: absenceTime.time_after,
            time_before: absenceTime.time_before,
            time_description: absenceTime.time_description,
        });
    }, [absenceTime]);


    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        };
    }, []);

    // enables pagination in the grid
    const pagination = true;

    // sets 10 rows per page (default is 100)
    const paginationPageSize = 20;

    const [frameworkComponents, setFrameworkComponents] = useState({
        btnCellRenderer: BtnCellRenderer,
    });

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Aksi",
            field: "action",
            enableRowGroup: false,
            editable: false,
            filter: false,
            minWidth: 120,
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
                _view_detail: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_view_detail(data);
                },
                _view_edit: function (data) {
                    // alert(`${JSON.stringify(data)} was clicked`);
                    _init_edit(data);
                },
            },
        },
        {
            headerName: "No",
            editable: false,
            field: "id",
            minWidth: 80,
            enableRowGroup: false,
            filter: false,
        },
        {
            headerName: "Hari",
            editable: false,
            field: "time_days",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jam Ke-1",
            field: "time_section_one",
            editable: false,
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jam Ke-2",
            editable: false,
            field: "time_section_two",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Jam Ke-3",
            editable: false,
            field: "time_section_three",
            minWidth: 220,
            enableRowGroup: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Sebelum (mnt)",
            field: "time_before",
            editable: false,
            minWidth: 200,
            enableRowGroup: false,
            filter: "agNumberColumnFilter",
        },
        {
            headerName: "Sesudah (mnt)",
            field: "time_after",
            minWidth: 200,
            editable: false,
            enableRowGroup: false,
            filter: "agNumberColumnFilter",
        },
        {
            headerName: "Keterangan",
            field: "time_description",
            minWidth: 220,
            enableRowGroup: false,
            editable: false,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains"],
                textFormatter: function (r) {
                    if (r == null) return null;
                    return r
                        .toLowerCase()
                        .replace(/[àáâãäå]/g, "a")
                        .replace(/æ/g, "ae")
                        .replace(/ç/g, "c")
                        .replace(/[èéêë]/g, "e")
                        .replace(/[ìíîï]/g, "i")
                        .replace(/ñ/g, "n")
                        .replace(/[òóôõö]/g, "o")
                        .replace(/œ/g, "oe")
                        .replace(/[ùúûü]/g, "u")
                        .replace(/[ýÿ]/g, "y");
                },
                debounceMs: 200,
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: "Tanggal Diupdate",
            field: "time_update_at",
            minWidth: 200,
            editable: false,
            enableRowGroup: true,
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = cellValue;
                    if (dateAsString == null) return -1;
                    var dateParts = dateAsString.split("/");
                    var cellDate = new Date(
                        Number(dateParts[2]),
                        Number(dateParts[1]) - 1,
                        Number(dateParts[0])
                    );

                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    }

                    if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                },
                browserDatePicker: true,
            },
        },
    ]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    console.log(params);
                    let HEADER_CONFIG = {
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                    };
                    const page = params.request.endRow / perPage;
                    Api.post(
                        `/web-app/context/settings/absence?per_page=${perPage}&page=${page}`,
                        JSON.stringify(params.request),
                        HEADER_CONFIG
                    )
                        .then((data) => {
                            console.log(data.data);
                            params.success({
                                rowData: data.data.data,
                                rowCount: data.data.lastData,
                            });
                            // params.successCallback(data.data.data, data.data.total);
                        })
                        .catch((error) => {
                            console.error(error);
                            params.fail();
                        });
                },
            };

            gridApi.setServerSideDatasource(dataSource);
        }
    }, [gridApi]);

    const _handle_close_detail = () => {
        setViewDetail(false);
    };

    const _refresh_cache = useCallback(() => {
        gridREF.current.api.refreshServerSideStore({ purge: true });
    }, []);

    const _exportExcel = useCallback(() => {
        gridREF.current.api.exportDataAsExcel();
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const _init_view_detail = async (id) => {
        setLoading(true);
        setViewForm(true);
        try {
            const { data } = await Api.get("/web-app/context/settings/absence/details", {
                params: {
                    id: id,
                },
            });

            if (data.status) {
                setAbsenceTime(data.data);
                setViewDetail(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error.response);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const _init_edit = async (id) => {
        setLoading(true);
        setViewForm(false);
        try {
            const { data } = await Api.get("/web-app/context/settings/absence/details", {
                params: {
                    id: id,
                },
            });

            if (data.status) {
                setViewDetail(true);
                setAbsenceTime(data.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const validate = () => {
        const schema = {
            time_section_one: {
                require: () =>
                    !!form?.time_section_one ? "" : `Jam Ke - 1 tidak boleh kosong`,
            },
            time_section_two: {
                require: () =>
                    !!form?.time_section_two ? "" : `Jam Ke - 2 tidak boleh kosong`,
            },
            time_section_three: {
                require: () =>
                    !!form?.time_section_three ? "" : `Jam Ke - 3 tidak boleh kosong`,
            },
        };
        let error = validate_input(schema);
        setError(error);
        return error;
    };

    const _handle_submit = async (e) => {
        e.preventDefault();
        if (!!validate()) return;
        setLoading(true);
        setError({});
        try {
            const { data } = await Api.post("/web-app/context/settings/absence/save", {
                ...form,
            });

            if (data.status) {
                setViewDetail(false);
                setLoading(false);
                _refresh_cache();
                MySwal.fire({
                    title: '',
                    text: "Berhasil simpan pengaturan absen.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                _refresh_cache();
                setLoading(false);
            }
        } catch (error) {
            setViewDetail(false);
            setLoading(false);
            _refresh_cache();
            console.log(error);
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan di sisi server kami, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - Pengaturan Jadwal Absen</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/settings/absence" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={overlay}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={viewDetail}
                    onClose={_handle_close_detail}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ bgcolor: "white", position: "relative" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={_handle_close_detail}
                                aria-label="close"
                            >
                                <CloseIcon sx={{ color: "#000000" }} />
                            </IconButton>
                            <Typography
                                sx={{ ml: 2, flex: 1, color: "#000000", fontSize: 14, letterSpacing:2, textTransform: 'uppercase', fontWeight: 700 }}
                                variant="h6"
                                component="div"
                            >
                                View / Edit Jadwal Absensi
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ flexGrow: 1, padding: 5 }}>
                        <Grid container spacing={2}>
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_days"
                                                disabled={true}
                                                value={form?.time_days}
                                                className={classes.input_label}
                                                margin="normal"
                                                fullWidth
                                                label="hari"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_section_one"
                                                onChange={(event) => _handle_form("time_section_one", event)}
                                                disabled={viewForm}
                                                value={form?.time_section_one}
                                                className={classes.input_label}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                type="time"
                                                fullWidth
                                                label="Jam Ke - 1"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_section_two"
                                                disabled={viewForm}
                                                onChange={(event) => _handle_form("time_section_two", event)}
                                                value={form?.time_section_two}
                                                className={classes.input_label}
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                type="time"
                                                label="Jam Ke - 2"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_section_three"
                                                disabled={viewForm}
                                                value={form?.time_section_three}
                                                onChange={(event) => _handle_form("time_section_three", event)}
                                                className={classes.input_label}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                type="time"
                                                fullWidth
                                                label="Jam Ke - 3"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_before"
                                                disabled={viewForm}
                                                inputProps={{
                                                    min: 0, max: 59, inputMode: 'numeric', pattern: '[0-9]*', 
                                                }}
                                                // onInput={(e) => {
                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                // }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                                                }}
                                                onChange={(event) => _handle_form("time_before", event)}
                                                value={form?.time_before}
                                                className={classes.input_label}
                                                margin="normal"
                                                type="number"
                                                fullWidth
                                                label="Sebelum"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_after"
                                                inputProps={{ min: 0, max: 59, inputMode: 'numeric', pattern: '[0-9]*' }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                                                }}
                                                // onInput={(e) => {
                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                // }}
                                                disabled={viewForm}
                                                onChange={(event) => _handle_form("time_after", event)}
                                                value={form?.time_after}
                                                className={classes.input_label}
                                                margin="normal"
                                                type="number"
                                                fullWidth
                                                label="Sesudah"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <TextField
                                                id="input-time_description"
                                                onChange={(event) => _handle_form("time_description", event)}
                                                disabled={viewForm}
                                                value={form?.time_description}
                                                multiline
                                                maxRows={4}
                                                rows={4}
                                                className={classes.input_label}
                                                margin="normal"
                                                fullWidth
                                                label="Keterangan"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid sx={{ marginTop: 5 }} item md={6} xs={12}>
                                        <StyledLoadingButton
                                            loading={loading}
                                            loadingPosition="start"
                                            disabled={loading}
                                            onClick={_handle_close_detail}
                                            type="button"
                                            startIcon={<ExitToAppOutlined />}
                                            fullWidth
                                            variant="contained"
                                            color="success"
                                            className={classes.submit}
                                        >
                                            Kembali
                                        </StyledLoadingButton>
                                    </Grid>
                                    <Grid sx={{ marginTop: 5 }} item md={6} xs={12}>
                                        <StyledLoadingButton
                                            loading={loading}
                                            loadingPosition="start"
                                            disabled={loading}
                                            onClick={_handle_submit}
                                            type="button"
                                            startIcon={<Save />}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Simpan
                                        </StyledLoadingButton>
                                    </Grid>

                                </Grid>
                            </form>
                        </Grid>
                    </Box>
                </Dialog>
                
                <Box
                    sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
                >
                    <Breadcrumbs
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        aria-label="breadcrumb"
                    >
                        <Link underline="hover" color="inherit" href="/dashboard">
                            Pengaturan
                        </Link>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                letterSpacing: 1,
                                textTransform: "uppercase",
                                padding: 2,
                            }}
                            color="text.primary"
                        >
                           Jadwal Absen
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box
                    className="ag-theme-alpine"
                    style={{ height: 600, marginBottom: 20, width: "100%" }}
                >
                    <Stack sx={{ margin: 2 }} spacing={2} direction="row">
                        <StyledButton variant="text" onClick={() => _refresh_cache()}>
                            <RefreshOutlined sx={{ mr: 1 }} />
                            Reload
                        </StyledButton>
                        <StyledButton variant="text" onClick={() => _exportExcel()}>
                            <Download sx={{ mr: 1 }} />
                            Export Excel
                        </StyledButton>
                    </Stack>
                    <AgGridReact
                        ref={gridREF}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        onGridReady={onGridReady}
                        frameworkComponents={frameworkComponents}
                        chartThemes={["ag-theme-alpine"]}
                        defaultColDef={defaultColDef}
                        // rowGroupPanelShow={"always"}
                        pivotMode={false}
                        rowModelType={"serverSide"}
                        serverSideStoreType={"partial"}
                        purgeClosedRowNodes={true}
                        cacheBlockSize={20}
                        animateRows={true}
                        sideBar={sideBar}
                        columnDefs={columnDefs}
                    ></AgGridReact>
                </Box>
            </Grid>
        </React.Fragment>
    );
}
