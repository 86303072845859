console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

export const def = {
  API_URL:
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_API_SERVER_V1_DEV
      : process.env.REACT_APP_API_SERVER_LIVE,
  lang: {
    en: "english",
    id: "indonesia",
  },
  CAPTCHA_KEY: process.env.REACT_APP_CAPTCHA_KEY,
  API_KEY: 'AIzaSyAdmKhxr--aqe1RswCaAFNC0jPfvGNcn5U',
  API_ASSET_URL: process.env.REACT_APP_API_SERVER_LIVE
};
