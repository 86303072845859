import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import RoomIcon from '@mui/icons-material/Room';
import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    CssBaseline,
    Input,
    InputLabel,
    Dialog,
    Alert,
    AlertTitle,
    Divider,
    MenuItem,
    InputAdornment,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    Button,
    Avatar,
    DialogTitle,
    Stack,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    Link,
    Box,
    Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import validate_input from "helper/helper.error_form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "helper/helper.http";
import { Context } from "state/store";
import Map from '../../components/map/Map';
import PhotoCollection from '../../components/photo/photo_collection';
import {
    RefreshOutlined,
    AccountCircle,
    Save,
    Close,
    ExitToAppOutlined,
    AssignmentInd,
    Lock,
    Download,
    CloudSync,
    AccountTree,
    VisibilityOff,
    AccessTime,
    Visibility,
} from "@mui/icons-material";
import moment from "moment";
import storage from "helper/helper.storage";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { def } from "../../helper/helper.const";
import StatusFilter from "components/datatables/status_filter";
import BtnActionRender from "components/datatables/btn_new_order";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { compose, withProps, lifecycle, withStateHandlers } from "recompose";
import { InfoWindow, withGoogleMap, withScriptjs, GoogleMap, Marker, Circle } from 'react-google-maps';
import { parseInt } from "lodash";
import LoadDetail from 'components/order/order_load_detail';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UnloadDetail from "components/order/order_unload_detail";

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input_text: {
        textTransform: "uppercase",
    },
    input_label: {
        color: "#3c4043",
        fontSize: 11,
        textTransform: "uppercase",
        "& .MuiFormControlLabel-label": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            fontFamily: "google sans",
            fontSize: 11,
            letterSpacing: 1,
            textTransform: "uppercase",
        },
        "& .MuiSelect-select": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: 11,
            letterSpacing: 1,
        },
        "& .MuiFormHelperText-root": {
            fontWeight: 500,
            fontSize: 10,
            letterSpacing: 1.5,
        },
    },
    title_section: {
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginTop: 20,
        marginBottom: 10
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function OrderDoneDetail() {

    const classes = useStyles();
    const Api = useAxios();
    let navigate = useNavigate();
    const params = useParams();
    const MySwal = withReactContent(Swal);
    const [state, dispatch] = React.useContext(Context);
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [alertpoup, setpopup] = React.useState({
        status: false,
        message: "",
    });
    const [orderDetails, setOrderDetails] = React.useState({});
    const [syncLatest, setSyncLatest] = React.useState({});
    const [overlay, setOverlay] = React.useState(false);
    const [loadingMap, setLoadingMap] = React.useState(false);
    const [viewDetail, setViewDetail] = React.useState(false);
    const [viewEdit, setEditView] = React.useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [clearID, setClearID] = React.useState("");
    const gridREF = useRef();
    const [viewMap, setViewMap] = React.useState(false);
    const perPage = 20;
    const [viewDelete, askDelete] = React.useState(false);
    const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );
    const StyledButton = styled((props) => <Button {...props} />)(
        ({ theme }) => ({
            fontWeight: 700,
            fontFamily: "inherit",
            fontSize: 13,
            letterSpacing: 1,
            textTransform: "uppercase",
        })
    );

    const [zoom, setZoom] = React.useState(18); // initial zoom
    const [center, setCenter] = React.useState({
        lat: -6.2120973,
        lng: 106.9837511,
    });

    const [mapMarker, setMapMarker] = React.useState([]);
    const [photoLoad, setPhotoLoad] = React.useState([]);

    const [form, setForm] = React.useState({});

    const _handle_close_map = () => {
        setViewMap(false);
    }

    useEffect(() => {
        setForm(orderDetails);
    }, [orderDetails]);

    useEffect(() => {
        const _init_edit = async () => {
            setOverlay(true);
            setForm({});
            setError({});
            try {
                const { data } = await Api.get("/web-app/context/order/order-done-detail?id=" + params.id);
                if (data.status) {
                    console.log(data.data);
                    setOverlay(false);
                    setEditView(true);
                    setOrderDetails(data.data);
                    setMapMarker(data.map_load);
                    setPhotoLoad(data.photo);
                    setpopup({
                        ...alertpoup,
                        status: false,
                        error: false,
                        message: "",
                    });
                } else {
                    setOverlay(false);
                }
            } catch (error) {
                setOverlay(false);
                console.log(error);
            }
        };
        _init_edit();
    }, []);

    const _handle_close_detail = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        // navigate('/order/aktif/pergi-muat');
        window.close();
    };

    const _handle_close_edit = () => {
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setEditView(false);
    };


    const [lightboxopen, setlightboxopen] = useState({
        open: false,
        image: null,
    });

    const _handle_open_map = () => {
        // setLoadingMap(true);
        setViewMap(true);
    };

    const _preview_photo = (photo) => {
        setlightboxopen({
            ...lightboxopen,
            open: true,
            image: photo
        });
    }

    const _handle_form = (property, event) => {
        const collection = { ...form };
        collection[property] = event.target.value;

        setError({
            ...error,
            [property]: "",
        });

        setForm(collection);
    };

    const validate = () => {
        const schema = {
            order_comment: {
                require: () =>
                    !!form?.order_comment ? "" : `Komentar tidak boleh kosong`,
            },
            order_load_comment: {
                require: () =>
                    !!form?.order_load_comment ? "" : `Komentar tidak boleh kosong`,
            },
        };
        let error = validate_input(schema);
        setError(error);
        return error;
    };

    const _handle_submit = async (e) => {
        e.preventDefault();
        if (!!validate()) return;
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setOverlay(true);
        setLoading(true);
        setError({});
        try {
            const { data } = await Api.post("/web-app/context/order-done/save", {
                order_load_id: form.id,
                order_comment: form.order_comment,
                order_load_comment: form.order_load_comment,
            });
            if (data.status) {
                setpopup({
                    ...alertpoup,
                    status: true,
                    error: false,
                    message: "Berhasil menyimpan data",
                });
                setOverlay(false);
                setLoading(false);
            } else {
                setpopup({
                    ...alertpoup,
                    status: false,
                    error: true,
                    message: data.message,
                });
                setOverlay(false);
                setLoading(false);
                setError(data.error);
            }
        } catch (error) {
            setpopup({
                ...alertpoup,
                status: false,
                error: true,
                message: error.message,
            });
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan disisi server, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
            setOverlay(false);
            setLoading(false);
            console.log(error);
        }
    };
    
    const _handle_precooling_submit = async (e) => {
        e.preventDefault();
        if (!!validate()) return;
        setpopup({
            ...alertpoup,
            status: false,
            error: false,
            message: "",
        });
        setOverlay(true);
        setLoading(true);
        setError({});
        try {
            const { data } = await Api.post("/web-app/context/order-done/save", {
                order_load_id: form.id,
                order_comment: form.order_comment,
                order_load_comment: form.order_load_comment,
            });
            if (data.status) {
                setpopup({
                    ...alertpoup,
                    status: true,
                    error: false,
                    message: "Berhasil menyimpan data",
                });
                setOverlay(false);
                setLoading(false);
            } else {
                setpopup({
                    ...alertpoup,
                    status: false,
                    error: true,
                    message: data.message,
                });
                setOverlay(false);
                setLoading(false);
                setError(data.error);
            }
        } catch (error) {
            setpopup({
                ...alertpoup,
                status: false,
                error: true,
                message: error.message,
            });
            MySwal.fire({
                title: `ERROR [${error.response.status}] : ${error.response.statusText.toUpperCase()}`,
                text: "Terdapat gangguan disisi server, mohon coba lagi nanti.",
                icon: "error",
                confirmButtonText: "OK",
            });
            setOverlay(false);
            setLoading(false);
            console.log(error);
        }
    };

    const [loadTabIndex, setLoadTabs] = React.useState(0);
    const [unLoadTabIndex, setUnLoadTabs] = React.useState(0);

    const handleLoadTabChange = (event, newValue) => {
        setLoadTabs(newValue);
    };

    const handleUnLoadTabChange = (event, newValue) => {
        setUnLoadTabs(newValue);
    };


    return (
        <Grid container component="main" className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MKA - order - selesai</title>
                <link rel="canonical" href="https://webdriver.kamanggala.web.id/order/selesai" />
            </Helmet>
            <CssBaseline />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={overlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box
                sx={{ textAlign: "left", alignContent: "flex-start", width: "inherit" }}
            >
                <Breadcrumbs
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 1,
                        textTransform: "uppercase",
                        padding: 2,
                    }}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Order
                    </Link>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                            padding: 2,
                        }}
                        color="text.primary"
                    >
                        selesai
                    </Typography>
                </Breadcrumbs>
            </Box>

            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={viewMap}
                onClose={_handle_close_map}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={_handle_close_map}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <Map
                    id="map-location"
                    options={{
                        center: center,
                        zoom: zoom,
                    }}
                    onMapLoad={(map) => {
                        var marker;
                        var circle;

                        for (let i = 0; i < mapMarker.length; i++) {
                            const place = mapMarker[i];
                            circle = new window.google.maps.Circle({
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#FF0000",
                                fillOpacity: 0.15,
                                map,
                                center: { lat: place.lat, lng: place.long },
                                radius: place.radius,
                            });
                            marker = new window.google.maps.Marker({
                                position: { lat: place.lat, lng: place.long },
                                map: map,
                                title: place.code,
                                zIndex: i
                            });

                        }
                        // map.addListener('click', function (e) {
                        //     console.log(e);
                        //     if (marker && circle) {
                        //         circle.setCenter(e.latLng);
                        //         marker.setPosition(e.latLng);
                        //     } else {
                        //         marker = new window.google.maps.Marker({
                        //             position: e.latLng,
                        //             map: map
                        //         });
                        //         circle = new window.google.maps.Circle({
                        //             strokeColor: "#FF0000",
                        //             strokeOpacity: 0.8,
                        //             strokeWeight: 2,
                        //             fillColor: "#FF0000",
                        //             fillOpacity: 0.35,
                        //             map,
                        //             center: e.latLng,
                        //             radius: parseInt(form?.location_radius),
                        //         });
                        //     }
                        // });


                    }}
                />
            </Dialog>


            <Box
                className="ag-theme-alpine"
                sx={{ height: 800, marginBottom: 20, width: "100%" }}
            >
                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        {alertpoup.status ? (
                            <React.Fragment>
                                {alertpoup.error === false ? (
                                    <Alert severity="success">{alertpoup.message}</Alert>
                                ) : (
                                    <Alert
                                        onClose={() => {
                                            setpopup({
                                                ...alertpoup,
                                                status: false,
                                                error: false,
                                                message: "",
                                            });
                                        }}
                                        severity="error"
                                    >
                                        <AlertTitle>Terjadi kesalahan</AlertTitle>
                                        {alertpoup.message} — <strong>sistem error</strong>
                                    </Alert>
                                )}
                            </React.Fragment>
                        ) : null}
                    </Stack>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    No. SO
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_so_number"
                                        isReadonly
                                        value={form?.order_so_number}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Customer
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_customer_name"
                                        isReadonly
                                        value={form?.order_customer_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    No. Polisi
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_vehicle_no"
                                        isReadonly
                                        value={form?.order_vehicle_no}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Nama Driver
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_driver_name"
                                        isReadonly
                                        value={form?.order_driver_name}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    No. HP
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_handphone_1"
                                        isReadonly
                                        value={form?.order_handphone_1}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        <div className={classes.title_section}>
                            Precooling
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Jenis Barang
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_name_product"
                                        isReadonly
                                        value={form?.order_name_product}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Range Suhu
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_range_temperature"
                                        isReadonly
                                        value={form?.order_range_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Setting Suhu
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_setting_temperature"
                                        isReadonly
                                        value={form?.order_setting_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    &#x2103;
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Precooling Suhu
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_precooling_temperature"
                                        isReadonly
                                        value={form?.order_precooling_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    &#x2103;
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Selisih Suhu
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_diff_temperature"
                                        isReadonly
                                        value={form?.order_diff_temperature}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    &#x2103;
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        <Grid container spacing={12}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Foto
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    {
                                        form?.order_precooling_photo_uri !== null ? (<React.Fragment>
                                            <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                                <img
                                                    style={{
                                                        width: 280,
                                                        height: 280,
                                                        objectFit: "cover",
                                                    }}
                                                    onClick={() => _preview_photo(`${form?.order_precooling_photo_uri}`)}
                                                    src={`${form?.order_precooling_photo_uri}`}
                                                    alt={form?.order_precooling_photo_uri}
                                                    loading="lazy"
                                                    onError={(e) => {
                                                        if (e.target.src !== "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png") { e.target.onerror = null; e.target.src = "https://developers.google.com/static/maps/documentation/streetview/images/error-image-generic.png"; }
                                                    }}

                                                />
                                            </Box>
                                        </React.Fragment>) : null
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 5 }} container spacing={12}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Keterangan
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_precooling_description"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        multiline
                                        onChange={(event) => _handle_form("order_precooling_description", event)}
                                        rows={4}
                                        value={form?.order_precooling_description}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                        error={error.order_precooling_description || form.order_precooling_description === "" ? true : false}
                                        helperText={
                                            error.order_precooling_description || form.order_precooling_description === "" ? error.order_precooling_description : ""
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 5 }} container spacing={12}>
                            <Grid item xs={12} md={1}>
                                <div className="label-title">
                                    Comment
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <TextField
                                        id="input-order_precooling_comment"
                                        isReadonly
                                        multiline
                                        onChange={(event) => _handle_form("order_precooling_comment", event)}
                                        rows={4}
                                        value={form?.order_precooling_comment}
                                        className={classes.input_label}
                                        margin="normal"
                                        fullWidth
                                        label=""
                                        variant="standard"
                                        error={error.order_precooling_comment || form.order_precooling_comment === "" ? true : false}
                                        helperText={
                                            error.order_precooling_comment || form.order_precooling_comment === "" ? error.order_precooling_comment : ""
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid sx={{ marginTop: 5 }} item md={2} xs={12}>
                                <StyledLoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    disabled={viewDetail}
                                    onClick={_handle_precooling_submit}
                                    type="button"
                                    startIcon={<Save />}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Simpan
                                </StyledLoadingButton>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        <Tabs value={loadTabIndex} onChange={handleLoadTabChange} aria-label="basic tabs example">
                            {
                                JSON.stringify(orderDetails) !== '{}' && orderDetails.list_data_load.map((value, index) => {
                                    return(
                                        <Tab style={{ fontWeight: 700 }} label={`MUAT ${index + 1}`} {...a11yProps(index)} />
                                    );
                                })
                            }
                        </Tabs>
                        {
                            JSON.stringify(orderDetails) !== '{}' && orderDetails.list_data_load.map((value, index) => {
                                return (
                                    <TabPanel value={loadTabIndex} index={index}>
                                        <LoadDetail id={value.detail_load.order_load_id} />
                                    </TabPanel>
                                );
                            })
                        }

                        <Tabs value={unLoadTabIndex} onChange={handleUnLoadTabChange} aria-label="basic tabs example">
                            {
                                JSON.stringify(orderDetails) !== '{}' && orderDetails.list_data_unload.map((value, index) => {
                                    return (
                                        <Tab style={{ fontWeight: 700 }} label={`BONGKAR ${index + 1}`} {...a11yProps(index)} />
                                    );
                                })
                            }
                        </Tabs>
                        {
                            JSON.stringify(orderDetails) !== '{}' && orderDetails.list_data_unload.map((value, index) => {
                                return (
                                    <TabPanel value={unLoadTabIndex} index={index}>
                                        <UnloadDetail id={value.detail_unload.order_unload_id} />
                                    </TabPanel>
                                );
                            })
                        }
                    </form>
                </Box>
            </Box>
        </Grid>
    );
}