import React, { Component } from "react";
import ImageList from "@mui/material/ImageList";
import Lightbox from 'react-image-lightbox';
import ImageListItem from "@mui/material/ImageListItem";
import { def } from "helper/helper.const";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
class ReasonCellImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      photoURL: "",
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen, photoURL } = this.state;

    console.log(this.props.data.attendance_reason_image);

    return (
      <React.Fragment>
        {this.props.data.attendance_reason_image != undefined && this.props.data.attendance_reason_image.length != 0 ? (
          <ImageList sx={{ width: 220, height: 80 }} cols={6} rowHeight={30}>
            {this.props.data.attendance_reason_image.map((item, index) => (
              <ImageListItem onClick={() => this.setState({ isOpen: true, photoURL: def.API_URL+item.image })} key={index}>
                <img
                  style={{
                    width: 30,
                    height: 30,
                    objectFit: "cover",
                  }}
                  src={`${def.API_URL}${item.image}?w=60&h=60&fit=crop&auto=format`}
                  srcSet={`${def.API_URL}${item.image}?w=60&h=60&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.image}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <React.Fragment>-</React.Fragment>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={photoURL}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ReasonCellImage;
